import { Box, Flex, Heading, Input, VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { getLabTests } from "../../../api/test";

import { TestSlider } from "./TestSlider";
import { getLab } from "../../../cookie/lab";
export const LabTests = () => {
  const [tests, setTests] = useState([]);
  const lab = getLab();

  //   React.useEffect(() => {
  //     import("@lottiefiles/lottie-player");
  //   });

  const fetchData = async () => {
    try {
      if (lab?._id) {
        const res = await getLabTests(lab?._id);
        setTests(res);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <VStack py={[7, 10]} height={"full"}>
      <Flex flexDir={"column"} alignItems={"flex-start"} width={"100%"}>
        <Heading color="teal" fontSize={[20, 25]} width={"100%"}>
          Lab Tests
        </Heading>
      </Flex>
      <Box w="100%" paddingX={"10"} color="black">
        <TestSlider slides={tests} />
      </Box>
    </VStack>
  );
};
