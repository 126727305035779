import React from "react";
import { Heading, Button } from "@chakra-ui/react";

export const getTotalPriceForLastFourMonths = (orders) => {
  const currentDate = new Date();
  const fourMonthsAgo = new Date(
    currentDate.getTime() - 4 * 30 * 24 * 60 * 60 * 1000
  );

  const lastFourMonthsOrders = orders.filter(
    (order) =>
      order.createdAt &&
      new Date(order.createdAt) >= fourMonthsAgo &&
      new Date(order.createdAt) <= currentDate
  );

  const totalPriceForLastFourMonths = lastFourMonthsOrders.reduce(
    (total, order) => total + (order.paymentDetails?.price || 0),
    0
  );

  return parseInt((75 * totalPriceForLastFourMonths) / 100);
};
export const Quarterly = ({ orders }) => {
  const totalPriceForQuarterly = getTotalPriceForLastFourMonths(orders);

  return (
    <>
      <Heading color={"teal"}> ₹ {totalPriceForQuarterly}</Heading>
      <Button variant={"ghost"} color={"teal"}>
        Last 4 Months
      </Button>
    </>
  );
};
