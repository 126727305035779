import { VStack } from "@chakra-ui/react";
import React from "react";
import { TopMain } from "../components/Landing/TopMain";
import { LandingSteps } from "../components/Landing/Steps";
import { WhyLabLikeUs } from "../components/Landing/WhyLabLikeUs";
import { ExploreDlab } from "../components/Landing/ExploreDlab";
import { WhyDPath } from "../components/Landing/Why";
import { LandingStats } from "../components/Landing/Stats";
import { Contact } from "../components/Landing/Contact";
import { FAQ } from "../components/Landing/FAQ";
import { Footer } from "../components/Footer";
import { SigninModal } from "../components/Modal/SigninModal";

export const HomePage = ({ isOpen, setOpen }) => {
  return (
    <VStack w={["full", "100%"]} overflowX={"hidden"}>
      <TopMain />
      <ExploreDlab />
      <WhyLabLikeUs />
      <WhyDPath />
      <LandingStats />
      <LandingSteps />
      <Contact />
      <FAQ />
      <Footer />
      <SigninModal />
    </VStack>
  );
};
