import React from "react";
import { Heading, Button } from "@chakra-ui/react";

export const getTotalPriceForLastWeek = (orders) => {
  const currentDate = new Date();
  const lastWeek = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000);

  const lastWeekOrders = orders.filter(
    (order) =>
      order.createdAt &&
      new Date(order.createdAt) >= lastWeek &&
      new Date(order.createdAt) <= currentDate
  );

  const totalPriceForLastWeek = lastWeekOrders.reduce(
    (total, order) => total + (order.paymentDetails?.price || 0),
    0
  );

  return parseInt((75 * totalPriceForLastWeek) / 100);
};

export const Weekly = ({ orders }) => {
  const totalPriceForWeekly = getTotalPriceForLastWeek(orders);

  return (
    <>
      <Heading color={"teal"}> ₹ {totalPriceForWeekly}</Heading>
      <Button variant={"ghost"} color={"teal"}>
        This Week
      </Button>
    </>
  );
};
