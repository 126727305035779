import React, { useEffect, useState } from "react";
import {
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  VStack,
  Input,
  Table,
  TableContainer,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  useToast,
  Text,
} from "@chakra-ui/react";
import axios from "axios";
import { ReportCard } from "../ReportCard";

export const UploadParametersModal = ({ open, setOpen, order, setOrder }) => {
  const [parameters, setParameters] = useState([]);
  const [patient, setPatient] = useState("");
  const [results, setResults] = useState([]);
  const [reportParameters, setReportParameters] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reportOpen, setReportOpen] = useState(false);

  const toast = useToast();

  const onChangeHandler = (e, p) => {
    const updatedResults = [...results];
    const existingResultIndex = updatedResults.findIndex(
      (result) => result.parameterId === p?._id
    );

    if (existingResultIndex !== -1) {
      updatedResults[existingResultIndex] = {
        value: e.target.value,
        parameterId: p?._id,
      };
    } else {
      updatedResults.push({ value: e.target.value, parameterId: p?._id });
    }

    setResults(updatedResults);

    // Update reportParameters state
    const existingReportParameterIndex = reportParameters.findIndex(
      (reportParam) => reportParam.parameterId === p?._id
    );

    if (existingReportParameterIndex !== -1) {
      reportParameters[existingReportParameterIndex] = {
        value: e.target.value,
        name: p?.name,
        unit: p?.unit,
        referralRange: p?.referralRange,
        parameterId: p?._id,
      };
    } else {
      reportParameters.push({
        value: e.target.value,
        name: p?.name,
        unit: p?.unit,
        referralRange: p?.referralRange,
        parameterId: p?._id,
      });
    }

    setReportParameters([...reportParameters]);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const response = await axios.put(
        `${process.env.REACT_APP_SERVER}/labs/orders/updateParameters/${order?._id}`,
        { parameterValues: results }
      );

      // console.log("callling update parameters res", response);
      setOrder(response?.data?.order);
      toast({
        title: "parameters updated successfully",
        duration: 9000,
        status: "success",
        isClosable: true,
      });
      if (response.status === 200) {
        await getPatient(response?.data?.order);
        setOpen(false);
        setReportOpen(true);
      }
    } catch (error) {
      console.error(error);
      toast({
        title: "error while updating paramters",
        duration: 9000,
        status: "error",
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const getPatient = async (order) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER}/getPatient?patientId=${order?.patientId}&userId=${order?.consumerDetails?.user?._id}`
      );

      // console.log("calling patient response", response);

      setPatient(response?.data?.patient);
    } catch (error) {
      console.error("error while fetching patient", error);
      toast({
        title: "error in getting patient details",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const fetchParameters = async (testId) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER}/getTestParameters/${testId}`
      );
      // console.log("calling parameters response", res?.data);

      return res?.data?.testParameter?.parameters || [];
    } catch (error) {
      toast({
        status: "error",
        title: "error in fetching parameters",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    const fetchParametersForAllTests = async () => {
      let combinedParameters = [];

      // if packages
      if (order?.packages) {
        const packageTests = order?.packages?.tests;
        const promises = packageTests.map((testId) => fetchParameters(testId)); // get all test parameters asynchronously
        const parametersArrays = await Promise.all(promises); // to concurrently fetch parameters for all test IDs in the package tests array
        parametersArrays.forEach((parameters) => {
          combinedParameters = [...combinedParameters, ...parameters];
        });
      } else {
        const testId = order?.test?._id;
        combinedParameters = await fetchParameters(testId);
      }

      setParameters(combinedParameters);
    };

    fetchParametersForAllTests();
  }, []);

  return (
    <>
      <Modal isOpen={open} onClose={() => setOpen(false)} size={"6xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <VStack
              flex={1}
              p={[1, 5]}
              spacing={[5, 7]}
              justifyContent={"center"}
            >
              <Heading fontSize={"2xl"}>Upload Parameters</Heading>
              <TableContainer width={"100%"}>
                <Table
                  variant="simple"
                  width="100%"
                  p="0"
                  css={{ tableLayout: "fixed" }}
                >
                  <Thead width="100%">
                    <Tr width="100%">
                      <Th width="25%">Name</Th>
                      <Th width="25%">Value</Th>
                      <Th width="25%">Unit</Th>
                      <Th width="25%">Referral Range</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {parameters?.map((p, index) => (
                      <Tr key={index} width="100%">
                        <Td width="25%">
                          <Text
                            display={"flex"}
                            alignItems={"center"}
                            justifyContent={"flex-start"}
                            flexWrap={"wrap"}
                            overflowWrap={"break-word"}
                            whiteSpace={"normal"}
                            maxWidth={"100%"}
                          >
                            {p?.name}
                          </Text>
                        </Td>
                        <Td width="25%">
                          <Input
                            type="number"
                            name="value"
                            value={results[index]?.value || ""}
                            placeholder="Enter value"
                            onChange={(e) => onChangeHandler(e, p)}
                          />
                        </Td>
                        <Td width="25%">
                          <Text>{p?.unit}</Text>
                        </Td>
                        <Td width="25%">
                          <Text
                            display={"flex"}
                            alignItems={"center"}
                            justifyContent={"flex-start"}
                            flexWrap={"wrap"}
                            overflowWrap={"break-word"}
                            whiteSpace={"normal"}
                            maxWidth={"100%"}
                          >
                            {p?.referralRange}
                          </Text>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
              <Button
                colorScheme="teal"
                variant="solid"
                borderRadius={50}
                isLoading={loading}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
      <ReportCard
        Open={reportOpen}
        setOpen={setReportOpen}
        reportParameters={reportParameters}
        order={order}
        setOrder={setOrder}
        patient={patient}
      />
    </>
  );
};
