import axios from "axios";

export const fetchLabOrders = async (labId) => {
  try {
    if (labId) {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER}/labs/${labId}/orders`
      );
      return res;
    } else {
      throw new Error("Lab ID is missing");
    }
  } catch (error) {
    console.error("Error fetching lab orders:", error);
    throw error;
  }
};
