import {
  Box,
  Button,
  Flex,
  IconButton,
  Switch,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Td,
  Text,
  Tr,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import useOwnStore from "../../../../store/store";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { deletePackage, DisabledPackage } from "../../../../api/package";

export const PackageList = ({ data, fetchData }) => {
  const { lab } = useOwnStore();
  // const [isMenu, setIsMenu] = React.useState(false);
  const toast = useToast();
  const navigate = useNavigate();

  const handleDeletePackage = async () => {
    try {
      // console.log("calling data in the handle delete", data);
      const response = await deletePackage(data?._id);
      // console.log("callling delete package res", response);
      fetchData();
      toast({
        title: "package deleted successfully",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error deleting package:", error);
      toast({
        title: 'error while deleting package',
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const [disabled, setDisabled] = useState(data?.disabled?.includes(lab._id));

  const handleChecked = async () => {
    try {
      const res = await DisabledPackage({
        labId: lab?._id,
        packageId: data?._id,
      });
      toast({
        title: "Checked successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error handling checked:", error);
      toast({
        title: "Error handling checked",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Tr
      justifyContent={"space-between"}
      width={"100%"}
      display={"flex"}
      alignItems={"center"}
      transition={"ease-in 0.2s"}
      cursor={"default"}
      _hover={{
        bg: "gray.100",
      }}
    >
      <Td
        sx={{
          width: "30%",
          display: "flex",
          flexDir: "column",
        }}
      >
        <Flex gap={3}>
          <Box>
            <Text
              color={"#2A2B2C"}
              sx={{
                cursor: "pointer",
              }}
              onClick={() => navigate(`/dashboard/packages/${data?._id}`)}
            >
              {data?.name}
            </Text>
          </Box>
        </Flex>
      </Td>
      <Td>₹ {data?.price}</Td>
      <Td isNumeric>{data?.createdAt.split("T")[0]}</Td>
      <Td>
        {data.tag === "basic" ? (
          <Switch
            size="lg"
            colorScheme="teal"
            onChange={(e) => {
              if (e.target.checked) {
                handleChecked();
                setDisabled(true);
              } else {
                setDisabled(false);
                handleChecked();
              }
            }}
            isChecked={disabled}
          />
        ) : (
          <Menu>
            {({ isOpen }) => (
              <>
                <MenuButton>
                  <IconButton
                    isActive={isOpen}
                    as={Button}
                    borderRadius={"50%"}
                  >
                    <BsThreeDotsVertical />
                  </IconButton>
                </MenuButton>
                <MenuList>
                  <MenuItem color={"red"} onClick={handleDeletePackage}>
                    Delete
                  </MenuItem>
                  {/* <MenuItem onClick={() => alert('Kagebunshin')}>Create a Copy</MenuItem> */}
                </MenuList>
              </>
            )}
          </Menu>
        )}
      </Td>
    </Tr>
  );
};
