import {
  Box,
  Button,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
  Spinner,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { PackageList } from "./List";
import useOwnStore from "../../../store/store";
import PackageModal from "../../Modal/PackageModel";
import { getPackages, getLabPackages } from "../../../api/package";
import { getLab } from "../../../cookie/lab";

export const Health = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [packagesdata, setPackagesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { setDashboardData } = useOwnStore();

  const lab = getLab();
  const fetchData = async () => {
    setLoading(true);
    try {
      // console.log("calling labId inside fetchdata function", lab);
      if (lab?._id) {
        const response = await getPackages({});
        const fetchPackages = response.packages;
        // console.log("calling lab inside fetchpackage from store", lab);
        const fetchLabPackages = await getLabPackages(lab?._id);
        const newData = [...fetchPackages, ...fetchLabPackages];
        // console.log("calling combined package data", newData);
        setPackagesData(newData?.reverse());
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
    // console.log("calling newPackage data in use effect", packagesdata);
    setDashboardData((dashboardData) => ({
      ...dashboardData,
      totalPackages: packagesdata?.length,
    }));
  }, []);

  return (
    <Box
      sx={{
        width: "full",
        height: "87vh",
        paddingLeft: 0,
        marginLeft: 64,
        paddingRight: 0,
        py: 10,
        display: "flex",
        flexDir: "column",
        gap: 1,
        overflowY: "scroll",
        alignItems: "center",
      }}
    >
      <Button
        variant={"outline"}
        colorScheme="teal"
        width={"64"}
        onClick={() => setIsModalOpen(true)}
        px={3}
        py={5}
      >
        + Create new package
      </Button>
      <Flex
        width={"full"}
        justifyContent={"center"}
        py={"8"}
        alignSelf={"center"}
      >
        <TableContainer>
          <Table
            variant="simple"
            width={"73vw"}
            display={"flex"}
            flexDir={"column"}
            justifyItems={"space-between"}
          >
            <Thead bg={"teal.300"}>
              <Tr
                justifyContent={"space-between"}
                width={"100%"}
                display={"flex"}
              >
                <Th>Package</Th>
                <Th></Th>
                <Th></Th>
                <Th>Price</Th>
                <Th isNumeric>Date Added</Th>
                <Th>Disable</Th>
              </Tr>
            </Thead>
            {loading ? (
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  height: "60vh",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Spinner size={"lg"} />
              </Box>
            ) : (
              <Tbody>
                {packagesdata?.map((e, i) => {
                  return <PackageList key={i} data={e} fetchData={fetchData} />;
                })}
              </Tbody>
            )}
          </Table>
        </TableContainer>
      </Flex>
      <PackageModal
        isOpen={isModalOpen}
        onClose={() => {
          return setIsModalOpen(false);
        }}
        data={packagesdata}
        setData={setPackagesData}
      />
    </Box>
  );
};
