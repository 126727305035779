import React, { useEffect } from "react";
import { ChakraProvider, theme } from "@chakra-ui/react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { HomePage, Layout } from "./pages";
import { Dashboard } from "./pages/Dashboard";
import { Revenue } from "./components/Dashboard/Revenue";
import { Customer } from "./components/Dashboard/Customer";
import { Orders } from "./components/Dashboard/Orders";
import { Help } from "./components/Dashboard/Help";
import { Test } from "./components/Dashboard/Test";
import { Health } from "./components/Dashboard/Health";
import { Training } from "./components/Dashboard/Training";
import { Inventory } from "./components/Dashboard/Inventory";
import useOwnStore from "./store/store";
import { Package } from "./pages/Package";
import AboutPage from "./pages/About";
import { Footer } from "./components/Footer";
import { LabProfile } from "./pages/LabProfile";
import { Wallet } from "./pages/Wallet";
import { Contact } from "./pages/Contact";

function App() {
  const [isOpen, setOpen] = React.useState(false);
  const { setLab } = useOwnStore();

  useEffect(() => {
    const lab = JSON.parse(localStorage.getItem("lab"));
    // console.log("calling lab from web-lab app component", lab);
    setLab(lab);
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout setOpen={setOpen} />}>
            <Route
              index
              element={
                <>
                  <HomePage isOpen={isOpen} setOpen={setOpen} />
                </>
              }
            />
          </Route>
          <Route
            path="/aboutus"
            element={
              <>
                <AboutPage />
                <Footer />
              </>
            }
          />
          <Route
            path="/mywallet"
            element={
              <>
                <Wallet />
                <Footer />
              </>
            }
          />
          <Route
            path="/contactus"
            element={
              <>
                <Contact />
                <Footer />
              </>
            }
          />
          <Route>
            <Route path="/dashboard" element={<Layout />}>
              <Route path="/dashboard/" element={<Dashboard />} />
              <Route path="/dashboard/profile" element={<LabProfile />} />
              <Route path="/dashboard/revenue" element={<Revenue />} />
              <Route path="/dashboard/customer" element={<Customer />} />
              <Route path="/dashboard/orders" element={<Orders />} />
              <Route path="/dashboard/help" element={<Help />} />
              <Route path="/dashboard/tests" element={<Test />} />
              <Route path="/dashboard/tests/:id" element={<Test />} />
              <Route path="/dashboard/packages" element={<Health />} />
              <Route path="/dashboard/packages/:id" element={<Package />} />
              <Route path="/dashboard/training" element={<Training />} />
              <Route path="/dashboard/inventory" element={<Inventory />} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
