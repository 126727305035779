import axios from "axios";

export const DisabledTest = async ({ labId, testId }) => {
  const res = await axios.post(`${process.env.REACT_APP_SERVER}/disabledTest`, {
    labId,
    testId,
  });
  return res.data.message;
};

export const getLabTests = async (desiredLabId) => {
  const res = await axios.get(`${process.env.REACT_APP_SERVER}/getLabTests`);
  //   console.log("calling lab test res in api", res, desiredLabId);
  const filteredLabTests = res?.data?.labTests?.filter(
    (labTest) => labTest.labId === desiredLabId
  );
  //   console.log(filteredLabTests);
  return filteredLabTests;
};

export const createTests = async (test) => {
  // console.log(test)
  const res = await axios.post(
    `${process.env.REACT_APP_SERVER}/createTest`,
    test
  );
  // console.log(res.data)
  return res.data;
};

export const deleteTest = async (id) => {
  // console.log(id)
  const res = await axios.delete(
    `${process.env.REACT_APP_SERVER}/deleteTest/${id}`
  );
  // console.log(res)
  return res.data;
};
