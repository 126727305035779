import {
  Button,
  Flex,
  HStack,
  Heading,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import useOwnStore from "../../../store/store";
import { useNavigate } from "react-router-dom";

export const LandingSteps = () => {
  const { lab, setSignin } = useOwnStore();
  const navigate = useNavigate();

  return (
    <VStack py={[10, 10]} spacing={[10, 0]}>
      <Flex flexDir={"column"} alignItems={"center"} gap={2}>
        <Heading
          fontSize={28}
          sx={{
            color: "#0C727E",
          }}
        >
          How to Join DLabs?
        </Heading>
        <Text fontWeight={500}> Easy 3 Step Registration Of Lab In DLabs </Text>
      </Flex>
      <HStack
        width={["full", "4xl"]}
        justifyContent={"space-between"}
        flexDir={["column", "row"]}
        px={5}
        spacing={10}
      >
        <VStack
          flex={1}
          display={"flex"}
          p={[0, 10]}
          px={[10, 10]}
          width={["full", "10"]}
          sx={{
            textAlign: ["center", "flex-start !important"],
          }}
        >
          <Heading fontSize={25} color={"#0C727E"}>
            Step-1
          </Heading>
          <Text fontWeight={500} fontSize={17}>
            Provide your lab details, including the address, so that we can
            readily list your lab on our website.
          </Text>
        </VStack>
        <Flex
          flex={1}
          justifyContent={"center"}
          width={["full", "4xl"]}
          alignItems={"center"}
        >
          <Image src="/assets/landing/steps/1.png" width={250} height={250} />
        </Flex>
      </HStack>
      <HStack
        width={["full", "4xl"]}
        justifyContent={"space-between"}
        flexDir={["column-reverse", "row"]}
        spacing={10}
      >
        <Flex
          flex={1}
          justifyContent={"center"}
          width={["full", "4xl"]}
          alignItems={["center"]}
        >
          <Image src="/assets/landing/steps/2.png" width={250} height={250} />
        </Flex>
        <VStack
          flex={1}
          display={"flex"}
          p={[0, 10]}
          px={[10, 10]}
          width={["full", "10"]}
          sx={{
            textAlign: ["center"],
          }}
        >
          <Heading fontSize={25} color={"#0C727E"}>
            Step-2
          </Heading>
          <Text fontWeight={500} fontSize={17}>
            Provide the lab owner's details so that we can establish a
            connection with them.
          </Text>
        </VStack>
      </HStack>
      <HStack
        width={["full", "4xl"]}
        justifyContent={"space-between"}
        flexDir={["column", "row"]}
        spacing={10}
      >
        <VStack
          flex={1}
          display={"flex"}
          p={[0, 10]}
          px={[10, 10]}
          width={["full", "8"]}
          sx={{
            textAlign: ["center", "flex-start !important"],
          }}
        >
          <Heading fontSize={25} color={"#0C727E"}>
            Step-3
          </Heading>
          <Text fontWeight={500} fontSize={17}>
            Upon paying the lab listing fee, you will gain access to your lab
            digitally on dLab.
          </Text>
        </VStack>
        <Flex
          flex={1}
          justifyContent={"center"}
          width={["full", "4xl"]}
          alignItems={"center"}
        >
          <Image src="/assets/landing/steps/3.png" width={250} height={250} />
        </Flex>
      </HStack>
      {lab === "" || lab === null ? (
        <Button
          bg={"#0C727E"}
          color={"white"}
          _hover={{ color: "white" }}
          width={["180px", "sm"]}
          sx={{
            mt: 5,
          }}
          onClick={() => setSignin(true)}
        >
          Join DLabs Today!
        </Button>
      ) : (
        <Button
          bg={"#0C727E"}
          color={"white"}
          _hover={{ color: "white" }}
          width={["180px", "sm"]}
          sx={{
            mt: 5,
          }}
          onClick={() => navigate("/dashboard")}
        >
          Go To Dashboard
        </Button>
      )}
    </VStack>
  );
};
