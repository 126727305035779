import {
  Heading,
  VStack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Link,
} from "@chakra-ui/react"
import React from "react"

export const FAQ = () => {
  return (
    <VStack
      width={["full", "full"]}
      spacing={["8", "12"]}
      p={[7, 10]}
      overflowX={"hidden"}
    >
      <Heading color={"#0C727E"}>FAQ</Heading>
      <VStack spacing={3}>
        <Accordion allowToggle width={"100%"}>
          <AccordionItem
            width={["full", "6xl"]}
            justifyContent={"space-between"}
          >
            <h2>
              <AccordionButton
                px={[4, 8]}
                py={[3, 5]}
                width={"full"}
                justifyContent={"space-between"}
                textAlign={"start"}
              >
                <Heading fontSize={[15, 17]} fontWeight={500}>
                  How should I book an appointment for a Lab test/Health
                  Checkup?
                </Heading>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} paddingLeft={8}>
              You can book an appointment via the following steps: <br /> •
              Choose your city Pincode <br /> • Choose your near by Laboratory
              or package/lab test & add it to the cart <br /> • Add additional
              tests if needed <br />• You can book tests <br /> • Select date
              ,slot & time
              <br /> • Confirm your patient information
              <br /> • Appointment confirmed
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem
            width={["full", "6xl"]}
            justifyContent={"space-between"}
          >
            <h2>
              <AccordionButton
                px={[4, 8]}
                py={[3, 5]}
                width={"full"}
                justifyContent={"space-between"}
                textAlign={"start"}
              >
                <Heading fontSize={[15, 17]} fontWeight={500}>
                  How many days in advance do I need to book an appointment?
                </Heading>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} paddingLeft={8}>
              You can book the appointment or sample collection from home for
              same day
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem
            width={["full", "6xl"]}
            justifyContent={"space-between"}
          >
            <h2>
              <AccordionButton
                px={[4, 8]}
                py={[3, 5]}
                width={"full"}
                justifyContent={"space-between"}
                textAlign={"start"}
              >
                <Heading fontSize={[15, 17]} fontWeight={500}>
                  Can an appointment be canceled?
                </Heading>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} pl={8}>
              Yes ,the appointment can be cancelled with in a given Time limit.
              <br />• Select the Profile icon on the top-right corner <br />•
              Select Track orders <br />• Select Cancel
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        <Link color={"#0C727E"}>View more</Link>
      </VStack>
    </VStack>
  )
}
