import React from "react";
import { Heading, Button } from "@chakra-ui/react";

export const getTotalPriceForYesterday = (orders) => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  const yesterdayDate = yesterday.toISOString().split("T")[0];

  const yesterdayOrders = orders.filter(
    (order) =>
      order.createdAt && order.createdAt.split("T")[0] === yesterdayDate
  );

  const totalPriceForYesterday = yesterdayOrders.reduce(
    (total, order) => total + order.paymentDetails?.price,
    0
  );

  return parseInt((75 * totalPriceForYesterday) / 100);
};
export const Yesterday = ({ orders }) => {
  const totalPriceForYesterday = getTotalPriceForYesterday(orders);

  return (
    <>
      <Heading color={"teal"}> ₹ {totalPriceForYesterday}</Heading>
      <Button variant={"ghost"} color={"teal"}>
        Yesterday
      </Button>
    </>
  );
};
