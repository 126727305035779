import { NavBar } from "../components";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Menu } from "../components/Menu";
import { Box, Flex } from "@chakra-ui/react";
import { useEffect } from "react";
import useOwnStore from "../store/store";

export const Layout = ({ setOpen }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { lab, setSignin } = useOwnStore();

  useEffect(() => {
    if (lab === null) {
      navigate("/");
    }
  },[]);

  return (
    <>
      <Box>
        <NavBar setOpen={setOpen} />
        {location.pathname === "/" ? (
          <>
            <Menu />
            <Outlet />
          </>
        ) : (
          <>
            <Flex>
              <Menu />
              <Outlet />
            </Flex>
          </>
        )}
      </Box>
    </>
  );
};
