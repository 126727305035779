import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  VStack,
  Input,
  useToast,
  HStack,
  Heading,
  Image,
  InputGroup,
  InputLeftAddon,
} from "@chakra-ui/react";
import axios from "axios";
import { IconCurrencyRupee } from "@tabler/icons-react";
import { getLab } from "../../../../cookie/lab";

export const AddMoneyModal = ({ isOpen, onClose }) => {
  const lab = getLab();
  const labId = lab?._id;

  const currentDate = new Date()
    .toLocaleDateString("en-IN", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    })
    .replace(/\//g, "-");

  const [transaction, setTransaction] = useState({
    transactionName: "wallet Recharge",
    amount: "",
    date: currentDate,
    paymentMethod: "card",
    referenceId: "",
  });

  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const [checkoutSuccess, setCheckoutSuccess] = useState(false);

  // console.log("calling total order price", transaction?.amount);

  const verificationHandler = async (response) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER}/paymentverification`,
        { response }
      );
      // console.log("calling verification res inside handler", res);
      setTransaction({
        ...transaction,
        referenceId: res?.data?.razorpayOrder?.razorpay_payment_id,
      });
      setCheckoutSuccess(true);
    } catch (error) {
      console.error("error in the verification request");
    }
  };

  const razorpayCheckoutHandler = async () => {
    setLoading(true);
    try {
      // console.log(
      //   'calling total order price inside checkout handler',
      //   totalOrderPrice
      // );
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER}/checkoutOrder`,
        { totalOrderPrice: transaction?.amount }
      );

      const orderId = res.data.razorpayOrder.id;

      //   setTransaction({
      //     ...transaction,
      //     amount: res?.data?.razorpayOrder?.amount,
      //   });
      // console.log('calling orderId', orderId);

      // console.log("calling checkout order res", res);

      const options = {
        key: process.env.REACT_APP_RAZORPAY_API_KEY,
        amount: res?.data?.razorpayOrder?.amount,
        currency: "INR",
        name: lab?.name,
        description: `This is a Wallet Recharge for lab : ${lab?.labName}`,
        image: "/assets/landing/steps/3.png",
        order_id: orderId,
        handler: async (response) => verificationHandler(response),
        prefill: {
          name: lab?.ownerName,
          email: lab?.email ? lab.email : "xyz@gmail.com",
          contact: lab?.phone,
        },
        notes: {
          address: lab?.address,
        },
        theme: {
          color: "#0c727e",
        },
      };

      const razor = new window.Razorpay(options);
      razor.open();
    } catch (error) {
      console.error("error while creating checkout", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (checkoutSuccess) {
      handleSubmit();
    }
  }, [checkoutSuccess]);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER}/addMoneyToWallet`,
        {
          labId,
          transaction,
        }
      );
      localStorage.setItem("lab", JSON.stringify(res?.data?.lab));
      onClose();

      toast({
        title: "wallet money added successfully",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error Adding Money:", error.message);
      toast({
        title: "Error Adding Money",
        description: error.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"4xl"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Recharge Your Wallet</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <HStack alignItems={"flex-start"} spacing={5}>
            <VStack flex={1} p={5} display={["none", "flex"]}>
              <Image src={"/assets/landing/steps/3.png"} width={"64"} />
            </VStack>
            <VStack flex={1} p={[1, 5]} spacing={7}>
              <Heading>Add Money To the Wallet</Heading>
              <InputGroup>
                <InputLeftAddon>
                  <IconCurrencyRupee />
                </InputLeftAddon>
                <Input
                  type="number"
                  placeholder="Add Amount in INR"
                  name="amount"
                  value={transaction?.amount}
                  onChange={(event) =>
                    setTransaction({
                      ...transaction,
                      [event.target.name]: event.target.value,
                    })
                  }
                  focusBorderColor="teal.500"
                />
              </InputGroup>

              <Button
                width={"full"}
                isLoading={loading}
                variant={"solid"}
                colorScheme="teal"
                onClick={razorpayCheckoutHandler}
              >
                Recharge
              </Button>
            </VStack>
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
