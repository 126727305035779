import React, { useState } from "react";
import {
  Button,
  Text,
  Input,
  Stack,
  Flex,
  Textarea,
  Select,
  useToast,
} from "@chakra-ui/react";
import useOwnStore from "../../../store/store";
import axios from "axios";

export const RegistrationStep3 = ({ onChangeHandler }) => {
  const { setSignin, setSignup, lab } = useOwnStore();
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  async function submitHandler() {
    setLoading(true);
    // console.log("calling lab data before registration", lab);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER}/registerLab`,
        { lab }
      );
      // console.log("calling register lab response", response);
      if (response?.status === 201) {
        toast({
          title: "lab registration request sent successfully",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
      }
    } catch (error) {
      console.error("error while registering the lab", error);
      toast({
        title: "error while resgitering the lab",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Flex gap={3} width={"100%"}>
        <Input
          type="number"
          placeholder="Pincode"
          name="pincode"
          value={lab?.pincode}
          onChange={(e) => onChangeHandler(e)}
          focusBorderColor="teal.500"
        />
        <Select
          placeholder="Lab Type"
          name="labType"
          value={lab?.labType}
          onChange={(e) => onChangeHandler(e)}
          focusBorderColor="teal.500"
        >
          <option value={"Pathology"}>Pathology</option>
          <option value={"Radiology"}>Radiology</option>
          <option value={"Both"}>Both</option>
        </Select>
      </Flex>
      <Flex gap={3} width={"100%"}>
        <Input
          type="number"
          name="labSize"
          value={lab?.labSize}
          onChange={onChangeHandler}
          placeholder="Lab Size in sqft"
          focusBorderColor="teal.500"
        />
        <Input
          type="number"
          placeholder="Sample Capacity"
          name="sampleCapacity"
          value={lab?.sampleCapacity}
          onChange={(e) => onChangeHandler(e)}
          focusBorderColor="teal.500"
        />
      </Flex>
      <Textarea
        name="landmark"
        value={lab?.landmark}
        onChange={(e) => onChangeHandler(e)}
        placeholder="Land Mark Location"
        focusBorderColor="teal.500"
      />
      <Stack spacing={3} width={"100%"}>
        <Button
          bg={"#0C727E"}
          colorScheme="teal"
          fontWeight={400}
          width={"100%"}
          isLoading={loading}
          onClick={submitHandler}
        >
          Register
        </Button>
        <Text display={"flex"} fontSize={12} fontWeight={500} gap={1}>
          Already have an account?
          <Text
            color={"#0C727E"}
            _hover={{ cursor: "pointer", textDecor: "underline" }}
            onClick={() => {
              setSignin(true);
              setSignup(false);
            }}
          >
            Sign In
          </Text>
        </Text>
      </Stack>
    </>
  );
};
