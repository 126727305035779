import {
  Card,
  CardBody,
  Heading,
  Image,
  Text,
  VStack,
  HStack,
} from "@chakra-ui/react";
import "react-multi-carousel/lib/styles.css";
import React from "react";

// const settings = {
// 	dots: true,
// 	arrows: false,
// 	fade: true,
// 	infinite: true,
// 	autoplay: true,
// 	speed: 500,
// 	autoplaySpeed: 5000,
// 	slidesToShow: 1,
// 	slidesToScroll: 1
// };

export const WhyLabLikeUs = () => {
  return (
    <VStack p={10} spacing={10}>
      <Heading color="teal">Why Labs Like Us!</Heading>
      <HStack
        spacing={[8, 5]}
        width={["full", "6xl"]}
        flexDir={["column", "row"]}
      >
        <Card maxW={["sm"]} width={"100%"} h={"320px"} boxShadow={"2xl"}>
          <CardBody alignItems={"center"} width={"100%"}>
            <Image
              src="/assets/landing/user4.avif"
              objectFit={"cover"}
              borderRadius="lg"
              width={"100%"}
              h={"200px"}
              p={1}
            />
            <VStack
              width={"100%"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Heading
                size={"sm"}
                textAlign={"left"}
                width={"100%"}
                color={"teal"}
              >
                Darlene Robertson
              </Heading>
              <Text fontSize={"lg"} textAlign={"left"} width={"100%"}>
                Dlab maximized our revenue streams effectively.
              </Text>
            </VStack>
          </CardBody>
        </Card>
        <Card maxW={["sm"]} width={"100%"} h={"320px"} boxShadow={"2xl"}>
          <CardBody alignItems={"center"} width={"100%"}>
            <Image
              src="/assets/landing/user5.jpg"
              objectFit={"cover"}
              borderRadius="lg"
              width={"100%"}
              h={"200px"}
              p={1}
            />
            <VStack
              width={"100%"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Heading
                size={"sm"}
                textAlign={"left"}
                width={"100%"}
                color={"teal"}
              >
                Thomas Johnson
              </Heading>
              <Text fontSize={"lg"} textAlign={"left"} width={"100%"}>
                Dlab has hassle-free operations.
              </Text>
            </VStack>
          </CardBody>
        </Card>
        <Card maxW={["sm"]} width={"100%"} h={"320px"} boxShadow={"2xl"}>
          <CardBody alignItems={"center"} width={"100%"}>
            <Image
              src="/assets/landing/user2.jpg"
              objectFit={"cover"}
              borderRadius="lg"
              width={"100%"}
              h={"200px"}
              p={1}
            />
            <VStack
              width={"100%"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Heading
                size={"sm"}
                textAlign={"left"}
                width={"100%"}
                color={"teal"}
              >
                Himanshu Goyal
              </Heading>
              <Text fontSize={"lg"} textAlign={"left"} width={"100%"}>
                Dlab helped us in online reachability.
              </Text>
            </VStack>
          </CardBody>
        </Card>
        <Card maxW={["sm"]} width={"100%"} h={"320px"} boxShadow={"2xl"}>
          <CardBody alignItems={"center"} width={"100%"}>
            <Image
              src="/assets/landing/user3.webp"
              objectFit={"cover"}
              borderRadius="lg"
              width={"100%"}
              h={"200px"}
              p={1}
            />
            <VStack
              width={"100%"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Heading
                size={"sm"}
                textAlign={"left"}
                width={"100%"}
                color={"teal"}
              >
                Kayle Mayers
              </Heading>
              <Text fontSize={"lg"} textAlign={"left"} width={"100%"}>
                Dlab optimized patient experiences remarkably.
              </Text>
            </VStack>
          </CardBody>
        </Card>
      </HStack>
    </VStack>
  );
};
