import React from "react";
import { Td, Tr, Tbody, Text } from "@chakra-ui/react";

export const ListElement = ({ key, orderDetails }) => {
  // Check if the user has a phone number
  const hasPhoneNumber = orderDetails?.consumerDetails?.user?.phone;

  return (
    <>
      {hasPhoneNumber && (
        <Tr key={key} width="100%">
          <Td>
            <Text
              display={"flex"}
              alignItems={"center"}
              justifyContent={"flex-start"}
              flexWrap={"wrap"}
              overflowWrap={"break-word"}
              whiteSpace={"normal"}
              maxWidth={"100%"}
            >
              {orderDetails?.name}
            </Text>
          </Td>

          <Td>
            <Text
              display={"flex"}
              alignItems={"center"}
              justifyContent={"flex-start"}
              flexWrap={"wrap"}
              overflowWrap={"break-word"}
              whiteSpace={"normal"}
              maxWidth={"100%"}
            >
              {orderDetails?.createdAt
                .split("T")[0]
                .split("-")
                .reverse()
                .join("-")}
            </Text>
          </Td>
          <Td isNumeric>
            <Text>
              {parseInt((75 * orderDetails?.paymentDetails?.price) / 100)}
            </Text>
          </Td>
        </Tr>
      )}
    </>
  );
};
