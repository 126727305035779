import {
  Box,
  Heading,
  Text,
  Image,
  HStack,
  VStack,
  Flex,
  Menu,
  List,
  ListItem,
  Stack,
  Grid,
} from "@chakra-ui/react";
import about from "../assets/about/lab_search/about.png";
import spiral from "../assets/about/lab_search/130.svg";
import abouttwo from "../assets/about/lab_search/about2.png";
import Deepanshu from "../assets/about/lab_search/Dipanshu.jpeg";
import Lokesh from "../assets/about/lab_search/Lokesh.jpeg";
import Rohini from "../assets/about/lab_search/P3.png";
import Warish from "../assets/about/lab_search/Warish.jpeg";
import { Outlet } from "react-router-dom";
import { NavBar } from "../components";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { AboutCard } from "../components/AboutCard";

export const AboutPage = () => {
  const STEPS = [
    {
      title: "Booked safe & hygienic lab tests From approved labs",
      image: "/assets/landing/stats/1.png",
    },
    {
      title: "Book Your Corporate Sponsored Health Checkups",
      image: "/assets/landing/stats/2.png",
    },
    {
      title: "Home Sample collection available any time",
      image: "/assets/landing/stats/3.png",
    },
  ];

  const Founder = [
    {
      imageSrc: Deepanshu,
      name: "Deepanshu Sharma",
      role: "Founder & CEO",
      facebookUrl: "",
      instagramUrl: "",
      twitterUrl: "",
      linkedInUrl: "",
    },
    {
      imageSrc: Lokesh,
      name: "Lokesh Kabra",
      role: "Co-Founder & CTO",
      facebookUrl: "",
      instagramUrl: "",
      twitterUrl: "",
      linkedInUrl: "",
    },
  ];
  const Team = [
    {
      imageSrc: Rohini,
      name: "Rohini Patil",
      role: "UI/UX Designer",
      facebookUrl: "",
      instagramUrl: "",
      twitterUrl: "",
      linkedInUrl: "",
    },
    {
      imageSrc: Warish,
      name: "Mohd Warish",
      role: "Full Stack Developer",
      facebookUrl: "",
      instagramUrl: "",
      twitterUrl: "",
      linkedInUrl: "",
    },
  ];

  return (
    <>
      <Box>
        <NavBar />
        <>
          <Flex>
            <Menu />
            <Outlet />
          </Flex>
        </>
      </Box>
      <Box>
        <Box
          display={"flex"}
          maxWidth={"100%"}
          height={"100%"}
          bgColor={"teal.400"}
          padding={"5"}
        >
          <Box
            display={"flex"}
            flexDirection={"column"}
            width={"50%"}
            height={"50%"}
            textAlign={"justify"}
            color={"white"}
            marginTop={"80px"}
            marginLeft={"40px"}
            gap={"5"}
          >
            <Heading fontSize={"50px"}>Our Mission</Heading>
            <Text fontSize={"lg"} textAlign={"justify"} width={"80%"}>
              D Labs is a global platform that empowers entrepreneurs,
              Laboratory Technicians, Doctors And small business with Laboratory
              and clinics By providing full stack technology that Increases
              earnings and eases operations. Bringing affordable & trusted Lab
              tests that patient Can book instantly.
            </Text>
          </Box>
          <Box width={"50%"} height={"50%"}>
            <Image
              src={about}
              width={"400px"}
              height={"400px"}
              marginLeft={"270px"}
            />
          </Box>
        </Box>
        <Stack
          width={"100%"}
          justifyContent={"center"}
          flexDirection={"column"}
          paddingTop={"24"}
          alignItems={"center"}
        >
          <Heading width={"100%"} textAlign={"center"} color={"teal"}>
            About Dlabs
          </Heading>
          <Stack
            width={["100%", "100%"]}
            paddingX={["5", "12"]}
            justifyContent={"center"}
          >
            <Grid
              templateColumns={{
                sm: "1fr",
                md: "1fr 1fr",
                lg: "1fr 1fr",
              }}
              width={"100%"}
              gap={["0", "0", "8"]}
              px={"5"}
              height={["auto", "auto", "auto"]}
            >
              <Box
                width={"100%"}
                display="flex"
                alignItems={"center"}
                justifyContent={"flex-start"}
                order={["1", "1", "2"]}
              >
                <Image width={"80%"} src={spiral} objectFit="contain" />
              </Box>

              <VStack
                alignItems={["center", "center", "flex-start"]}
                justifyContent={"center"}
                px={"12"}
                height={["100%", "100%"]}
                minWidth={"50%"}
                zIndex={"5"}
                order={["1", "1", "2"]}
              >
                <Text textAlign={["center", "justify"]} fontSize={"xl"}>
                  DLabs is India's premier on-demand digital healthcare
                  platform, offering affordable lab tests and a wide array of
                  healthcare services accessible from home or through our
                  network of diagnostic centers. We empower healthcare
                  professionals, support small labs in going digital, and strive
                  to simplify the lives of laboratory owners by maximizing
                  revenue channels and leveraging technological expertise for
                  increased demand and growth.
                </Text>
              </VStack>
            </Grid>
          </Stack>
        </Stack>
        <Stack
          width="100%"
          p={["5", "16"]}
          flexDirection="column"
          justifyContent={"center"}
        >
          <Heading textAlign={"center"} color={"teal"}>
            Why Choose DLabs
          </Heading>
          <Stack
            borderRadius={"24"}
            width={["100%", "100%"]}
            paddingX={["5", "16"]}
            justifyContent={"center"}
          >
            <Grid
              templateColumns={{
                sm: "1fr",
                md: "1fr 1fr",
                lg: "1fr 1fr",
              }}
              width={"100%"}
              gap={["0", "0", "8"]}
              px={["5", "8"]}
              height={["auto", "auto", "auto"]}
            >
              <Box
                width={"100%"}
                display="flex"
                alignItems={"center"}
                justifyContent={"flex-end"}
                order={["1", "1", "2"]}
              >
                <Image width={"60%"} src={abouttwo} objectFit="contain" />
              </Box>

              <VStack
                alignItems={["center", "center", "flex-start"]}
                justifyContent={"center"}
                spacing={4}
                height={["100%", "100%"]}
                px={["0", "8"]}
                minWidth={"50%"}
                zIndex={"5"}
                order={["2", "2", "1"]}
              >
                <List
                  display={"flex"}
                  flexDirection={"column"}
                  width={"100%"}
                  justifyContent={"center"}
                  alignItems={"flex-start"}
                >
                  <ListItem width={"100%"}>
                    <HStack width={"100%"} justifyContent={"flex-start"}>
                      <CheckCircleIcon fontSize={"xl"} color={"teal"} />
                      <Text
                        textAlign={["center", "left"]}
                        fontSize={"xl"}
                        width={"100%"}
                      >
                        For Maximising Revenue
                      </Text>
                    </HStack>
                  </ListItem>
                  <ListItem width={"100%"}>
                    <HStack width={"100%"} justifyContent={"flex-start"}>
                      <CheckCircleIcon fontSize={"xl"} color={"teal"} />
                      <Text textAlign={["center", "left"]} fontSize={"xl"}>
                        To Build Online Presence Across Channels
                      </Text>
                    </HStack>
                  </ListItem>
                  <ListItem width={"100%"}>
                    <HStack width={"100%"} justifyContent={"flex-start"}>
                      <CheckCircleIcon fontSize={"xl"} color={"teal"} />
                      <Text textAlign={["center", "left"]} fontSize={"xl"}>
                        To Reach More Patients & Optimize Experience
                      </Text>
                    </HStack>
                  </ListItem>
                  <ListItem width={"100%"}>
                    <HStack width={"100%"} justifyContent={"flex-start"}>
                      <CheckCircleIcon fontSize={"xl"} color={"teal"} />
                      <Text textAlign={["center", "left"]} fontSize={"xl"}>
                        For Hassle-Free Operations
                      </Text>
                    </HStack>
                  </ListItem>
                  <ListItem width={"100%"}>
                    <HStack width={"100%"} justifyContent={"flex-start"}>
                      <CheckCircleIcon fontSize={"xl"} color={"teal"} />
                      <Text
                        textAlign={["center", "left"]}
                        fontSize={"xl"}
                        width={"100%"}
                      >
                        To Build A Brand Loved By Both Patients &<br></br> City
                        Laboratory Owners.
                      </Text>
                    </HStack>
                  </ListItem>
                </List>
              </VStack>
            </Grid>
          </Stack>
        </Stack>
        <VStack width={"full"}>
          <Stack
            width={"full"}
            flexDir={["column", "row"]}
            spacing={["16", "24"]}
            alignItems={"center"}
            justifyContent={"center"}
            p={["8", "12"]}
            bgColor={"teal.400"}
            color={"#fff"}
          >
            {STEPS.map((s, i) => (
              <VStack spacing={1} key={i}>
                <Image src={s.image} width={"50%"} />
                <Text
                  textAlign={"center"}
                  fontWeight={500}
                  fontSize={21}
                  width={"50%"}
                  as="h4"
                >
                  {s.title}
                </Text>
              </VStack>
            ))}
          </Stack>
        </VStack>
        <VStack
          width={"100%"}
          h={"full"}
          justifyContent={"center"}
          alignItems={"center"}
          paddingBottom={"24"}
        >
          <Box
            maxWidth={"100%"}
            height={"100%"}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Heading paddingY={"24"} color={"teal.600"} height={"20px"}>
              Founders of DLabs
            </Heading>

            <Box
              width={"100%"}
              height={"100%"}
              display={"flex"}
              flexDirection={["column", "row"]}
              justifyContent={"center"}
              alignItems={"center"}
              gap={"16"}
            >
              {Founder?.map((cardItem) => (
                <AboutCard cardItem={cardItem} />
              ))}
            </Box>
          </Box>
          <Box
            maxWidth={"100%"}
            height={"100%"}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Heading color={"teal.600"} paddingY={"24"} height={"20px"}>
              Meet Our Team
            </Heading>

            <Box
              width={"100%"}
              height={"100%"}
              display={"flex"}
              flexDirection={["column", "row"]}
              justifyContent={"center"}
              alignItems={"center"}
              gap={"16"}
            >
              {Team?.map((cardItem) => (
                <AboutCard cardItem={cardItem} />
              ))}
            </Box>
          </Box>
        </VStack>
      </Box>
    </>
  );
};

export default AboutPage;
