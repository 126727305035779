import { Box, Flex, Heading, Input, VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import { getLabPackages } from "../../../api/package";
import { PackageSlider } from "./PackageSlider";
import { getLab } from "../../../cookie/lab";

export const LabPackages = () => {
  const [packages, setPackages] = useState([]);
  const lab = getLab();
  //   React.useEffect(() => {
  //     import("@lottiefiles/lottie-player");
  //   });

  const fetchData = async () => {
    try {
      if (lab?._id) {
        // console.log("calling lab inside fetchpackage from store", lab);
        const fetchLabPackages = await getLabPackages(lab?._id);
        setPackages(fetchLabPackages?.reverse());
        // console.log("calling combined package data", newData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <VStack height={"full"}>
      <Flex flexDir={"column"} alignItems={"flex-start"} width={"100%"}>
        <Heading color="teal" fontSize={[20, 25]} width={"100%"}>
          Lab Packages
        </Heading>
      </Flex>
      <Box w="100%" paddingX={"10"} color="black">
        <PackageSlider slides={packages} />
      </Box>
    </VStack>
  );
};
