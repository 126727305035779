import { Flex, HStack } from "@chakra-ui/react";
import { Logo } from "../Logo";
import { Navigations } from "./Navigations";
import { useLocation } from "react-router-dom";

export const NavBar = () => {
  const location = useLocation();

  // const menu = [
  //   "/dashboard",
  //   "/dashboard/customer",
  //   "/dashboard/revenue",
  //   "/dashboard/orders",
  //   "/dashboard/test",
  //   "/dashboard/packages",
  //   "/dashboard/training",
  //   "/dashboard/inventory",
  //   "/dashboard/help",
  // ];

  return (
    <HStack
      alignItems={"center"}
      justifyContent={"space-between"}
      px={5}
      py={[4, 5]}
      width={"100%"}
      bg={location.pathname !== "/" ? "#E5F6FB" : ""}
      height={"13vh"}
    >
      <Logo />
      {/* <SearchBar/> */}
      <Flex display={["none", "flex"]}></Flex>
      <Navigations />
    </HStack>
  );
};
