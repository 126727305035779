import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  VStack,
  Input,
  useToast,
  HStack,
  Heading,
  Image,
} from "@chakra-ui/react";

import { getLab } from "../../../../cookie/lab";

export const BankDetailsModal = ({
  isOpen,
  onClose,
  bankDetails,
  setBankDetails,
}) => {
  const lab = getLab();
  // console.log("calling bankdetails ", bankDetails);

  const [bankData, setBankData] = useState(bankDetails);

  // console.log("calling bank Data ", bankData);
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const onChangeHandler = (e) => {
    setBankData({ ...bankData, [e.target.name]: e.target?.value });
  };

  const submitHandler = async () => {
    try {
      if (lab?._id) {
        setLoading(true);
        // const res = await axios.put(
        //   `${process.env.REACT_APP_SERVER}/bankDetails/update/${lab?._id}`,
        //   bankData
        // );
        // setBankDetails(res?.data?.bankDetails);
        onClose();

        toast({
          title: "your update request has been sent",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error updating bank details:", error.message);
      toast({
        title: "Error updating bank details",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"4xl"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Update Bank Details</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <HStack alignItems={"flex-start"} spacing={5}>
            <VStack flex={1} p={5} display={["none", "flex"]}>
              <Heading>Send Update Request to Support Team</Heading>
              <Image src={"/assets/landing/steps/3.png"} width={"64"} />
            </VStack>
            <VStack flex={1} p={[1, 5]} spacing={7}>
              <Input
                type="text"
                placeholder="Account Holder Name"
                name="accountHolder"
                value={bankData?.accountHolder}
                focusBorderColor="teal.500"
                onChange={(e) => onChangeHandler(e)}
              />
              <Input
                type="text"
                placeholder="Account Number"
                name="accountNumber"
                value={bankData?.accountNumber}
                focusBorderColor="teal.500"
                onChange={(e) => onChangeHandler(e)}
              />
              <Input
                type="text"
                placeholder="Bank Name"
                name="bankName"
                value={bankData?.bankName}
                focusBorderColor="teal.500"
                onChange={(e) => onChangeHandler(e)}
              />
              <Input
                type="text"
                placeholder="Branch Name"
                name="branchName"
                value={bankData?.branchName}
                focusBorderColor="teal.500"
                onChange={(e) => onChangeHandler(e)}
              />
              <Button
                width={"full"}
                variant={"solid"}
                colorScheme="teal"
                onClick={submitHandler}
                isLoading={loading}
              >
                Send Update Request
              </Button>
            </VStack>
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
