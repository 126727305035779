import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { OrderList } from "./List";
import useOwnStore from "../../../store/store";
import { fetchLabOrders } from "../../../api/order";
import { getLab } from "../../../cookie/lab";

export const Orders = () => {
  const { setDashboardData } = useOwnStore();
  const lab = getLab();
  const [orders, setOrders] = useState([]);
  const toast = useToast();
  const [loading, setLoading] = useState(false);

  const fetchOrders = async () => {
    setLoading(true);
    try {
      if (lab?._id) {
        const res = await fetchLabOrders(lab?._id);
        // console.log("calling orderd res", res?.data?.labOrders);

        setOrders(res?.data?.labOrders?.reverse());
      }
    } catch (error) {
      toast({
        title: "error in fetching the lab details",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      // console.log("error in fetching lab details");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders();
    setDashboardData((dashboardData) => ({
      ...dashboardData,
      totalOrders: orders.length,
    }));
  }, []);

  return (
    <Flex
      sx={{
        width: "full",
        height: "87vh",
        paddingLeft: 0,
        marginLeft: 64,
        paddingRight: 0,
        py: 10,
        display: "flex",
        flexDir: "column",
        gap: 1,
        overflowY: "scroll",
        alignItems: "center",
      }}
    >
      <TableContainer overflowY={"scroll"}>
        <Table
          variant="simple"
          width={"73vw"}
          display={"flex"}
          flexDir={"column"}
          justifyItems={"space-between"}
        >
          <Thead bg={"teal.300"}>
            <Tr
              justifyContent={"space-between"}
              width={"100%"}
              display={"flex"}
            >
              <Th>OrderName</Th>
              <Th></Th>
              <Th marginLeft={"16"}>Price</Th>
              <Th isNumeric>Ordered Date</Th>
              <Th>Status</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                width: "100%",
                height: "60vh",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Spinner size={"lg"} />
            </Box>
          ) : (
            <Tbody>
              {orders?.map((e, i) => {
                return <OrderList key={i} orderDetails={e} />;
              })}
            </Tbody>
          )}
        </Table>
      </TableContainer>
    </Flex>
  );
};
