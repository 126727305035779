import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Image,
  Text,
  Divider,
} from "@chakra-ui/react";

export const YoutubeVideoResult = ({ video, onPlayVideo }) => {
  // console.log("calling video inside a youtube video result", video);
  return (
    <Card
      key={video.id}
      width={"100%"}
      cursor={"pointer"}
      borderRadius={"12"}
      boxShadow={"md"}
      onClick={() => onPlayVideo(video)}
      height={"300px"}
    >
      <CardHeader p={"4"}>
        <Image
          src={video.thumbnail.url}
          height={"100%"}
          width={"100%"}
          objectFit={"cover"}
          objectPosition={"center"}
          borderRadius={"12"}
        />
      </CardHeader>
      <CardBody
        width={"100%"}
        alignItems={"flex-start"}
        justifyContent={"flex-start"}
        p={"4"}
        pt={"0"}
      >
        <Divider
          width={"70%"}
          borderWidth={"2px"}
          borderColor={"orange"}
          mb={"2"}
        />
        <Text>{video?.title?.split(0, 30)}...</Text>
      </CardBody>
    </Card>
  );
};
