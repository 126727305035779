import { Box, Button, Flex, Select, Text, Textarea } from "@chakra-ui/react"
import React from "react"

export const Help = () => {
  return (
    <Box
      sx={{
        width: "80%",
        px: 20,
        py: 10,
        display: "flex",
        flexDir: "column",
        gap: 5,
        marginLeft:"64"
      }}
    >
      <Text color={"teal"} fontWeight={600} fontSize={24}>
        Support
      </Text>
      <Flex
        flexDir={"column"}
        gap={5}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Select placeholder="Select query type" size="md" />
        <Textarea
          // value={value}
          // onChange={handleInputChange}
          placeholder="Here is a sample placeholder"
          size="sm"
        />
        <Button variant={"solid"} colorScheme="teal" width={"52"}>
          Submit Query
        </Button>
      </Flex>
    </Box>
  )
}
