import React from "react";
import { Heading, Button } from "@chakra-ui/react";

export const getTotalRevenue = (orders) => {
  const totalRevenue = orders?.reduce(
    (total, order) => total + (order?.paymentDetails?.price || 0),
    0
  );
  return parseInt((75 * totalRevenue) / 100);
};

export const Total = ({ orders }) => {
  const totalRevenue = getTotalRevenue(orders);

  return (
    <>
      <Heading color={"teal"}> ₹ {totalRevenue}</Heading>
      <Button variant={"ghost"} color={"teal"}>
        Total Revenue
      </Button>
    </>
  );
};
