import { Box, HStack, Image, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { Logo } from "../../../../Logo";

export const DLabsBanner = () => {
  const STEPS = [
    {
      title: "380+ Pathology Labs In India",
      image: "/assets/landing/stats/1.png",
    },
    {
      title: "2450+ tests booked per day",
      image: "/assets/landing/stats/2.png",
    },
    {
      title: "790+ Pin Codes Served Across India",
      image: "/assets/landing/stats/3.png",
    },
  ];
  return (
    <HStack
      width={"100%"}
      h={"17vh"}
      py={"10px"}
      px={"16px"}
      bg={"teal.400"}
      gap={"40px"}
      justifyContent={"space-between"}
    >
      <Box
        cursor={"pointer"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"flex-start"}
      >
        <Image
          src="/assets/landing/Dilate Labs _ Labs_files/property 1=logo-1.png"
          maxW={"130px"}
          maxH={"130px"}
          bg={"white"}
          borderRadius={"12px"}
          p={"10px"}
        />
      </Box>
      <HStack
        alignItems={"center"}
        justifyContent={"flex-end"}
        px={"10px"}
        color={"#fff"}
        width={"100%"}
      >
        {STEPS.map((s, i) => (
          <HStack
            spacing={3}
            key={i}
            width={"100%"}
            justifyContent={"space-between"}
          >
            <Image src={s.image} maxW={"90px"} maxH={"90px"} />
            <Text
              textAlign={"center"}
              fontSize={["12px", "12px", "12px", "12px", "12px", "14px"]}
              as="h4"
              color={"white"}
            >
              {s.title}
            </Text>
          </HStack>
        ))}
      </HStack>
    </HStack>
  );
};
