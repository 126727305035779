import React, { useEffect, useState } from "react";
import {
  Box,
  Heading,
  Text,
  HStack,
  VStack,
  Button,
  Tag,
  Avatar,
} from "@chakra-ui/react";

import { LabPackages } from "../components/Lab/Packages";
import { LabTests } from "../components/Lab/Tests";
import { IconComponent } from "../components/Landing/IconComponent";
import { ProfileModal } from "../components/Modal/ProfileModal";
import { getLab } from "../cookie/lab";
import { RatingCard } from "../components/Rating";
import { EmailIcon, PhoneIcon, StarIcon } from "@chakra-ui/icons";
import { getReviews } from "../api/review";
import { ImageGallery } from "../components/Lab/ImageGallery";
import { MdLocationOn } from "react-icons/md";
import { DoctorModal } from "../components/Modal/DoctorModal";

export const LabProfile = () => {
  const lab = getLab();
  const [isOpen, setIsOpen] = useState(false);
  const [reviewsData, setReviewsData] = useState([]);
  const [openDoctorModal, setOpenDoctorModal] = useState(false);

  const totalRatings = reviewsData?.length;
  const averageRating =
    totalRatings === 0
      ? 0
      : reviewsData?.reduce((acc, review) => acc + review?.ratingStar, 0) /
        totalRatings;

  const ratingTextMap = {
    1: "Terrible",
    2: "Bad",
    3: "Average",
    4: "Good",
    5: "Excellent",
  };

  const fetchReviews = async () => {
    const res = await getReviews(lab?._id);

    // console.log("calling get reviews res", res);
    setReviewsData(res);
  };

  useEffect(() => {
    fetchReviews();
  }, []);

  return (
    <Box
      sx={{
        width: "full",
        height: "87vh",
        paddingLeft: 0,
        marginLeft: "260px",
        paddingRight: 0,
        py: 10,
        px: 12,
        display: "flex",
        flexDir: "column",
        gap: 1,
        overflowY: "scroll",
        alignItems: "center",
      }}
    >
      <Box
        width={"100%"}
        flexDirection={"column"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"flex-start"}
      >
        <VStack width={"100%"} gap={"5"} alignItems={"flex-start"}>
          <HStack width={"100%"} justifyContent={"space-between"}>
            <VStack gap={"5"} alignItems={"flex-start"} width={"100%"}>
              <HStack>
                <Avatar src="LAB" />
                <Heading as="h1" size="lg">
                  {lab?.labName}
                </Heading>
              </HStack>
              <Box
                display={"flex"}
                gap={"3"}
                justifyContent={"flex-start"}
                alignItems={"center"}
                width={"100%"}
              >
                <Tag backgroundColor={"green.500"} color={"white"} size={"lg"}>
                  {averageRating.toFixed(1)}
                </Tag>
                <HStack gap={"2"}>
                  {[...Array(5)].map((_, index) => (
                    <StarIcon
                      key={index}
                      color={
                        index < Math.floor(averageRating)
                          ? "#ffd700"
                          : "gray.300"
                      }
                    />
                  ))}
                </HStack>
                <Text fontSize="14px" fontWeight={"500"}>
                  {totalRatings === 0
                    ? "No Ratings"
                    : ratingTextMap[Math.floor(averageRating)]}
                </Text>
                <Text fontSize="14px">({totalRatings} Ratings)</Text>
              </Box>
            </VStack>
            <Button colorScheme="teal" onClick={() => setIsOpen(true)}>
              Edit Profile
            </Button>
          </HStack>
          <Text fontSize="sm">{lab?.description}</Text>
          <HStack width={"100%"} justifyContent={"space-between"}>
            <Box
              sx={{
                border: "1px solid rgba(0, 0, 0, 0.2)",
                borderRadius: "20px",
                px: "20px",
                py: "20px",
                display: "flex",
                flexDir: "column",
                gap: "10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "20px",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <IconComponent icon={<PhoneIcon />} text={lab?.phone} />
                <IconComponent icon={<EmailIcon />} text={lab?.email} />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: "20px",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <IconComponent
                  icon={<MdLocationOn />}
                  text={lab?.labAddress?.placeName}
                />
              </Box>
            </Box>
            <Button colorScheme="teal" onClick={() => setOpenDoctorModal(true)}>
              Upload Logo and Signature
            </Button>
          </HStack>
        </VStack>
      </Box>
      <Box width={"100%"}>
        <LabTests />
      </Box>
      <Box width={"100%"}>
        <LabPackages />
      </Box>
      <Box width={"100%"}>
        <ImageGallery />
      </Box>
      <Box width={"100%"}>
        <RatingCard reviews={reviewsData.reverse()} />
      </Box>
      <ProfileModal isOpen={isOpen} onClose={() => setIsOpen(!isOpen)} />
      <DoctorModal
        isOpen={openDoctorModal}
        onClose={() => setOpenDoctorModal(!openDoctorModal)}
      />
    </Box>
  );
};
