import { Button, HStack, Heading, Image, Text, VStack } from "@chakra-ui/react";
import useOwnStore from "../../../store/store";
import { Link, useNavigate } from "react-router-dom";
// import { SearchBar } from "../../SearchBar";

export const TopMain = () => {
  const { lab, setSignin } = useOwnStore();
  const navigate = useNavigate();
  return (
    <HStack
      width={"100vw"}
      alignItems={"center"}
      justifyContent={"space-between"}
      p={[8, 10]}
      bgColor={"teal.400"}
      spacing={0}
      color={"#fff"}
    >
      <VStack
        w={["100%", "35%"]}
        spacing={[8, 10]}
        alignItems={"start"}
        justifyContent={"center"}
        textAlign={"start"}
      >
        <Heading fontWeight={700} fontSize={["40px", "45px"]}>
          Join our Next Generation Labs with DLabs
        </Heading>
        <Text>
          For Your Growth, And Future We Have Transparent Policies and Easy
          Payments, Come On, Join Dlabs
        </Text>
        {/* <SearchBar/> */}
        {lab === null || lab == {} ? (
          <Button
            bg={"white"}
            color={"black"}
            width={["150px", ["sm"]]}
            onClick={() => setSignin(true)}
          >
            Join DLabs
          </Button>
        ) : (
          <Button
            bg={"white"}
            color={"black"}
            width={["150px", ["sm"]]}
            onClick={() => navigate("/dashboard")}
          >
            Go To Dashboard
          </Button>
        )}
      </VStack>
      <Image src="/assets/lab-main.png" display={["none", "flex"]} />
    </HStack>
  );
};
