import { create } from "zustand";

const useOwnStore = create((set) => ({
  currentStep: 1,
  signin: false,
  signup: false,
  dashboardData: {
    revenue: 0,
    totalTests: 0,
    totalPackages: 0,
    totalOrders: 0,
  },
  lab: {},
  setDashboardData: (e) => set((state) => ({ dashboardData: e })),
  setLab: (e) => set((state) => ({ lab: e })),
  setSignin: (e) => set((state) => ({ signin: e })),
  setSignup: (e) => set((state) => ({ signup: e })),
  setCurrentStep: (e) => set((state) => ({ currentStep: e })),
}));

export default useOwnStore;
