import {
  Box,
  Button,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
  Spinner,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { TestList } from "./List";
import axios from "axios";
import { TestModal } from "../../Modal/Test";
import { getLabTests } from "../../../api/test";
import { getLab } from "../../../cookie/lab";

export const Test = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const lab = getLab();

  // const [isMenu, setIsMenu] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER}/getTests`
      );
      const fetchTests = response?.data?.tests;
      // console.log("calling basic test response", response);
      const fetchLabTests = await getLabTests(lab?._id);
      const newData = [...fetchTests, ...fetchLabTests];
      setData(newData?.reverse());
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Box
      sx={{
        width: "full",
        height: "87vh",
        paddingLeft: 0,
        marginLeft: 64,
        paddingRight: 0,
        py: 10,
        display: "flex",
        flexDir: "column",
        gap: 1,
        overflowY: "scroll",
        alignItems: "center",
      }}
    >
      <Button
        variant={"outline"}
        colorScheme="teal"
        width={"64"}
        onClick={() => setIsOpen(true)}
        px={3}
        py={5}
      >
        + Create new test{" "}
      </Button>
      <Flex
        width={"full"}
        justifyContent={"center"}
        py={"8"}
        alignSelf={"center"}
      >
        <TableContainer>
          <Table
            variant="simple"
            width={"73vw"}
            display={"flex"}
            flexDir={"column"}
            justifyItems={"space-between"}
          >
            <Thead bg={"teal.300"}>
              <Tr
                justifyContent={"space-between"}
                width={"100%"}
                display={"flex"}
              >
                <Th>Tests</Th>
                <Th></Th>
                <Th></Th>
                <Th>Price</Th>
                <Th isNumeric>Date Added</Th>
                <Th>Disable</Th>
              </Tr>
            </Thead>
            {loading ? (
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  height: "60vh",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Spinner size={"lg"} />
              </Box>
            ) : (
              <Tbody>
                {data?.map((e, i) => {
                  return <TestList key={i} data={e} fetchData={fetchData} />;
                })}
              </Tbody>
            )}
          </Table>
        </TableContainer>
      </Flex>
      <TestModal
        isOpen={isOpen}
        data={data}
        setData={setData}
        setIsOpen={setIsOpen}
      />
    </Box>
  );
};
