import {
  Badge,
  Box,
  Button,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Td,
  Text,
  Tr,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { UploadParametersModal } from "../Report/uploadResults";
import useOwnStore from "../../../../store/store";
import { useNavigate } from "react-router-dom";
import { SRAModal } from "../../../Modal/SRAModal";

export const OrderList = ({ key, orderDetails }) => {
  const [order, setOrder] = useState("");
  const [isReport, setIsReport] = useState(false);
  const [loading, setLoading] = useState(false);
  const [SRA, setSRA] = useState(false);

  const [checkoutSuccess, setCheckoutSuccess] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();

  const { lab, setLab } = useOwnStore();

  const currentDate = new Date()
    .toLocaleDateString("en-IN", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    })
    .replace(/\//g, "-");

  const [transaction, setTransaction] = useState({
    transactionName: "Pay at lab order deduction",
    amount: order?.paymentDetails?.price,
    date: currentDate,
    paymentMethod: "card",
    referenceId: "",
  });

  // console.log("calling total order price", transaction?.amount);

  const razorpayCheckoutHandler = async () => {
    setLoading(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER}/checkoutOrder`,
        { totalOrderPrice: orderDetails?.paymentDetails?.price }
      );

      const orderId = res.data.razorpayOrder.id;

      // console.log("calling checkout order res", res);

      const options = {
        key: process.env.REACT_APP_RAZORPAY_API_KEY,
        amount: transaction?.amount,
        currency: "INR",
        name: lab?.labName,
        description: `This is amount deduction for the pay at lab order : ${lab?.labName}`,
        image: "/assets/landing/steps/3.png",
        order_id: orderId,
        handler: async (response) => verificationHandler(response),
        prefill: {
          name: lab?.name,
          email: lab?.email ? lab.email : "xyz@gmail.com",
          contact: lab?.phone,
        },
        notes: {
          address: lab?.labAddress?.placeName,
        },
        theme: {
          color: "#0c727e",
        },
      };

      const razor = new window.Razorpay(options);
      razor.open();
    } catch (error) {
      console.error("error while creating checkout", error);
    } finally {
      setLoading(false);
    }
  };

  const verificationHandler = async (response) => {
    // console.log(
    //   "calling payment verification response before api request",
    //   response
    // );
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER}/paymentverification`,
        { response }
      );
      // console.log("calling verification res inside handler", res);
      setTransaction({
        ...transaction,
        referenceId: res?.data?.razorpayOrder?.razorpay_payment_id,
      });
      setCheckoutSuccess(true);
    } catch (error) {
      console.error("error in the verification request");
    }
  };

  const handleCancelled = async () => {
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_SERVER}/labs/orders/cancel/${order._id}`
      );

      setOrder(res?.data?.order);
      toast({
        title: "order cancelled successfully",
        duration: 9000,
        status: "success",
        isClosable: true,
      });
    } catch (error) {
      console.error("Error cancelling order:", error);
      toast({
        title: "error in cancelling the order",
        duration: 9000,
        status: "error",
        isClosable: true,
      });
    }
  };

  const handleVerifyLOP = async () => {
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_SERVER}/labs/orders/verify`,
        { transaction, orderId: order?._id }
      );

      // console.log("calling LOP verify order response", res);

      if (res.status === 200) {
        setOrder(res?.data?.orderData?.order);
        localStorage.setItem(
          "lab",
          JSON.stringify(res?.data?.orderData?.updatedLab)
        );
        setLab(res?.data?.orderData?.updatedLab);

        toast({
          title: res?.data?.message,
          duration: 9000,
          status: "success",
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error in verifying the order:", error);
      toast({
        title: "error in verifying the order",
        duration: 9000,
        status: "error",
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (checkoutSuccess) {
      handleVerifyLOP();
      // console.log("checkout called");
    }
  }, [checkoutSuccess]);

  useEffect(() => {
    setOrder(orderDetails);
    setTransaction({
      ...transaction,
      amount: orderDetails?.paymentDetails?.price,
    });
  }, []);

  return (
    <Tr
      justifyContent={"space-between"}
      width={"100%"}
      display={"flex"}
      alignItems={"center"}
      transition={"ease-in 0.2s"}
      cursor={"default"}
      _hover={{
        bg: "gray.100",
      }}
      key={key}
    >
      <Td
        sx={{
          width: "30%",
          display: "flex",
          flexDir: "column",
        }}
      >
        <Flex gap={3}>
          <Box>
            <Text color={"#2A2B2C"}>{order?.name}</Text>
          </Box>
        </Flex>
      </Td>
      <Td>₹ {order?.paymentDetails?.price}</Td>
      <Td isNumeric>{order?.createdAt?.split("T")[0]}</Td>
      <Td>
        <Badge
          colorScheme={
            order?.orderStatus === "pending"
              ? "twitter"
              : order?.orderStatus === "completed"
              ? "green"
              : order.orderStatus === "LOP"
              ? "facebook"
              : order.orderStatus === "SRA"
              ? "purple"
              : "red"
          }
        >
          {order?.orderStatus === "SRA"
            ? "SRA Verified"
            : order?.orderStatus === "LOP"
            ? "LOP Verified"
            : order?.orderStatus}
        </Badge>
      </Td>
      <Td>
        <Menu>
          {({ isOpen }) => (
            <>
              <MenuButton>
                <IconButton
                  isActive={isOpen}
                  as={Button}
                  borderRadius={"50%"}
                  isDisabled={order.orderStatus !== "pending"}
                >
                  <BsThreeDotsVertical />
                </IconButton>
              </MenuButton>
              <MenuList>
                <MenuItem
                  color={"purple"}
                  sx={{ _hover: { backgroundColor: "whiteAlpha" } }}
                  onClick={() => setSRA(true)}
                  isDisabled={order?.orderStatus !== "pending"}
                >
                  Verify SRA
                </MenuItem>
                {lab?.wallet?.balance > 1000 ? (
                  <MenuItem
                    color={"facebook"}
                    sx={{ _hover: { backgroundColor: "whiteAlpha" } }}
                    onClick={razorpayCheckoutHandler}
                    isDisabled={
                      order?.orderStatus !== "SRA" ||
                      order?.paymentDetails?.mode !== "labpay"
                    }
                  >
                    Verify LOP
                  </MenuItem>
                ) : (
                  <MenuItem
                    color={"facebook"}
                    sx={{ _hover: { backgroundColor: "whiteAlpha" } }}
                    onClick={() => navigate("/mywallet")}
                  >
                    Please Recharge Your Wallet!
                  </MenuItem>
                )}
                <MenuItem
                  color={"green"}
                  sx={{ _hover: { backgroundColor: "whiteAlpha" } }}
                  onClick={() => setIsReport(true)}
                  isDisabled={
                    (order?.orderStatus !== "SRA"
                      ? order?.orderStatus !== "LOP"
                      : false) ||
                    (order?.paymentDetails?.mode === "labpay" &&
                      order?.orderStatus !== "LOP") ||
                    (order?.orderStatus === "cancelled" &&
                      order?.orderStatus === "completed")
                  }
                >
                  Complete
                </MenuItem>
                <MenuItem
                  color={"red"}
                  sx={{ _hover: { backgroundColor: "whiteAlpha" } }}
                  onClick={handleCancelled}
                  isDisabled={
                    order?.orderStatus !== "SRA" ||
                    order?.orderStatus !== "pending"
                  }
                >
                  Cancel
                </MenuItem>
              </MenuList>
            </>
          )}
        </Menu>
      </Td>
      <UploadParametersModal
        open={isReport}
        setOpen={setIsReport}
        order={orderDetails}
        setOrder={setOrder}
      />
      <SRAModal
        open={SRA}
        onClose={() => setSRA(!SRA)}
        order={orderDetails}
        setOrder={setOrder}
      />
    </Tr>
  );
};
