import {
  Avatar,
  Button,
  Box,
  Flex,
  Td,
  Text,
  Tr,
  Th,
  Tbody,
  Table,
  TableContainer,
  Thead,
  HStack,
} from "@chakra-ui/react";
import { useDisclosure } from "@chakra-ui/react";
import { Badge } from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { getCustomerDetails } from "../../../../api/customer";

export const ListElement = ({ key, orderDetails, orders }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [customer, setCustomer] = useState("");
  const [loading, setLoading] = useState(false);

  // console.log("calling orderDetails in the customer list", orderDetails);
  const customerOrders = orders.filter(
    (order) =>
      order?.consumerDetails?.user?.phone ===
      orderDetails?.consumerDetails?.user?.phone
  );

  // console.log('calling customer orders', customerOrders)

  // Check if the user has a phone number
  const hasPhoneNumber = orderDetails?.consumerDetails?.user?.phone;

  const fetchCustomer = async () => {
    try {
      setLoading(true);
      const res = await getCustomerDetails(
        orderDetails?.consumerDetails?.user?._id
      );
      setCustomer(res);
    } catch (error) {
      console.error("error while loading customer details", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCustomer();
  }, []);

  return (
    <>
      {hasPhoneNumber && (
        <Tr
          paddingX={"0"}
          justifyContent={"space-between"}
          width={"100%"}
          display={"flex"}
          gap={"0"}
          alignItems={"center"}
          transition={"ease-in 0.2s"}
          cursor={"default"}
          _hover={{
            bg: "gray.100",
          }}
          key={key}
        >
          <Td paddingX={"0"}>
            <Flex gap={3}>
              <Box>
                <Text color={"#2A2B2C"} fontWeight={500}>
                  <Button onClick={onOpen}>
                    <HStack width={"100%"} gap={"3"}>
                      {customer?.fileUrl ? (
                        <Avatar
                          src={customer?.fileUrl}
                          size={"sm"}
                          loading={loading}
                        />
                      ) : (
                        <Avatar size={"sm"} />
                      )}
                      <Text>{orderDetails?.consumerDetails?.user?.phone}</Text>
                    </HStack>
                  </Button>

                  <Modal isOpen={isOpen} onClose={onClose} size={"4xl"}>
                    <ModalOverlay />
                    <ModalContent>
                      <ModalHeader>Patient Details </ModalHeader>
                      <ModalCloseButton />
                      <ModalBody>
                        <Box>
                          <Box display={"flex"}>
                            {customer?.fileUrl ? (
                              <Avatar
                                src={customer?.fileUrl}
                                size={"sm"}
                                loading={loading}
                              />
                            ) : (
                              <Avatar size={"sm"} />
                            )}
                            <Text
                              marginLeft={"10px"}
                              fontSize={"20px"}
                              fontWeight={"700"}
                            >
                              {customer?.phone}
                            </Text>
                          </Box>
                          <Box>
                            <Text
                              fontSize={"15px"}
                              fontWeight={"600"}
                              marginLeft={"50px"}
                            >
                              {customer?.mainAddress}
                            </Text>
                          </Box>
                          <Box>
                            <TableContainer
                              width={"100%"}
                              height={"100%"}
                              overflowY={"scroll"}
                            >
                              <Table variant="simple" overflowY={"scroll"}>
                                <Thead>
                                  <Tr>
                                    <Th>Order Name</Th>
                                    <Th>Date</Th>
                                    <Th>Status</Th>
                                    <Th isNumeric>Price</Th>
                                  </Tr>
                                </Thead>
                                <Tbody overflowY={"scroll"}>
                                  {customerOrders?.map((orderData) => {
                                    return (
                                      <>
                                        <Tr>
                                          <Td textAlign={"left"}>
                                            {orderData?.name}
                                            {/* {console.log("names - " + orderData.name)} */}
                                          </Td>
                                          <Td textAlign={"left"}>
                                            {
                                              orderData?.createdAt?.split(
                                                "T"
                                              )[0]
                                            }
                                          </Td>
                                          <Td textAlign={"left"}>
                                            <Badge
                                              colorScheme={
                                                orderData?.orderStatus ===
                                                "pending"
                                                  ? "blue"
                                                  : orderData?.orderStatus ===
                                                    "completed"
                                                  ? "green"
                                                  : orderData.orderStatus ===
                                                    "verified"
                                                  ? "purple"
                                                  : "red"
                                              }
                                            >
                                              {orderData?.orderStatus}
                                            </Badge>
                                          </Td>
                                          <Td textAlign={"right"}>
                                            {orderData?.paymentDetails?.price}
                                          </Td>
                                        </Tr>
                                      </>
                                    );
                                  })}
                                </Tbody>
                              </Table>
                            </TableContainer>
                          </Box>
                        </Box>
                      </ModalBody>
                      <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={onClose}>
                          Close
                        </Button>
                      </ModalFooter>
                    </ModalContent>
                  </Modal>
                </Text>
              </Box>
            </Flex>
          </Td>
          <Td marginLeft={12}>{orderDetails?.paymentDetails?.price}</Td>
          <Td
            paddingLeft={"14"}
            textAlign={"center"}
            isNumeric
            sx={{
              color: "#2A2B2c",
            }}
          >
            {orderDetails?.createdAt?.split("T")[0]}
          </Td>
          <Td marginLeft={10}>
            <Badge
              variant={"subtle"}
              colorScheme={`${
                orderDetails?.sampleMode === "home" ? "blue" : "red"
              }`}
            >
              <Text textAlign={"right"}>
                {orderDetails?.sampleMode === "home" ? "online" : "Walk In"}
              </Text>
            </Badge>
          </Td>
          <Td padding={0}>
            <Button colorScheme="teal" isDisabled={!orderDetails?.reportUrl}>
              <a
                href={orderDetails?.reportUrl}
                download={true}
                rel="noreferrer"
                target="_blank"
              >
                Download
              </a>
            </Button>
          </Td>
        </Tr>
      )}
    </>
  );
};
