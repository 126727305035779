import axios from "axios";

export const createPackage = async (packageData) => {
  const res = await axios.post(
    `${process.env.REACT_APP_SERVER}/createLabPackage`,
    packageData
  );

  return res.data;
};
export const getLabPackages = async (desiredLabId) => {
  const res = await axios.get(`${process.env.REACT_APP_SERVER}/getLabPackages`);
  // console.log("calling get labpackage res in api", res);
  const filteredLabPackages = res?.data?.packages?.filter(
    (labPackage) => labPackage.labId === desiredLabId
  );
  // console.log(
  //   "calling filtered package inside package api ",
  //   filteredLabPackages
  // );
  return filteredLabPackages;
};

export const getPackages = async ({ keyword, id }) => {
  const res = await axios.get(`${process.env.REACT_APP_SERVER}/getPackages`, {
    params: {
      keyword,
      id,
    },
  });
  return res.data;
};
export const deletePackage = async (id) => {
  try {
    // console.log("calling package id in delte api");
    const res = await axios.delete(
      `${process.env.REACT_APP_SERVER}/deletePackage/${id}`
    );

    // console.log("Deleted package -> ", res);

    return res.data;
  } catch (error) {
    console.error("Error deleting package:", error);
    throw error; // Re-throw the error for the calling function to handle
  }
};
export const DisabledPackage = async ({ labId, packageId }) => {
  const res = await axios.post(
    `${process.env.REACT_APP_SERVER}/disabledPackage`,
    {
      labId,
      packageId,
    }
  );
  return res.data.message;
};
