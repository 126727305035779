import { Box, Image } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

export const Logo = (props) => {
	const navigate = useNavigate();
	return (
		<Box cursor={"pointer"} onClick={() => navigate("/")} {...props}>
			<Image src="/assets/logo.svg" width={100} />
		</Box>
	);
};
