import React from "react";
import { Heading, Button } from "@chakra-ui/react";

export const getTotalPriceForLastFullYear = (orders) => {
  const currentDate = new Date();
  const oneYearAgo = new Date(
    currentDate.getTime() - 12 * 30 * 24 * 60 * 60 * 1000
  );

  const lastFullYearOrders = orders.filter(
    (order) =>
      order.createdAt &&
      new Date(order.createdAt) >= oneYearAgo &&
      new Date(order.createdAt) <= currentDate
  );

  const totalPriceForLastFullYear = lastFullYearOrders.reduce(
    (total, order) => total + (order.paymentDetails?.price || 0),
    0
  );

  return parseInt((75 * totalPriceForLastFullYear) / 100);
};

export const FullYear = ({ orders }) => {
  const totalPriceForFullYear = getTotalPriceForLastFullYear(orders);

  return (
    <>
      <Heading color={"teal"}> ₹ {totalPriceForFullYear}</Heading>
      <Button variant={"ghost"} color={"teal"}>
        Last 1 Year
      </Button>
    </>
  );
};
