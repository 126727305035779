import { PhoneIcon } from "@chakra-ui/icons";
import { Flex, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { FormizStep } from "@formiz/core";
import React from "react";
import { TextField } from "../../../Inputs/TextField";
import { isMaxNumber, isMinNumber } from "@formiz/validations";

export const Step1 = ({ form }) => {
  return (
    <>
      <FormizStep
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: 2,
          justifyContent: "center",
          alignItems: "center",
        }}
        label="Step 1"
        name="step1"
      >
        <Flex flexDirection={"column"} gap={3} width={"100%"}>
          <TextField
            disabled={false}
            id={"text"}
            name={"name"}
            type={"text"}
            placeholder="Full Name"
          />
          <TextField
            disabled={false}
            id={"text"}
            name={"phone"}
            type={"text"}
            placeholder="Mobile Number"
          />
          <TextField
            disabled={false}
            id={"text"}
            name={"name"}
            type={"text"}
            placeholder="Email"
          />
          <Flex gap={5}>
            <TextField
              disabled={false}
              id={"text"}
              name={"gender"}
              type={"select"}
              placeholder="Gender"
            />
            <TextField
              disabled={false}
              id={"text"}
              name={"name"}
              type={"date"}
              placeholder="Email"
            />
          </Flex>
        </Flex>
      </FormizStep>
    </>
  );
};
