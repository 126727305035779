import {HStack, Image, Text, VStack } from "@chakra-ui/react";

const STEPS = [
  {
    title: "380+ Pathology Labs In India",
    image: "/assets/landing/stats/1.png",
  },
  {
    title: "2450+ tests booked per day",
    image: "/assets/landing/stats/2.png",
  },
  {
    title: "790+ Pin Codes Served Across India",
    image: "/assets/landing/stats/3.png",
  },
];

export const LandingStats = () => {
  return (
    <VStack display={["none", "flex"]} width={"100vw"} bgColor={"teal.400"}>
      <HStack
        spacing={20}
        alignItems={"center"}
        justifyContent={"space-between"}
        p={10}
        px={20}
        flexDir={["column", "row"]}
        color={"#fff"}
      >
        {STEPS.map((s, i) => (
          <VStack spacing={3} key={i}>
            <Image src={s.image} width={150} height={150} />
            <Text textAlign={"center"} fontSize={23} as="h4">
              {s.title}
            </Text>
          </VStack>
        ))}
      </HStack>
    </VStack>
  );
};
