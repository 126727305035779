import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { HStack } from "@chakra-ui/react";
import { PackageCard } from "../PackageCard";
import "../../../../App.css";

export const PackageSlider = ({ slides }) => {
  const groupedSlides = [];
  for (let i = 0; i < slides.length; i += 2) {
    if (i + 1 < slides.length) {
      groupedSlides.push([slides[i], slides[i + 1]]);
    } else {
      groupedSlides.push([slides[i]]);
    }
  }

  return (
    <Carousel
      infiniteLoop
      showIndicators={true}
      showArrows={true}
      showStatus={false}
      showThumbs={false}
      emulateTouch={true}
      swipeable={true}
      className="carousel"
    >
      {groupedSlides.map((slide, index) => (
        <HStack
          key={index}
          width={"100%"}
          justifyContent={"center"}
          paddingTop={"10"}
          paddingBottom={"20"}
        >
          {slide.map((item, idx) => (
            <PackageCard key={idx} packages={item} />
          ))}
        </HStack>
      ))}
    </Carousel>
  );
};
