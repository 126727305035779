import React from "react";
import { Button, Text, Input, Stack, Flex, Textarea } from "@chakra-ui/react";
import useOwnStore from "../../../store/store";

export const RegistrationStep1 = ({
  activeStep,
  setActiveStep,
  onChangeHandler,
}) => {
  const { signup, signin, setSignin, setSignup, lab } = useOwnStore();
  // console.log("calling signup state", signup, signin);

  return (
    <>
      <Flex gap={3} width={"100%"}>
        <Input
          type="text"
          placeholder="Lab Name"
          name="labName"
          value={lab?.labName}
          onChange={(e) => onChangeHandler(e)}
          focusBorderColor="teal.500"
        />
        <Input
          type="text"
          placeholder="Owner Name"
          name="name"
          value={lab?.name}
          onChange={(e) => onChangeHandler(e)}
          focusBorderColor="teal.500"
        />
      </Flex>
      <Flex gap={3} width={"100%"}>
        <Input
          type="email"
          placeholder="Email"
          name="email"
          value={lab?.email}
          onChange={(e) => onChangeHandler(e)}
          focusBorderColor="teal.500"
        />
        <Input
          type="phone"
          placeholder="Phone Number"
          name="phone"
          value={lab?.phone}
          onChange={(e) => onChangeHandler(e)}
          focusBorderColor="teal.500"
        />
      </Flex>

      <Textarea
        name="description"
        value={lab?.description}
        onChange={(e) => onChangeHandler(e)}
        placeholder="Lab Description"
        focusBorderColor="teal.500"
      />

      <Stack spacing={3} width={"100%"}>
        <Button
          bg={"#0C727E"}
          color={"white"}
          fontWeight={400}
          width={"100%"}
          onClick={() => setActiveStep(activeStep + 1)}
        >
          Next
        </Button>
        <Text display={"flex"} fontSize={12} fontWeight={500} gap={1}>
          Already have an account?{"  "}
          <Text
            color={"#0C727E"}
            _hover={{ cursor: "pointer", textDecor: "underline" }}
            onClick={() => {
              setSignin(true);
              setSignup(false);
            }}
          >
            Sign In
          </Text>
        </Text>
      </Stack>
    </>
  );
};
