import {
  Box,
  Flex,
  InputGroup,
  InputLeftAddon,
  VStack,
} from "@chakra-ui/react";
import { FormizStep } from "@formiz/core";
import React from "react";
import { TextField } from "../../../Inputs/TextField";

export const Step3 = () => {
  return (
    <>
      <FormizStep
        as={"Box"}
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: 2,
          justifyContent: "center",
          alignItems: "center",
        }}
        component="form"
        label="Step 3"
        name="step3"
      >
        <VStack width={"120%"} gap={3}>
          <TextField
            disabled={false}
            name={"Packages"}
            type={"select"}
            placeholder="Select Packages*"
          />
          <TextField
            disabled={false}
            name={"Packages"}
            type={"select"}
            placeholder="Type Of Packages*"
          />
          <TextField
            disabled={false}
            name={"tests"}
            type={"select"}
            placeholder="Select Tests*"
          />
          <InputGroup>
            <InputLeftAddon children="₹" />
            <TextField
              disabled={false}
              name={"price"}
              type={"number"}
              placeholder="Automatically Price Display"
            />
          </InputGroup>
        </VStack>
      </FormizStep>
    </>
  );
};
