import React from "react";
import {
  Button,
  Text,
  Input,
  Stack,
  Flex,
  Textarea,
  Select,
} from "@chakra-ui/react";
import useOwnStore from "../../../store/store";
import { statesList } from "../../Lab/States";

export const RegistrationStep2 = ({
  activeStep,
  setActiveStep,
  onChangeHandler,
}) => {
  const { signup, signin, setSignin, setSignup, lab } = useOwnStore();
  // console.log("calling signup state", signup, signin);

  return (
    <>
      <Flex gap={3} width={"100%"}>
        <Select
          placeholder="Country"
          name="country"
          value={lab?.country}
          onChange={(e) => onChangeHandler(e)}
          focusBorderColor="teal.500"
        >
          <option value={"India"}>India</option>
          <option value={"Other"}>Other</option>
        </Select>
        <Select
          placeholder="State"
          name="state"
          value={lab?.state}
          onChange={(e) => onChangeHandler(e)}
          focusBorderColor="teal.500"
        >
          {statesList.map((state, index) => (
            <option key={index} value={state}>
              {state}
            </option>
          ))}
        </Select>
      </Flex>
      <Flex gap={3} width={"100%"}>
        <Input
          type="text"
          placeholder="District"
          name="district"
          value={lab?.district}
          onChange={(e) => onChangeHandler(e)}
          focusBorderColor="teal.500"
        />
        <Input
          type="text"
          placeholder="City"
          name="city"
          value={lab?.city}
          onChange={(e) => onChangeHandler(e)}
          focusBorderColor="teal.500"
        />
      </Flex>

      <Textarea
        name="labAddress"
        value={lab?.labAddress?.placeName}
        onChange={(e) => onChangeHandler(e)}
        placeholder="Lab Address"
        focusBorderColor="teal.500"
      />
      <Stack spacing={3} width={"100%"}>
        <Button
          bg={"#0C727E"}
          color={"white"}
          fontWeight={400}
          width={"100%"}
          onClick={() => setActiveStep(activeStep + 1)}
        >
          Next
        </Button>
        <Text display={"flex"} fontSize={12} fontWeight={500} gap={1}>
          Already have an account?
          <Text
            color={"#0C727E"}
            _hover={{ cursor: "pointer", textDecor: "underline" }}
            onClick={() => {
              setSignin(true);
              setSignup(false);
            }}
          >
            Sign In
          </Text>
        </Text>
      </Stack>
    </>
  );
};
