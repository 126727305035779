import React from "react";
import { Text } from "@chakra-ui/react";

export const YoutubeVideoPlayer = ({ video }) => {
  // console.log("calling video inside videoplayer", video);
  if (!video) {
    return <Text>No video to watch</Text>;
  }

  return (
    <iframe
      title={video?.title}
      width="900px"
      height={"500px"}
      src={`https://www.youtube.com/embed/${video.id}`}
      frameBorder="0"
      allowFullScreen
    ></iframe>
  );
};
