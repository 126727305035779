// import React from "react";
// import {
//   Box,
//   Button,
//   Card,
//   CardBody,
//   Divider,
//   Flex,
//   Heading,
//   Select,
//   Stack,
//   Text,
// } from "@chakra-ui/react";
// import { Image } from "@chakra-ui/react";

// export const Inventory = () => {
//   return (
//     <Box
//       sx={{
//         width: "80%",
//         px: 10,
//         py: 10,
//         display: "flex",
//         flexDir: "column",
//         alignItems: "center",
//         gap: 1,
//         marginLeft: "64",
//       }}
//     >
//       <Button variant={"outline"} colorScheme="teal" width={"64"}>
//         + Add new item
//       </Button>
//       <Flex width={"full"} justifyContent={"center"} py={"8"}>
//         <Flex
//           justifyContent={"space-evenly"}
//           alignItems={"center"}
//           width={"100%"}
//         >
//           <Text fontSize={"3xl"}>Pathalogy lab equipment</Text>
//           <Text>
//             <Select placeholder="large size" size="lg" />
//           </Text>
//         </Flex>
//       </Flex>
//       <Box
//         sx={{
//           display: "flex",
//           gap: 5,
//         }}
//       >
//         <Card maxW="xs">
//           <CardBody>
//             <Image
//               src="https://images.unsplash.com/photo-1555041469-a586c61ea9bc?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80"
//               alt="Green double couch with wooden legs"
//               borderRadius="2xl"
//             />
//             <Stack mt="6" spacing="3">
//               <Heading size="md">Borosilicate Test Tube</Heading>
//               <Text>15*125mm set Of 12pcs</Text>
//               <Divider />
//               <Box
//                 sx={{
//                   display: "flex",
//                   justifyContent: "space-between",
//                   alignItems: "center",
//                 }}
//               >
//                 <Text fontWeight="bold" fontSize="md" color={"#1DC2D5"}>
//                   $ 987.55
//                 </Text>
//                 <Text fontWeight="bold" fontSize="md" color={"gray.400"}>
//                   MRP 1048.55
//                 </Text>
//               </Box>
//             </Stack>
//           </CardBody>
//         </Card>
//         <Card maxW="xs">
//           <CardBody>
//             <Image
//               src="https://images.unsplash.com/photo-1555041469-a586c61ea9bc?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80"
//               alt="Green double couch with wooden legs"
//               borderRadius="2xl"
//             />
//             <Stack mt="6" spacing="3">
//               <Heading size="md">Borosilicate Test Tube</Heading>
//               <Text>15*125mm set Of 12pcs</Text>
//               <Divider />
//               <Box
//                 sx={{
//                   display: "flex",
//                   justifyContent: "space-between",
//                   alignItems: "center",
//                 }}
//               >
//                 <Text fontWeight="bold" fontSize="md" color={"#1DC2D5"}>
//                   $ 987.55
//                 </Text>
//                 <Text fontWeight="bold" fontSize="md" color={"gray.400"}>
//                   MRP 1048.55
//                 </Text>
//               </Box>
//             </Stack>
//           </CardBody>
//         </Card>
//       </Box>
//     </Box>
//   );
// };

import React from "react";
import { Box, Heading, Text, Button, Flex, Menu } from "@chakra-ui/react";
import Lottie from "react-lottie";
import animationData from "../../../assets/about/lab_search/inventory animation.json";
import { useNavigate } from "react-router-dom";

export const Inventory = () => {
  const navigate = useNavigate();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Box
      sx={{
        width: "full",
        height: "87vh",
        paddingLeft: 0,
        marginLeft: 64,
        paddingRight: 0,
        py: 10,
        display: "flex",
        justifyContent: "space-around",
        flexDir: "column",
        overflowY: "scroll",
        alignItems: "center",
      }}
    >
      <Heading
        display="inline-block"
        as="h2"
        size="xl"
        bgGradient="linear(to-r, teal.400, teal.600)"
        backgroundClip="text"
      >
        Inventory Coming Soon
      </Heading>
      <Lottie options={defaultOptions} width={"100"} />

      <Button
        onClick={() => navigate("/")}
        colorScheme="teal"
        bgGradient="linear(to-r, teal.400, teal.500, teal.600)"
        color="white"
        variant="solid"
        size={"lg"}
      >
        Go to Home
      </Button>
    </Box>
  );
};
