import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  VStack,
  Input,
  Textarea,
  Checkbox,
  Box,
  useToast,
  HStack,
  Heading,
  Image,
  Stack,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import { getLabTests } from "../../../api/test";
import { createPackage } from "../../../api/package";
import { MdDescription } from "react-icons/md";
import { getLab } from "../../../cookie/lab";

const PackageModal = ({ isOpen, onClose, data, setData }) => {
  const lab = getLab();
  const labId = lab?._id;
  // console.log("calling lab id", lab._id);

  const [packageData, setPackageData] = useState({
    name: "",
    description: "",
    price: "",
    tests: [],
    labId,
  });

  // console.log("calling package data before creating", packageData);
  const [availableTests, setAvailableTests] = useState([]);
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPackageData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleTestChange = (testId, isChecked) => {
    setPackageData((prevData) => ({
      ...prevData,
      tests: isChecked
        ? [...prevData.tests, testId]
        : prevData.tests.filter((test) => test !== testId),
    }));
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      // console.log("calling package data before creating", packageData);
      const res = await createPackage(packageData);
      // console.log("calling create package res in api", res);
      setData([res.createdPackage, ...data]);
      onClose();

      toast({
        title: "package created successfully",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error creating package:", error.message);
      toast({
        title: "Error creating package",
        description: error.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchTests = async () => {
      try {
        const res = await getLabTests(labId);
        setAvailableTests(res);
        // console.log("calling lab test ", res);
      } catch (error) {
        console.error("Error fetching tests:", error.message);
        toast({
          title: "Error fetching tests",
          description: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    };

    fetchTests();
  }, [labId, toast]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"4xl"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create New Health Package</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <HStack alignItems={"flex-start"} spacing={5}>
            <VStack flex={1} p={5} display={["none", "flex"]}>
              <Image src={"/assets/signup/Signup.png"} width={"8xl"} />
            </VStack>
            <VStack flex={1} p={[1, 5]} spacing={7}>
              <Heading>Create New Health Package</Heading>
              <Stack
                sx={{
                  display: "flex",
                  flexDir: "column",
                  gap: "3",
                  width: "100%",
                }}
              >
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    color="gray.300"
                    fontSize="1.2em"
                  >
                    <MdDescription />
                  </InputLeftElement>
                  <Input
                    placeholder="Package Name"
                    name="name"
                    value={packageData?.name}
                    onChange={handleChange}
                  />
                </InputGroup>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    color="gray.300"
                    fontSize="1.2em"
                  >
                    ₹
                  </InputLeftElement>
                  <Input
                    placeholder="Price"
                    type="number"
                    name="price"
                    value={packageData?.price}
                    onChange={handleChange}
                  />
                </InputGroup>

                <Textarea
                  placeholder="Package Description"
                  name="description"
                  value={packageData?.description}
                  onChange={handleChange}
                />
              </Stack>

              <Box gap={"2"}>
                <h3>Available Tests:</h3>
                {availableTests?.map((test) => (
                  <Checkbox
                    margin={2}
                    key={test?._id}
                    isChecked={packageData?.tests?.includes(test?._id)}
                    onChange={(e) =>
                      handleTestChange(test?._id, e.target.checked)
                    }
                  >
                    {test?.name}
                  </Checkbox>
                ))}
              </Box>
              <Button
                width={"full"}
                isLoading={loading}
                variant={"solid"}
                colorScheme="teal"
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </VStack>
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PackageModal;
