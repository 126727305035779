import {
  Box,
  Button,
  HStack,
  Heading,
  Icon,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Logo } from "../Logo";
import {
  AiOutlineInstagram,
  AiOutlineTwitter,
  AiFillFacebook,
  AiFillLinkedin,
} from "react-icons/ai";

export const Footer = () => {
  return (
    <VStack spacing={4} p={[5, 10]} display={["flex"]} width={"full"}>
      <HStack
        alignItems={"flex-start"}
        width={"100%"}
        justifyContent={"space-evenly"}
        spacing={5}
      >
        <HStack
          alignItems={"flex-start"}
          width={"100%"}
          justifyContent={"space-evenly"}
          flexDir={["column", "row"]}
          spacing={[8, 0]}
        >
          <VStack alignItems={"flex-start"} spacing={"4"}>
            <Logo />
            <Heading
              fontWeight={600}
              pb={3}
              fontSize={20}
              _hover={{
                cursor: "default",
              }}
            >
              Follow us
            </Heading>
            <HStack gap={2}>
              <Icon
                fontSize={30}
                as={AiOutlineTwitter}
                _hover={{
                  color: "#00ACEE",
                  cursor: "pointer",
                }}
              />
              <Icon
                fontSize={30}
                as={AiFillFacebook}
                _hover={{
                  color: "#3b5998 ",
                  cursor: "pointer",
                }}
              />
              <Icon
                fontSize={30}
                as={AiFillLinkedin}
                _hover={{
                  color: "#0A66C2",
                  cursor: "pointer",
                }}
              />
              <Icon
                fontSize={30}
                as={AiOutlineInstagram}
                _hover={{
                  color: "#fd1d1d",
                  cursor: "pointer",
                }}
              />
            </HStack>
          </VStack>
          <VStack alignItems={"flex-start"} fontSize={15}>
            <Heading
              pb={5}
              fontWeight={600}
              fontSize={18}
              _hover={{
                cursor: "default",
              }}
            >
              Patients
            </Heading>
            <Text
              _hover={{
                cursor: "pointer",
                textDecoration: "underline",
              }}
            >
              Upload prescription
            </Text>
            <Text
              _hover={{
                cursor: "pointer",
                textDecoration: "underline",
              }}
            >
              Download report
            </Text>
            <Text
              _hover={{
                cursor: "pointer",
                textDecoration: "underline",
              }}
            >
              Explore all blood test
            </Text>
            <Text
              _hover={{
                cursor: "pointer",
                textDecoration: "underline",
              }}
            >
              Explore all blood test
            </Text>
            <Text
              _hover={{
                cursor: "pointer",
                textDecoration: "underline",
              }}
            >
              Feedback
            </Text>
          </VStack>
          <VStack alignItems={"flex-start"} fontSize={15}>
            <Heading
              pb={5}
              fontWeight={600}
              fontSize={18}
              _hover={{
                cursor: "default",
              }}
            >
              Laboratories
            </Heading>
            <Text
              _hover={{
                cursor: "pointer",
                textDecoration: "underline",
              }}
            >
              Our lab
            </Text>

            <Text
              _hover={{
                cursor: "pointer",
                textDecoration: "underline",
              }}
            >
              Visit department
            </Text>
            <Text
              _hover={{
                cursor: "pointer",
                textDecoration: "underline",
              }}
            >
              List Your Lab
            </Text>
          </VStack>
        </HStack>
        <HStack
          alignItems={"flex-start"}
          width={"100%"}
          spacing={10}
          justifyContent={"space-evenly"}
          flexDir={["column", "row"]}
        >
          <VStack alignItems={"flex-start"} fontSize={15}>
            <Heading
              pb={5}
              fontWeight={600}
              fontSize={18}
              _hover={{
                cursor: "default",
              }}
            >
              About us
            </Heading>
            <Text
              _hover={{
                cursor: "pointer",
                textDecoration: "underline",
              }}
            >
              About DLabs
            </Text>
            <Text
              _hover={{
                cursor: "pointer",
                textDecoration: "underline",
              }}
            >
              Register your service
            </Text>
            <Text
              _hover={{
                cursor: "pointer",
                textDecoration: "underline",
              }}
            >
              Terms of use
            </Text>
            <Text
              _hover={{
                cursor: "pointer",
                textDecoration: "underline",
              }}
            >
              Help & support
            </Text>
            <Text
              _hover={{
                cursor: "pointer",
                textDecoration: "underline",
              }}
            >
              Privacy
            </Text>
          </VStack>
          <VStack alignItems={"flex-start"} fontSize={15} gap={10}>
            <VStack alignItems={"flex-start"} fontSize={15}>
              <Heading
                pb={5}
                fontSize={18}
                fontWeight={600}
                _hover={{
                  cursor: "default",
                }}
              >
                Contact Us
              </Heading>
              <Text
                _hover={{
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
              >
                2972 Ranpwill Rd. Maharastra 85486
              </Text>
              <Text
                _hover={{
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
              >
                +91 73037 01602
              </Text>
              <Text
                _hover={{
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
              >
                contact@dilatelabs.com
              </Text>
            </VStack>
            <VStack>
              <HStack
                border={"1px solid teal"}
                borderRadius={"12"}
                p={"1"}
                width={"100%"}
                h={"100%"}
                justifyContent={"space-between"}
                alignItems={"center"}
                spacing={"3"}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 48 48"
                  width="32px"
                  height="32px"
                  baseProfile="basic"
                >
                  <linearGradient
                    id="AraffhWwwEqZfgFEBZFoqa"
                    x1="18.102"
                    x2="25.297"
                    y1="3.244"
                    y2="34.74"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0" stop-color="#35ab4a" />
                    <stop offset=".297" stop-color="#31a145" />
                    <stop offset=".798" stop-color="#288739" />
                    <stop offset="1" stop-color="#237a33" />
                  </linearGradient>
                  <path
                    fill="url(#AraffhWwwEqZfgFEBZFoqa)"
                    d="M13.488,4.012C10.794,2.508,7.605,3.778,6.45,6.323L24.126,24l9.014-9.014L13.488,4.012z"
                  />
                  <linearGradient
                    id="AraffhWwwEqZfgFEBZFoqb"
                    x1="19.158"
                    x2="21.194"
                    y1="23.862"
                    y2="66.931"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0" stop-color="#f14e5d" />
                    <stop offset=".499" stop-color="#ea3d4f" />
                    <stop offset="1" stop-color="#e12138" />
                  </linearGradient>
                  <path
                    fill="url(#AraffhWwwEqZfgFEBZFoqb)"
                    d="M33.14,33.014L24.126,24L6.45,41.677 c1.156,2.546,4.345,3.815,7.038,2.312L33.14,33.014z"
                  />
                  <linearGradient
                    id="AraffhWwwEqZfgFEBZFoqc"
                    x1="32.943"
                    x2="36.541"
                    y1="14.899"
                    y2="43.612"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0" stop-color="#ffd844" />
                    <stop offset=".519" stop-color="#ffc63f" />
                    <stop offset="1" stop-color="#ffb03a" />
                  </linearGradient>
                  <path
                    fill="url(#AraffhWwwEqZfgFEBZFoqc)"
                    d="M41.419,28.393 c1.72-0.96,2.58-2.676,2.581-4.393c-0.001-1.717-0.861-3.434-2.581-4.393l-8.279-4.621L24.126,24l9.014,9.014L41.419,28.393z"
                  />
                  <linearGradient
                    id="AraffhWwwEqZfgFEBZFoqd"
                    x1="13.853"
                    x2="15.572"
                    y1="5.901"
                    y2="42.811"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset=".003" stop-color="#0090e6" />
                    <stop offset="1" stop-color="#0065a0" />
                  </linearGradient>
                  <path
                    fill="url(#AraffhWwwEqZfgFEBZFoqd)"
                    d="M6.45,6.323C6.168,6.948,6,7.652,6,8.408 v31.179c0,0.761,0.164,1.463,0.45,2.09l17.674-17.68L6.45,6.323z"
                  />
                </svg>
                <Box
                  flexDir={"column"}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  gap={"0"}
                  pr={"1"}
                >
                  <Text fontSize={"xs"} color="teal" p={"0"} mb={"-1"}>
                    Coming Soon On
                  </Text>
                  <Text fontSize={"md"} fontWeight={"500"} p={"0"}>
                    Google Play
                  </Text>
                </Box>
              </HStack>
            </VStack>
          </VStack>
        </HStack>
      </HStack>
      <Text fontWeight={500} fontSize={15}>
        © 2023-2024 Dilate Labs Private Limited.All Rights Reserved
      </Text>
    </VStack>
  );
};
