import {
  Box,
  Button,
  Divider,
  HStack,
  Heading,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  VStack,
  Flex,
  IconButton,
} from "@chakra-ui/react";
import { Formiz, useForm } from "@formiz/core";
import React from "react";
import { Steps } from "./Steps";
import { Step1 } from "./Step1";
import { Step2 } from "./Step2";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { Step3 } from "./Step3";

export const CreateCustomerModal = ({ open, setOpen }) => {
  const [show, setShow] = React.useState(false);
  const form = useForm();

  function handleClick(event) {
    alert("hello");
  }

  function handleLogin(event) {
    alert("login");
  }
  return (
    <Modal
      isOpen={open}
      onClose={() => {
        setOpen(false);
      }}
      size={"3xl"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <HStack alignItems={"flex-start"} spacing={5}>
            <VStack flex={1} p={5} display={["none", "flex"]}>
              <Heading fontSize={"2xl"}>
                Walk In Customer Details.
              </Heading>
              <Image src={"/assets/signup/Signup.png"} width={80} />
            </VStack>
            <VStack flex={1} p={[1, 5]} spacing={[5, 7]}>
              <Formiz
                connect={form}
                // onValidSubmit={submitForm}
                // onSubmit={() => submitForm()}
              >
                <Box
                  sx={{
                    width: "full",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    flex: 1,
                    gap: 5,
                  }}
                  as="form"
                  noValidate
                  onSubmit={form.submitStep}
                >
                  <Steps form={form} />
                  <Step1 form={form} />
                  <Step2 form={form} />
                  <Step3 form={form} />
                </Box>
              </Formiz>
              <Stack spacing={3}>
                <Flex justifyContent={"space-between"} px={2}>
                  {!form.isFirstStep ? (
                    <>
                      <IconButton
                        p={2}
                        // onClick={() => prevStep(form)}
                        sx={{
                          border: "1px solid #fff",
                          borderRadius: 50,
                        }}
                      >
                        <ChevronRightIcon color={"teal"} />
                      </IconButton>
                    </>
                  ) : (
                    <>
                      {/* <Button
                        p={1}
                        variant="outline"
                        sx={{
                          borderRadius: 50,
                          px: 3,
                          gap: 1,
                        }}
                      >
                        <AiOutlineGoogle
                          alt={'Google img'}
                          size={25}
                          color="#0C727E"
                        />
                        <Text
                          sx={{
                            fontWeight: '500',
                            color: 'teal',
                          }}
                        >
                          Google
                        </Text>
                      </Button> */}
                    </>
                  )}

                  {form.isLastStep ? (
                    <Button
                      isLoading={false}
                      loadingText="Submitting"
                      colorScheme="teal"
                      variant="solid"
                      borderRadius={50}
                      onClick={() => alert("Submitting")}
                    >
                      Submit
                    </Button>
                  ) : (
                    <>
                      {form.isFirstStep ? (
                        <Button
                          colorScheme="teal"
                          variant="solid"
                          borderRadius={50}
                          gap={1}
                          onClick={form.nextStep}
                        >
                          <Text
                            sx={{
                              fontWeight: "500",
                              color: "white",
                            }}
                          >
                            Next
                          </Text>
                          <ChevronRightIcon size={25} />
                        </Button>
                      ) : (
                        <>
                          <IconButton
                            p={2}
                            onClick={form.nextStep}
                            // size="large"
                            sx={{
                              border: "1px solid #fff",
                              borderRadius: 50,
                            }}
                          >
                            <ChevronRightIcon color={"teal"} />
                          </IconButton>
                        </>
                      )}
                    </>
                  )}
                </Flex>
              </Stack>
            </VStack>
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
