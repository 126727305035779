import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  HStack,
  VStack,
  Heading,
  Image,
  Text,
  Input,
  Stack,
  Divider,
  Box,
  InputGroup,
  InputRightElement,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import useOwnStore from "../../../store/store";

export const SigninModal = () => {
  const { signin, setSignin, setLab, setSignup } = useOwnStore();
  const [email, setEmail] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [password, setPassword] = React.useState("");
  const [show, setShow] = React.useState(false);
  const toast = useToast();
  const handleClick = () => setShow(!show);

  const handleLogin = async () => {
    setLoading(true);
    try {
      if (email && password) {
        const res = await axios.post(
          `${process.env.REACT_APP_SERVER}/lab/login`,
          {
            email,
            password,
          }
        );
        localStorage.setItem("lab", JSON.stringify(res?.data?.lab));
        // console.log("calling lab from local storage", newLab);
        setLab(res?.data?.lab);
        setSignin(false);
        toast({
          title: "Login Success",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        return;
      }
      toast({
        title: "Please Enter Email / Passowrd",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      // console.log(error);
      toast({
        title: `Error: ${error?.response?.data?.message}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={signin} onClose={() => setSignin(false)} size={"3xl"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader />
        <ModalCloseButton />
        <ModalBody>
          <HStack alignItems={"flex-start"} spacing={5}>
            <VStack flex={1} p={5} display={["none", "flex"]}>
              <Heading fontSize={"2xl"}>Welcome To DLabs</Heading>
              <Image src={"/assets/signup/Signup.png"} width={80} />
            </VStack>
            <VStack flex={1} p={[1, 5]} spacing={7}>
              <Heading fontSize={"23"}> Login </Heading>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  gap: 3,
                }}
              >
                <Input
                  placeholder="Enter email*"
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <InputGroup size="md">
                  <Input
                    pr="4.5rem"
                    type={show ? "text" : "password"}
                    placeholder="Enter password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <InputRightElement width="4.5rem">
                    <Button h="1.75rem" size="sm" onClick={handleClick}>
                      {show ? "Hide" : "Show"}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </Box>
              <Stack spacing={3}>
                <Button
                  isLoading={loading}
                  bg={"#0C727E"}
                  color={"white"}
                  fontWeight={400}
                  width={310}
                  onClick={handleLogin}
                >
                  Login
                </Button>
                <Divider />

                <Text display={"flex"} fontSize={12} fontWeight={500} gap={1}>
                  Don't Have An Account?
                  <Text
                    _hover={{ cursor: "pointer", textDecor: "underline" }}
                    color={"#0C727E"}
                    onClick={() => {
                      setSignup(true);
                      setSignin(false);
                    }}
                  >
                    Sign Up
                  </Text>
                </Text>
              </Stack>
            </VStack>
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
