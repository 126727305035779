import React from "react";
import { Box } from "@chakra-ui/react";
import { YoutubeVideoSearch } from "./VideoSearch";

export const Training = () => {
  return (
    <Box
      sx={{
        width: "80%",
        px: 10,
        py: 10,
        display: "flex",
        flexDir: "column",
        alignItems: "center",
        gap: 1,
        marginLeft: "64",
        overflowY: "scroll",
        height: "87vh",
      }}
    >
      <YoutubeVideoSearch />
    </Box>
  );
};
