import {
  Button,
  Heading,
  Input,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { useState } from "react";

export const AddBankDetails = ({ bankDetails, setBankDetails, labId }) => {
  const toast = useToast();
  const [bankData, setBankData] = useState({ ...bankDetails, labId: labId });
  const [loading, setLoading] = useState(false);

  const onChangeHandler = (e) => {
    setBankData({ ...bankData, [e.target?.name]: e.target?.value });
  };

  const submitHanlder = async () => {
    try {
      setLoading(true);
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER}/bankDetails/add`,
        bankData
      );
      setBankDetails(res?.data?.bankDetails);
      // console.log("calling add bank res", res);
      toast({
        title: "bank details added successfully",
        status: "error",
        duration: "5000",
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "error while adding bank details",
        status: "error",
        duration: "5000",
        isClosable: true,
      });
      console.error("Error fetching bank details:", error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <VStack
      flex={1}
      py={[1, 5]}
      spacing={7}
      width={"100%"}
      gap={"10"}
      justifyContent={"flex-start"}
      alignItems={"center"}
      px={"32"}
    >
      <Heading>My Wallet</Heading>
      <Text color={"teal"} fontWeight={"600"}>
        To check the wallet insights , you need to add the bank deatils
      </Text>

      <VStack gap={"8"} width={"50%"} alignItems={"flex-start"}>
        <Input
          type="text"
          placeholder="Account Holder Name"
          name="accountHolder"
          value={bankData?.accountHolder}
          focusBorderColor="teal.500"
          onChange={(e) => onChangeHandler(e)}
        />
        <Input
          type="text"
          placeholder="Account Number"
          name="accountNumber"
          value={bankData?.accountNumber}
          focusBorderColor="teal.500"
          onChange={(e) => onChangeHandler(e)}
        />
        <Input
          type="text"
          placeholder="Bank Name"
          name="bankName"
          value={bankData?.bankName}
          focusBorderColor="teal.500"
          onChange={(e) => onChangeHandler(e)}
        />
        <Input
          type="text"
          placeholder="Branch Name"
          name="branchName"
          value={bankData?.branchName}
          focusBorderColor="teal.500"
          onChange={(e) => onChangeHandler(e)}
        />
        <Button
          width={"full"}
          variant={"solid"}
          colorScheme="teal"
          onClick={submitHanlder}
          isLoading={loading}
        >
          Submit Details
        </Button>
      </VStack>
    </VStack>
  );
};
