import {
  Box,
  Button,
  Card,
  CardBody,
  Checkbox,
  HStack,
  Heading,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Textarea,
  VStack,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import useOwnStore from "../../../../store/store";
import axios from "axios";
import { getLabTests } from "../../../../api/test";
import packagePic from "../../../../assets/about/lab_search/heart.png";

export const PackageCard = ({ packages }) => {
  const { lab } = useOwnStore();
  const labId = lab?._id;

  const [packageData, setPackageData] = useState({
    name: packages?.name,
    description: packages?.description,
    price: packages?.price,
    tests: packages?.tests,
    testCount: packages?.tests?.length,
    labId: labId,
  });

  // console.log("calling package data before creating", packageData);
  const [availableTests, setAvailableTests] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const toast = useToast();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPackageData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleTestChange = (testId, isChecked) => {
    setPackageData((prevData) => ({
      ...prevData,
      tests: isChecked
        ? [...prevData.tests, testId]
        : prevData.tests.filter((test) => test !== testId),
    }));
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      // console.log("calling package data before updating", packageData);
      const res = await axios.put(
        `${process.env.REACT_APP_SERVER}/updateLabPackage/${packages?._id}`,
        packageData
      );
      // console.log("calling update package res in packageCard", res);
      setPackageData(res.data.updatedPackage);
      onClose();

      toast({
        title: "lab package updated successfully",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error updating package:", error.message);
      toast({
        title: "Error updating package",
        description: error.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchTests = async () => {
    try {
      const res = await getLabTests(labId);
      setAvailableTests(res);
      // console.log(res);
    } catch (error) {
      console.error("Error fetching tests:", error.message);
      toast({
        title: "Error fetching tests",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchTests();
  }, []);

  function onClose() {
    setIsOpen(false);
  }

  return (
    <>
      <Card
        maxW={["full", "lg"]}
        px={3}
        py={1}
        borderRadius={8}
        variant={"outline"}
        transition={"ease-in-out 0.3s"}
        border={"1px solid black"}
        _hover={{
          boxShadow: "0px 4px 4px 0px rgba(42, 43, 44, 0.50)",
        }}
      >
        <CardBody>
          <VStack spacing={5}>
            <HStack spacing={5} alignItems={["flex-start"]}>
              <Image src={packagePic} width={"24"} height={"24"} />
              <VStack alignItems={"flex-start"}>
                <Heading
                  fontSize={[22, 25]}
                  _hover={{
                    cursor: "pointer",
                  }}
                >
                  {packageData?.name?.slice(0, 23)}
                  {packageData?.name?.length > 25 && "..."}
                </Heading>
                <Text>
                  {packageData?.description?.slice(0, 150)}
                  {packageData?.description?.length > 150 && "..."}
                </Text>
              </VStack>
            </HStack>
            <Card
              maxW="lg"
              width={"100%"}
              sx={{
                display: "flex",
                flexDirection: "rows",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              px={[3, 6]}
              py={2}
              borderRadius={8}
              variant={"outline"}
            >
              <Text color={"#1DC2D5"} fontSize={[18, 20]} fontWeight={600}>
                ₹ {packageData?.price}
              </Text>
              <Text
                color={"#1DC2D5"}
                fontSize={[18, 20]}
                fontWeight={600}
                display={"flex"}
                flexDirection={"column"}
              >
                basic
                <Text color={"black"} fontSize={15} fontWeight={600}>
                  {packageData?.tests?.length}
                </Text>
              </Text>
            </Card>
            <Button
              bg={"#0C727E"}
              color={"#fff"}
              _hover={{}}
              onClick={() => setIsOpen(true)}
            >
              Edit Package
            </Button>
          </VStack>
        </CardBody>
      </Card>
      <Modal isOpen={isOpen} onClose={onClose} size={"4xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Update Health Package</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <HStack alignItems={"flex-start"} spacing={5}>
              <VStack flex={1} p={5} display={["none", "flex"]}>
                <Image src={"/assets/signup/Signup.png"} width={"8xl"} />
              </VStack>
              <VStack flex={1} p={[1, 5]} spacing={7}>
                <Heading>Update Health Package</Heading>
                <Stack
                  sx={{
                    display: "flex",
                    flexDir: "column",
                    gap: "3",
                    width: "100%",
                  }}
                >
                  <Input
                    placeholder="Package Name"
                    name="name"
                    value={packageData?.name}
                    onChange={handleChange}
                  />
                  <Textarea
                    placeholder="Package Description"
                    name="description"
                    value={packageData?.description}
                    onChange={handleChange}
                  />
                  <Input
                    placeholder="Price"
                    type="number"
                    name="price"
                    value={packageData?.price}
                    onChange={handleChange}
                  />
                  <Input
                    placeholder="Test Count"
                    type="number"
                    name="testCount"
                    value={packageData?.testCount}
                    onChange={handleChange}
                  />
                </Stack>

                <Box gap={"2"}>
                  <h3>Available Tests:</h3>
                  {availableTests.map((test) => (
                    <Checkbox
                      margin={2}
                      key={test?._id}
                      isChecked={packageData?.tests?.includes(test._id)}
                      onChange={(e) =>
                        handleTestChange(test._id, e.target.checked)
                      }
                    >
                      {test?.name}
                    </Checkbox>
                  ))}
                </Box>
                <Button
                  width={"full"}
                  isLoading={loading}
                  variant={"solid"}
                  colorScheme="teal"
                  onClick={handleSubmit}
                >
                  Update
                </Button>
              </VStack>
            </HStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
