export const getLab = () => {
  try {
    const labString = localStorage.getItem("lab");
    // console.log("calling labstring in the getlab", labString);
    if (!labString) {
      return null;
    }
    const lab = JSON.parse(labString);
    // console.log("calling lab after parsing the string in get lab", lab);
    return lab;
  } catch (error) {
    // console.error("Error parsing alb from localStorage:", error);
    return null;
  }
};
