import {
  Card,
  CardBody,
  HStack,
  Heading,
  Icon,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { LiaCreditCardSolid } from "react-icons/lia";
import { BsWindowFullscreen } from "react-icons/bs";
import { BiNotepad } from "react-icons/bi";

export const ExploreDlab = () => {
  return (
    <VStack p={10} spacing={16}>
      <Heading color="teal">Explore DLabs!</Heading>
      <HStack spacing={[8, 5]} flexDir={["column", "row"]}>
        <HStack spacing={5}>
          <Card maxW="md	" px={3} py={1} borderRadius={8} variant={"outline"}>
            <CardBody>
              <VStack spacing={5} alignItems={"center"}>
                <Icon as={BsWindowFullscreen} width={100} height={35} />
                <Heading fontSize={[20, 25]} color={"#1D5563"}>
                  Sign-up Process
                </Heading>
                <Text textAlign={"center"}>
                  Register your lab in easy steps.
                </Text>
              </VStack>
            </CardBody>
          </Card>
        </HStack>
        <HStack spacing={5}>
          <Card
            maxW={["md", "lg"]}
            px={3}
            py={1}
            borderRadius={8}
            variant={"outline"}
          >
            <CardBody>
              <VStack spacing={5} alignItems={"center"}>
                <Icon as={LiaCreditCardSolid} width={100} height={35} />
                <Heading fontSize={[20, 25]} color={"#1D5563"}>
                  Commission Charges
                </Heading>
                <Text textAlign={"center"}>No Extra Commission Charges</Text>
              </VStack>
            </CardBody>
          </Card>
        </HStack>
        <HStack spacing={5}>
          <Card maxW="md	" px={3} py={1} borderRadius={8} variant={"outline"}>
            <CardBody>
              <VStack spacing={5} alignItems={"center"}>
                <Icon as={BiNotepad} width={100} height={35} />
                <Heading fontSize={[20, 25]} color={"#1D5563"}>
                  Eligibility Criteria
                </Heading>
                <Text textAlign={"center"}>
                  Laboratory with minimum 100 sq.ft space
                </Text>
              </VStack>
            </CardBody>
          </Card>
        </HStack>
      </HStack>
    </VStack>
  );
};
