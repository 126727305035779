import axios from "axios";

export const getCustomerDetails = async (customerId) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_SERVER}/getUser/${customerId}`
    );

    const customer = res?.data?.user;

    return customer;
  } catch (error) {
    console.error("error while getting customer", error);
  }
};
