import React, { useState } from "react";
import {
  Text,
  Heading,
  VStack,
  HStack,
  Stack,
  Box,
  Button,
  Progress,
  Avatar,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Divider,
  Tag,
} from "@chakra-ui/react";
import { StarIcon } from "@chakra-ui/icons";
import { BsThreeDotsVertical } from "react-icons/bs";
import ReactStars from "react-rating-stars-component";

export const RatingCard = ({ reviews }) => {
  const [displayedReviews, setDisplayedReviews] = useState(5);

  const handleSeeMoreReviews = () => {
    setDisplayedReviews((prevDisplayedReviews) => prevDisplayedReviews + 5);
  };

  const totalRatings = reviews?.length;
  const averageRating =
    totalRatings === 0
      ? 0
      : reviews?.reduce((acc, review) => acc + review?.ratingStar, 0) /
        totalRatings;

  const ratingCounts = Array(5).fill(0);

  reviews?.forEach((review) => {
    ratingCounts[review?.ratingStar - 1]++;
  });

  const ratingTextMap = {
    1: "Terrible",
    2: "Bad",
    3: "Average",
    4: "Good",
    5: "Excellent",
  };

  return (
    <VStack width={"100%"} alignItems={"center"} gap={"5"}>
      <Heading color="teal" fontSize={[20, 25]} width={"100%"}>
        Reviews
      </Heading>
      {totalRatings === 0 ? (
        <Heading as={"h2"} textAlign={"center"} width={"100%"}>
          No Reviews Found
        </Heading>
      ) : (
        <>
          <Stack
            py={["7", "10"]}
            flexDirection={["column", "row"]}
            alignItems={"center"}
            justifyContent={"center"}
            width={"100%"}
            spacing={"0"}
          >
            <Box
              display={"flex"}
              flexDir={"column"}
              gap={"3"}
              justifyContent={"center"}
              alignItems={"center"}
              width={"100%"}
              border={"1px solid gray"}
              h={"300px"}
            >
              <Tag backgroundColor={"green.500"} color={"white"} size={"lg"}>
                {averageRating.toFixed(1)}
              </Tag>
              <HStack gap={"2"}>
                {[...Array(5)].map((_, index) => (
                  <StarIcon
                    key={index}
                    color={
                      index < Math.floor(averageRating) ? "#ffd700" : "gray.300"
                    }
                  />
                ))}
              </HStack>
              <Text fontSize="14px" fontWeight={"500"}>
                {totalRatings === 0
                  ? "No Ratings"
                  : ratingTextMap[Math.floor(averageRating)]}
              </Text>
              <Text fontSize="14px">({totalRatings} Ratings)</Text>
            </Box>

            <VStack
              w={"100%"}
              alignItems={"center"}
              border={"1px solid gray"}
              h={"300px"}
            >
              {ratingCounts.map((count, index) => (
                <HStack
                  key={index}
                  p={2}
                  alignItems="center"
                  justifyContent={"center"}
                  width={"100%"}
                  gap={"5"}
                >
                  <Text>{index + 1}</Text>
                  <StarIcon color={"gray.300"} />
                  <Progress
                    value={(count / totalRatings) * 100}
                    size={"sm"}
                    width={"100%"}
                    colorScheme="green"
                  />
                  <Text>{count}</Text>
                </HStack>
              ))}
            </VStack>
          </Stack>
          <Box
            my={4}
            width={"100%"}
            display={"flex"}
            flexDir={"column"}
            justifyContent={"flex-start"}
            alignItems={"flex-start"}
            gap={"10"}
          >
            {reviews
              .slice(0, displayedReviews)
              ?.reverse()
              .map((review, index) => (
                <VStack width={"100%"} alignItems={"flex-start"} gap={"5"}>
                  <HStack justifyContent={"space-between"} width={"100%"}>
                    <HStack gap={"5"}>
                      {review?.user?.fileUrl ? (
                        <Avatar
                          src={review?.user?.fileUrl}
                          alt="SA"
                          size={"lg"}
                        />
                      ) : (
                        <Avatar alt="SA" size={"lg"} />
                      )}
                      <VStack gap={"3"}>
                        {review?.user?.firstName ? (
                          <Text>
                            {review?.user?.firstName +
                              " " +
                              review?.user?.lastName}
                          </Text>
                        ) : (
                          <Text>{review?.user?.phone}</Text>
                        )}
                        <HStack gap={"2"}>
                          <ReactStars
                            count={5}
                            value={review?.ratingStar}
                            size={24}
                            activeColor="#ffd700"
                            edit={false}
                          />
                        </HStack>
                      </VStack>
                    </HStack>
                    <HStack
                      justifyContent={"space-between"}
                      gap={"10"}
                      alignItems={"flex-start"}
                    >
                      <Text color={"gray"}>
                        {review?.createdAt.split("T")[0]}
                      </Text>
                      <Menu>
                        <MenuButton paddingTop={"1"}>
                          <BsThreeDotsVertical />
                        </MenuButton>
                        <MenuList>
                          <MenuItem>Delete</MenuItem>
                          <MenuItem>Reply</MenuItem>
                        </MenuList>
                      </Menu>
                    </HStack>
                  </HStack>
                  <Text>{review?.reviewText}</Text>
                  {/* <HStack gap={"10"}>
                  <HStack gap={"5"}>
                    <AiOutlineLike />
                    <Text color={"gray"}>Helpful</Text>
                  </HStack>
                  <HStack gap={"5"}>
                    <AiOutlineComment />
                    <Text color={"gray"}>Comment</Text>
                  </HStack>
                  <HStack gap={"5"}>
                    <AiOutlineShareAlt />
                    <Text color={"gray"}>Share</Text>
                  </HStack>
                </HStack> */}
                  <Divider width={"100%"} border={"1px solid gray"} />
                </VStack>
              ))}
            <Box width={"100%"} display={"flex"} justifyContent={"center"}>
              {displayedReviews < reviews.length && (
                <Button
                  my={4}
                  colorScheme="teal"
                  onClick={handleSeeMoreReviews}
                >
                  See More Reviews
                </Button>
              )}
            </Box>
          </Box>
        </>
      )}
    </VStack>
  );
};
