import React, { useEffect, useState } from "react";
import {
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  VStack,
  Input,
  Table,
  TableContainer,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  useToast,
  Text,
  Grid,
  Box,
} from "@chakra-ui/react";
import axios from "axios";

import {
  calculateAge,
  getFormattedDate,
} from "../../Dashboard/Orders/Report/ReportCard";
import { useNavigate } from "react-router-dom";

export const SRAModal = ({ open, onClose, order, setOrder }) => {
  const toast = useToast();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [sraError, setSraError] = useState(false);
  const [confirmBarcode, setConfirmBarcode] = useState("");
  const [patient, setPatient] = useState("");

  const handleConfirm = async () => {
    try {
      setLoading(true);

      // console.log(
      //   "calling confirm and barcode data",
      //   order?.barcodeData,
      //   confirmBarcode
      // );
      if (confirmBarcode === order?.barcodeData.toUpperCase()) {
        const response = await axios.put(
          `${process.env.REACT_APP_SERVER}/labs/orders/sra/${order?._id}`
        );
        setOrder(response?.data?.order);
        // console.log("calling confirm response", response);
        onClose();
        toast({
          title: "sra verified successfully",
          duration: 5000,
          status: "success",
          isClosable: true,
        });
      } else {
        setSraError(true);
        toast({
          title: "Barcode data entered does not match.",
          duration: 5000,
          status: "error",
          isClosable: true,
        });
      }
    } catch (error) {
      console.error(error);
      toast({
        title: "error while confirming sra",
        duration: 5000,
        status: "error",
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const getPatient = async (order) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER}/getPatient?patientId=${order?.patientId}&userId=${order?.consumerDetails?.user?._id}`
      );

      // console.log("calling patient response", response);

      setPatient(response?.data?.patient);
    } catch (error) {
      console.error("error while fetching patient", error);
      toast({
        title: "error in getting patient details",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    getPatient(order);
  }, []);

  return (
    <>
      <Modal isOpen={open} onClose={onClose} size={"6xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <VStack
              flex={1}
              p={[1, 5]}
              spacing={[5, 7]}
              justifyContent={"center"}
            >
              <Heading fontSize={"2xl"}>Verify Sample Registration</Heading>

              <Grid
                templateColumns={["1fr 1fr 1fr 1fr"]}
                width={"100%"}
                borderY={"2px solid black"}
                py={"5"}
                px={"32px"}
              >
                <Box alignItems={"flex-start"}>
                  <Text fontWeight={"bold"} fontSize={"12px"}>
                    Patient Name
                  </Text>
                  <Text fontWeight={"bold"} fontSize={"12px"}>
                    Age/Gender
                  </Text>
                  <Text fontWeight={"bold"} fontSize={"12px"}>
                    Order ID
                  </Text>
                  <Text fontWeight={"bold"} fontSize={"12px"}>
                    Order Name
                  </Text>
                </Box>
                <Box alignItems={"flex-start"}>
                  <Text fontSize={"12px"}>: {patient?.name}</Text>
                  <Text fontSize={"12px"}>
                    {`     : ${calculateAge(patient?.birthdate)}
                    years / ${patient?.gender}`}
                  </Text>
                  <Text fontSize={"12px"}>: {order?._id}</Text>
                  <Text fontSize={"12px"}>: {order?.name}</Text>
                </Box>
                <Box alignItems={"flex-start"}>
                  <Text fontWeight={"bold"} fontSize={"12px"}>
                    Referred By
                  </Text>
                  <Text fontWeight={"bold"} fontSize={"12px"}>
                    Sample Mode
                  </Text>
                  <Text fontWeight={"bold"} fontSize={"12px"}>
                    Sample Collected On
                  </Text>
                  <Text fontWeight={"bold"} fontSize={"12px"}>
                    Order Status
                  </Text>
                </Box>
                <Box alignItems={"flex-start"}>
                  <Text fontSize={"12px"}>: {patient?.tag}</Text>
                  <Text fontSize={"12px"}>
                    : {order?.sampleMode} collection
                  </Text>
                  <Text fontSize={"12px"}>
                    : {getFormattedDate(order?.createdAt.split("T")[0])}
                  </Text>
                  <Text fontSize={"12px"}>: {order?.orderStatus}</Text>
                </Box>
              </Grid>
              <TableContainer width={"100%"}>
                <Table
                  variant="simple"
                  width="100%"
                  p="0"
                  css={{ tableLayout: "fixed" }}
                >
                  <Thead width="100%">
                    <Tr width="100%">
                      <Th width="25%">Confirm Barcode Number</Th>
                      <Th width="25%" isNumeric>
                        Action
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody px={"0"}>
                    {sraError ? (
                      <Tr>
                        <Td>
                          <Button
                            onClick={() => {
                              navigate("/dashboard/help");
                              setSraError(false);
                              onClose();
                            }}
                            colorScheme="purple"
                          >
                            Contact Support Team for help
                          </Button>
                        </Td>
                        <Td isNumeric>
                          <Button
                            colorScheme="teal"
                            onClick={() => setSraError(false)}
                          >
                            Try Again
                          </Button>
                        </Td>
                      </Tr>
                    ) : (
                      <Tr width="100%" px={"0"}>
                        <Td width="25%">
                          <Input
                            type="text"
                            name="confirmBarcode"
                            placeholder="Enter Barcode Number"
                            value={confirmBarcode.toUpperCase()}
                            onChange={(e) => setConfirmBarcode(e.target.value)}
                          />
                        </Td>
                        <Td width="25%" isNumeric>
                          <Button
                            colorScheme="teal"
                            isLoading={loading}
                            onClick={handleConfirm}
                          >
                            Confirm
                          </Button>
                        </Td>
                      </Tr>
                    )}
                  </Tbody>
                </Table>
              </TableContainer>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
