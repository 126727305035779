// YoutubeVideoSearch.jsx
import React, { useEffect, useState } from "react";
import {
  VStack,
  Input,
  Button,
  InputGroup,
  InputRightAddon,
  Text,
  HStack,
  Heading,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { YoutubeVideoPlayer } from "../VideoPlayer";
import { YoutubeVideoResult } from "../VideoList";

export const YoutubeVideoSearch = () => {
  const [searchKeyword, setSearchKeyword] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);

  const GoogleDeveloperApiKey = "AIzaSyCKzQX7vdNYtITYdTFOW_INtgutZkyagOI";

  const handleVideoSearch = async () => {
    try {
      const response = await fetch(
        `https://www.googleapis.com/youtube/v3/search?q=${searchKeyword}&part=snippet&maxResults=5&type=video&key=${GoogleDeveloperApiKey}`
      );

      // console.log("calling youtube search api response", response);
      if (!response.ok) {
        throw new Error("Failed to fetch YouTube videos");
      }

      const data = await response.json();

      // console.log("calling response jason data", data);

      // Extract video information from the API response
      const videos = data.items.map((item) => ({
        id: item.id.videoId,
        title: item.snippet.title,
        thumbnail: item.snippet.thumbnails.default,
      }));

      setSearchResults(videos);
    } catch (error) {
      console.error("Error fetching YouTube videos:", error);
    }
  };

  const playVideo = (video) => {
    setSelectedVideo(video);
  };

  useEffect(() => {
    setSelectedVideo(searchResults ? searchResults[0] : null);
  }, [searchResults]);
  return (
    <VStack gap={10} maxH={"90vh"} overflowY={"scroll"}>
      <InputGroup>
        <Input
          placeholder="Search for videos..."
          value={searchKeyword}
          onChange={(e) => setSearchKeyword(e.target.value)}
          focusBorderColor="teal.500"
        />
        <InputRightAddon p={"0"}>
          <Button
            colorScheme="teal"
            onClick={handleVideoSearch}
            borderLeftRadius={"0"}
          >
            <SearchIcon />
          </Button>
        </InputRightAddon>
      </InputGroup>
      <VStack
        width={"100%"}
        alignItems={"flex-start"}
        justifyContent={"center"}
        gap={"10"}
      >
        {searchResults.length > 0 ? (
          <VStack align="flex-start" spacing={4} width="100%" gap={"10"}>
            <Text fontWeight="bold" width={"100%"} color="teal">
              Search Results
            </Text>
            <HStack width={"100%"}>
              {searchResults.map((video) => (
                <YoutubeVideoResult
                  key={video.id}
                  video={video}
                  onPlayVideo={playVideo}
                />
              ))}
            </HStack>
          </VStack>
        ) : (
          <VStack width={"100%"}>
            <Heading>Nothing to watch in training</Heading>
            <Text>Search for a topic to begin your training</Text>
          </VStack>
        )}

        {selectedVideo && (
          <VStack spacing={4} alignItems={"center"} gap={"10"} width={"100%"}>
            <Text fontWeight="bold" color="teal" width={"100%"}>
              Currently Watching
            </Text>
            <YoutubeVideoPlayer video={selectedVideo} />
          </VStack>
        )}
      </VStack>
    </VStack>
  );
};
