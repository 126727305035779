import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  HStack,
  VStack,
  Heading,
  Image,
  Stepper,
  Step,
  StepIndicator,
  StepStatus,
  StepIcon,
  StepNumber,
  Box,
  StepTitle,
  StepSeparator,
  useSteps,
} from "@chakra-ui/react";
import useOwnStore from "../../../store/store";
import { RegistrationStep1 } from "../../RegistrationStep/Step1";
import { RegistrationStep2 } from "../../RegistrationStep/Step2";
import { RegistrationStep3 } from "../../RegistrationStep/Step3";

export const SignupModal = () => {
  const { signup, setSignup, lab, setLab } = useOwnStore();

  const steps = [
    { title: "Contact Inforamtion" },
    { title: "Address Information" },
    { title: "Lab Information" },
  ];

  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps?.length,
  });

  function onChangeHandler(e) {
    setLab({ ...lab, [e.target.name]: e.target.value });
  }

  return (
    <Modal isOpen={signup} onClose={() => setSignup(false)} size={"4xl"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader />
        <ModalCloseButton />
        <ModalBody>
          <HStack
            alignItems={"center"}
            spacing={5}
            justifyContent={"space-between"}
          >
            <VStack
              h={"100%"}
              width={"100%"}
              flex={1}
              p={5}
              gap={"5"}
              display={["none", "flex"]}
              justifyContent={"center"}
            >
              {activeStep === 0 && (
                <Heading fontSize={"2xl"}>Register Your Lab Now</Heading>
              )}
              {activeStep === 1 && (
                <Heading fontSize={"2xl"}>Maximize Revenue With DLab</Heading>
              )}
              {activeStep === 2 && (
                <Heading fontSize={"2xl"}>
                  Increase Your Online Presence
                </Heading>
              )}

              <Image src={"/assets/signup/Signup.png"} width={96} />
            </VStack>
            <VStack
              flex={1}
              p={[1, 5]}
              spacing={[5, 5]}
              justifyContent={"flex-start"}
            >
              <Stepper
                index={activeStep}
                pb={"10"}
                px={["5", "12", "24", "24"]}
                justifyContent={"space-between"}
                size={["lg"]}
                colorScheme="teal"
              >
                {steps.map((step, index) => (
                  <Step key={index}>
                    <StepIndicator
                      sx={{
                        backgroundColor:
                          activeStep < index ? "#D9D9D9" : "teal!important",
                        cursor: "pointer",
                      }}
                      onClick={() => setActiveStep(() => index)}
                    >
                      <StepStatus
                        complete={
                          <StepIcon
                            h={["10px", "15px"]}
                            width={["10px", "15px"]}
                          />
                        }
                        incomplete={
                          <StepNumber
                            style={{
                              fontSize: ["8px", "12px"],
                              color: "rgb(0, 0, 0)",
                              borderColor: "#D9D9D9",
                            }}
                          />
                        }
                        active={
                          <StepNumber
                            style={{
                              fontSize: ["8px", "12px"],
                              color: "white",
                              borderColor: "teal",
                            }}
                          />
                        }
                      />
                    </StepIndicator>
                    <StepSeparator />

                    {activeStep === index && (
                      <Box
                        flexShrink="0"
                        position={"absolute"}
                        top={"10"}
                        left={"-3"}
                      >
                        <StepTitle
                          style={{
                            color:
                              activeStep >= index
                                ? "teal"
                                : "rgba(0, 0, 0, 0.5)",
                            fontSize: "12px",
                            cursor: "pointer",
                            textAlign: "center",
                          }}
                          onClick={() => setActiveStep(() => index)}
                        >
                          {step.title}
                        </StepTitle>
                      </Box>
                    )}
                  </Step>
                ))}
              </Stepper>
              {activeStep === 0 && (
                <RegistrationStep1
                  activeStep={0}
                  setActiveStep={setActiveStep}
                  onChangeHandler={onChangeHandler}
                />
              )}
              {activeStep === 1 && (
                <RegistrationStep2
                  activeStep={1}
                  setActiveStep={setActiveStep}
                  onChangeHandler={onChangeHandler}
                />
              )}
              {activeStep === 2 && (
                <RegistrationStep3
                  activeStep={2}
                  setActiveStep={setActiveStep}
                  onChangeHandler={onChangeHandler}
                />
              )}
            </VStack>
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
