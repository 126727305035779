import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  HStack,
  Heading,
  Stack,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { getLabTests } from "../api/test";
import axios from "axios";
import { fetchLabOrders } from "../api/order";
import { getLabPackages, getPackages } from "../api/package";
import { getLab } from "../cookie/lab";

export const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const [testsCount, setTestsCount] = useState(0);
  const [packagesCount, setPackagesCount] = useState(0);
  const [ordersCount, setOrdersCount] = useState(0);
  const [revenue, setRevenue] = useState(0);

  const lab = getLab();
  const navigate = useNavigate();

  const dashboardHndler = async () => {
    try {
      if (lab?._id) {
        setLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER}/getTests?labId=${lab?._id}`
        );
        const fetchTests = response?.data?.tests;
        const fetchLabTests = await getLabTests({ desiredLabId: lab?._id });
        const newData = [...fetchTests, ...fetchLabTests];
        const testsCount = newData?.length;
        setTestsCount(testsCount);
        const res = await fetchLabOrders(lab?._id);
        // console.log("calling lab res in dashboard", res);
        const ordersCount = res?.data?.labOrders?.length;
        setOrdersCount(ordersCount);

        const completedOrders = res?.data?.labOrders?.filter(
          (orderData) => orderData?.orderStatus === "completed"
        );
        // console.log("calling completed orders", completedOrders);

        const getTotalRevenue = () => {
          const totalRevenue = completedOrders?.reduce(
            (total, order) => total + (order?.paymentDetails?.price || 0),
            0
          );
          return parseInt((75 * totalRevenue) / 100);
        };

        const totalRevenue = getTotalRevenue();

        // console.log("calling total revenue", totalRevenue);
        setRevenue(totalRevenue);

        const packagesData = await getPackages({});
        const fetchPackages = packagesData?.packages;
        // console.log("calling lab inside fetchpackage from store", lab);
        const fetchLabPackages = await getLabPackages(lab?._id);
        const data = [...fetchPackages, ...fetchLabPackages];
        // console.log("calling packages data", data);

        setPackagesCount(data?.length);
      }
    } catch (error) {
      console.error(error);
      toast({
        title: "error while fetching dashboard details",
        duration: 9000,
        isClosable: true,
        status: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    dashboardHndler();
    // console.log(
    //   "calling dashboard data",
    //   testsCount,
    //   packagesCount,
    //   ordersCount,
    //   revenue
    // );
  }, []);

  return (
    <Box
      top={"13vh"}
      h={"87vh"}
      sx={{
        display: "flex",
        width: "full",
        flexDir: "column",
        justifyContent: "center",
        px: 7,
        py: 5,
        gap: 5,
        overflowY: "scroll",
        marginLeft: "64",
      }}
    >
      {/* <Button
        variant={"outline"}
        fontWeight={400}
        color={"teal.400"}
        width={"sm"}
        sx={{
          p: 5,
        }}
        onClick={() => setOpen(true)}
      >
        + Set Walk In Customer Appointment
      </Button>
      <Flex
        width={"100%"}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        bg={"#98D2E0"}
        transition={"ease-in 300ms"}
        _hover={{
          bg: "#98D2E0",
          cursor: "pointer",
        }}
        px={6}
        py={4}
        borderRadius={10}
      >
        <Flex flexDir={"column"} gap={3}>
          <Flex alignItems={"center"} gap={3}>
            <Avatar size={"sm"} />
            <Text cursor={"pointer"} fontWeight={500}>
              Vedant Bhavsar
            </Text>
          </Flex>
          <Text cursor={"pointer"} fontSize={16}>
            Book Appointment for @test today
          </Text>
        </Flex>
        <Flex gap={0} flexDir={"column"} alignItems={"center"}>
          <Text
            cursor={"pointer"}
            color={"black"}
            fontSize={14}
            opacity={"50%"}
          >
            9/02/2003
          </Text>
          <Text color={"#2BB8A2"} cursor={"pointer"}>
            Online
          </Text>
        </Flex>
      </Flex>
      <Flex justifyContent={"center"}>
        <Button variant={"link"} color={"teal"} fontSize={14}>
          All Appointments
        </Button>
      </Flex> */}
      <VStack
        padding={"10"}
        gap={"10"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <HStack justifyContent={"space-evenly"} gap={"10"} width={"100%"}>
          <Card
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            size="lg"
            flex={"1"}
            variant={"filled"}
            sx={{
              borderRadius: 10,
            }}
          >
            <CardBody
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              flex={"1"}
              width={"md"}
              h={"md"}
              p={"10"}
            >
              <Stack
                width={"100%"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Flex flexDir={"column"} alignItems={"center"} gap={3}>
                  <Heading size="xl" color={"#0C727E"}>
                    ₹ {revenue}
                  </Heading>
                  <Text fontSize={"larger"}>Generated Revenue</Text>
                </Flex>
                <Button
                  variant={"ghost"}
                  color={"teal"}
                  onClick={() => navigate("/dashboard/revenue")}
                >
                  Check Revenue
                </Button>
              </Stack>
            </CardBody>
          </Card>
          <Card
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            size="lg"
            flex={"1"}
            variant={"filled"}
            sx={{
              borderRadius: 10,
            }}
          >
            <CardBody
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              flex={"1"}
              width={"md"}
              h={"md"}
              p={"10"}
            >
              <Stack
                width={"100%"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Flex flexDir={"column"} alignItems={"center"} gap={3}>
                  <Heading size="xl" color={"#0C727E"}>
                    {testsCount}
                  </Heading>
                  <Text fontSize={"larger"}>Total Collections Of Tests</Text>
                </Flex>
                <Button
                  variant={"ghost"}
                  color={"teal"}
                  onClick={() => navigate("/dashboard/tests")}
                >
                  Manage All Tests
                </Button>
              </Stack>
            </CardBody>
          </Card>
        </HStack>
        <HStack justifyContent={"space-evenly"} gap={"10"} width={"100%"}>
          <Card
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            size="lg"
            flex={"1"}
            variant={"filled"}
            sx={{
              borderRadius: 10,
            }}
          >
            <CardBody
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              flex={"1"}
              width={"md"}
              h={"md"}
              p={"10"}
            >
              <Stack
                width={"100%"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Flex flexDir={"column"} alignItems={"center"} gap={3}>
                  <Heading size="xl" color={"#0C727E"}>
                    {packagesCount}
                  </Heading>
                  <Text fontSize={"larger"}>Total Health Packages</Text>
                </Flex>
                <Button
                  variant={"ghost"}
                  color={"teal"}
                  onClick={() => navigate("/dashboard/packages")}
                >
                  Manage All Packages
                </Button>
              </Stack>
            </CardBody>
          </Card>
          <Card
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            size="lg"
            flex={"1"}
            variant={"filled"}
            sx={{
              borderRadius: 10,
            }}
          >
            <CardBody
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              flex={"1"}
              width={"md"}
              h={"md"}
              p={"10"}
            >
              <Stack
                width={"100%"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Flex flexDir={"column"} alignItems={"center"} gap={3}>
                  <Heading size="xl" color={"#0C727E"}>
                    {ordersCount}
                  </Heading>
                  <Text fontSize={"larger"}>Orders</Text>
                </Flex>
                <Button
                  variant={"ghost"}
                  color={"teal"}
                  onClick={() => navigate("/dashboard/orders")}
                >
                  Manage orders
                </Button>
              </Stack>
            </CardBody>
          </Card>
        </HStack>
      </VStack>
      {/* <CreateCustomerModal open={open} setOpen={setOpen} /> */}
    </Box>
  );
};
