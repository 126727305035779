import React, { useEffect, useState } from "react";
import {
  Text,
  Heading,
  VStack,
  Box,
  Button,
  Grid,
  Flex,
  Input,
  Image,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { useDropzone } from "react-dropzone";
import { AddIcon } from "@chakra-ui/icons";
import { getLab } from "../../../cookie/lab";

export const ImageGallery = () => {
  const [isUpdate, setIsUpdate] = useState(true);
  const [isSubmit, setIsSubmit] = useState(true);
  const [lab, setLab] = useState([]);
  const toast = useToast();
  const [labImages, setLabImages] = useState({
    frontPics: [null, null, null, null],
    receptionPics: [null, null, null, null],
    collectionPics: [null, null, null, null],
    processingPics: [null, null, null, null],
    instrumentsPics: [null, null, null, null],
    consumablesPics: [null, null, null, null],
  });
  const [labImagesUrl, setLabImagesUrl] = useState("");

  const [loading, setLoading] = useState(false);

  const handleDrop = (acceptedFiles, sectionKey, index) => {
    const newLabImages = { ...labImages };
    newLabImages[sectionKey][index] = acceptedFiles[0];

    // console.log("calling labImages", newLabImages);
    setLabImages(newLabImages);

    const newLabImagesUrl = { ...labImagesUrl };
    newLabImagesUrl[sectionKey][index] = URL.createObjectURL(acceptedFiles[0]);
    // console.log("calling new imagesUrl in handle drop", newLabImagesUrl);
    setLabImagesUrl(newLabImagesUrl);
    setIsUpdate(false);
  };

  const uploadSectionFile = async (sectionKey) => {
    setLoading(true);
    const formData = new FormData();
    const updatedLabImagesUrl = { ...labImagesUrl };
    try {
      labImages[sectionKey].forEach(async (file, index) => {
        if (file) {
          formData.append("file", file);
          formData.append("upload_preset", "vv0rqrrj");
          formData.append("cloud_name", "ddqlrpysr");

          const response = await axios.post(
            "https://api.cloudinary.com/v1_1/ddqlrpysr/image/upload",
            formData
          );

          // console.log("calling cloudinary response", response);
          if (response.status === 200) {
            const imageUrl = response?.data?.secure_url;
            updatedLabImagesUrl[sectionKey][index] = imageUrl;
          }
        }
      });
    } catch (error) {
      console.error(`Error uploading ${sectionKey} images:`, error);
    } finally {
      setLoading(false);
      setIsUpdate(true);
      setIsSubmit(false);
    }

    setLabImagesUrl(updatedLabImagesUrl);
  };

  const handleSubmit = async () => {
    setLoading(true);
    // console.log("calling labimages url before updating", labImagesUrl);
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_SERVER}/updateLabImages/${lab?._id}`,
        labImagesUrl
      );

      // console.log("calling updateLabimages response", response);
      if (response.status === 200) {
        localStorage.setItem("lab", JSON.stringify(response?.data?.updatedLab));
        toast({
          title: "lab images updated successfully",
          status: "success",
          duration: "5000",
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error uploading lab images:", error);
      toast({
        title: "error in uploading lab images",
        status: "error",
        duration: "5000",
        isClosable: true,
      });
    } finally {
      setLoading(false);
      setIsSubmit(true);
    }
  };

  useEffect(() => {
    const updatedlab = getLab();
    setLab(updatedlab);
    const newLabUrls = {
      frontPics: updatedlab?.images?.frontPics,
      receptionPics: updatedlab?.images?.receptionPics,
      collectionPics: updatedlab?.images?.collectionPics,
      processingPics: updatedlab?.images?.processingPics,
      instrumentsPics: updatedlab?.images?.instrumentsPics,
      consumablesPics: updatedlab?.images?.consumablesPics,
    };
    setLabImagesUrl(newLabUrls);
    // console.log(
    //   "calling lab and laburls inside useffect",
    //   updatedlab,
    //   newLabUrls
    // );
  }, []);

  const SectionDropzone = ({ section }) => {
    const { getRootProps, getInputProps } = useDropzone({
      accept: {
        "image/png": [".png"],
        "image/jpeg": [".jpeg", ".jpg"],
      },

      multiple: false,
      //   onDrop: (acceptedFiles) => handleDrop(acceptedFiles, section, index),
    });

    return (
      <Box
        width="100%"
        display={"flex"}
        flexDirection={"column"}
        border={"1px solid gray"}
        borderRadius={"12"}
      >
        <Text
          padding={"2"}
          borderBottom={"1px solid gray"}
          textTransform={"capitalize"}
          textAlign={"center"}
        >
          {section?.sectionName}
        </Text>
        <Box
          borderRadius={"12"}
          display={"flex"}
          flexDirection={"column"}
          width={"100%"}
          gap={"5"}
          p={"5"}
        >
          <Grid
            templateColumns="repeat(4, 1fr)"
            width={"100%"}
            alignItems={"center"}
            justifyContent={"space-evenly"}
            flexDir={"row"}
            gap={"3"}
          >
            {labImagesUrl[section?.sectionKey]?.map((imageUrl, index) => (
              <Flex
                key={index}
                height={"130px"}
                width={"130px"}
                border="1px solid gray"
                borderRadius={"12"}
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                position="relative"
                {...getRootProps()}
                onDrop={(e) =>
                  handleDrop(e.dataTransfer.files, section?.sectionKey, index)
                }
              >
                <Input type="file" {...getInputProps()} />
                {imageUrl ? (
                  <Image
                    objectFit={"cover"}
                    key={index}
                    borderRadius={"12"}
                    src={labImagesUrl[section?.sectionKey][index]}
                    alt={`Image ${index + 1}`}
                    style={{ width: "130px", height: "130px" }}
                  />
                ) : (
                  <AddIcon />
                )}
              </Flex>
            ))}
          </Grid>
          <Button
            width={"100%"}
            size={"sm"}
            colorScheme="teal"
            variant={"outline"}
            onClick={() => uploadSectionFile(section?.sectionKey)}
            isLoading={loading}
          >
            Upload
          </Button>
        </Box>
      </Box>
    );
  };

  return (
    <VStack width="100%" alignItems="center" gap={12} py={"5"}>
      <Heading
        color="teal"
        fontSize={[20, 25]}
        width={"100%"}
        textAlign={"left"}
      >
        Image Gallery
      </Heading>
      <Grid
        templateColumns={{
          base: "1fr",
          md: "1fr",
          lg: "1fr",
          xl: "1fr 1fr",
        }}
        justifyContent={{
          base: "center",
          md: "center",
          lg: "center",
          xl: "space-between",
        }}
        gap={"5"}
      >
        <SectionDropzone
          section={{
            sectionKey: "frontPics",
            sectionName: "Lab Front Pics With Dlab Branding (clearly visible) ",
          }}
        />
        <SectionDropzone
          section={{
            sectionKey: "receptionPics",
            sectionName: "Lab Reception Area Pics",
          }}
        />
        <SectionDropzone
          section={{
            sectionKey: "collectionPics",
            sectionName: "Sample Collection Area Pics",
          }}
        />
        <SectionDropzone
          section={{
            sectionKey: "processingPics",
            sectionName: "Sample Processing Area Pics",
          }}
        />
        <SectionDropzone
          section={{
            sectionKey: "instrumentsPics",
            sectionName: "Lab Instruments Pics",
          }}
        />
        <SectionDropzone
          section={{
            sectionKey: "consumablesPics",
            sectionName: "Lab Consumables Pics",
          }}
        />
      </Grid>
      <Button colorScheme="teal" onClick={handleSubmit}>
        Submit Gallery
      </Button>
    </VStack>
  );
};
