import React, { useEffect, useState } from "react";
import {
  Box,
  Heading,
  Text,
  Flex,
  Menu,
  VStack,
  HStack,
  Stack,
  Button,
  Badge,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import { NavBar } from "../components";
import { LineChart } from "../components/Wallet/LineChart";
import { WalletList } from "../components/Wallet/List";
import { AddMoneyModal } from "../components/Modal/Wallet/AddMoneyModal";
import { IconPremiumRights } from "@tabler/icons-react";
import { IconReportMoney } from "@tabler/icons-react";
import { IconCoinMonero } from "@tabler/icons-react";
import { getLab } from "../cookie/lab";
import useOwnStore from "../store/store";
import axios from "axios";
import { AddBankDetails } from "../components/Wallet/AddBankDetails";
import { BankDetailsModal } from "../components/Modal/Wallet/BankDetailsModal";
import { fetchLabOrders } from "../api/order";
import { getTotalPriceForLastWeek } from "./../components/Dashboard/Revenue/Weekly/index";
import { getTotalPriceForLastMonth } from "./../components/Dashboard/Revenue/Monthly/index";

export const Wallet = () => {
  const [transactions, seTransactions] = useState([]);
  const [addMoney, setAddMoney] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editBankDetails, setEditBankDetails] = useState(false);
  const [bankDetails, setBankDetails] = useState(null);
  const [orders, setOrders] = useState([]);
  const [weeklyRevenue, setWeeklyRevenue] = useState();
  const [monthlyRevenue, setMonthlyRevenue] = useState("");

  const toast = useToast();

  const { setLab } = useOwnStore();
  const lab = getLab();

  const fetchLab = async (labId) => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER}/getLabs?id=${labId}`
      );
      localStorage.setItem("lab", JSON.stringify(res.data?.lab));
      setLab(res?.data?.lab);
      seTransactions(res?.data?.lab?.wallet?.transactions);
    } catch (error) {
      console.error("error in getting lab details", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchOrders = async () => {
    setLoading(true);
    try {
      const res = await fetchLabOrders(lab?._id);

      const completedOrders = res?.data?.labOrders?.filter(
        (orderData) => orderData.orderStatus === "completed"
      );

      setOrders(completedOrders);
      setWeeklyRevenue(getTotalPriceForLastWeek(completedOrders));
      setMonthlyRevenue(getTotalPriceForLastMonth(completedOrders));
      // console.log('calling inside customer and showing orders', res)
      // console.log('calling orders +1', orders)
    } catch (error) {
      toast({
        title: "error in fetching the lab details",
        duration: 9000,
        isClosable: true,
        status: 'error',
      });
      // console.log('error in fetching lab details')
    } finally {
      setLoading(false);
    }
  };

  const fetchBankDetails = async (labId) => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER}/bankDetails/${labId}`
      );
      setBankDetails(res?.data?.bankDetails);
    } catch (error) {
      console.error("Error fetching bank details:", error);
    } finally {
      setLoading(false);
    }
  };

  const chartData = {
    labels: [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ],
    datasets: [
      {
        label: "Daily Revenue",
        data: [200, 400, 1200, 500, 600, 700, 300],
        fill: false,
        borderColor: "rgba(220, 134, 12, 1)",
        tension: 0.4,
      },
    ],
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await fetchLab(lab?._id);
      await seTransactions(lab?.wallet?.transactions);
      await fetchBankDetails(lab?._id);
    };

    fetchData();
  }, []);

  return (
    <Box width={"100%"}>
      <NavBar />
      <Flex>
        <Menu />
        <Outlet />
      </Flex>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: "60vh",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spinner size={"lg"} colorScheme="teal" />
        </Box>
      ) : (
        <>
          {bankDetails !== null ? (
            <Box
              textAlign="center"
              px={"32"}
              py={"16"}
              pb={"32"}
              gap={"30"}
              display={"flex"}
              flexDir={"Column"}
              alignItems={"center"}
              justifyContent={"center"}
              width={"100%"}
            >
              <HStack
                width={"100%"}
                justifyContent={"space-between"}
                bg={"#E5F6FB"}
                padding={"20px"}
                borderRadius={"12px"}
              >
                <VStack alignItems={"flex-start"} maxW={"100%"} width={"20%"}>
                  <Heading fontSize={"28px"}>My Wallet</Heading>
                  <Text>Revenue Insights</Text>
                </VStack>
                <HStack width={"100%"} justifyContent={"flex-end"} gap={"10"}>
                  <HStack>
                    <Stack
                      justifyContent={"center"}
                      alignItems={"center"}
                      backgroundColor={"rgba(220, 134, 12, 0.25)"}
                      padding={"10px"}
                      borderRadius={"50%"}
                      h={"100%"}
                    >
                      <IconPremiumRights size={"36px"} color="#DC860C" />
                    </Stack>

                    <VStack alignItems={"flex-start"}>
                      <Heading fontSize={"28px"}>₹ {weeklyRevenue}</Heading>
                      <Text>Weekly Totals</Text>
                    </VStack>
                    <Badge
                      colorScheme="teal"
                      borderRadius={"12px"}
                      paddingY={"5px"}
                      px={"10px"}
                    >
                      + 12%
                    </Badge>
                  </HStack>
                  <HStack>
                    <Stack
                      justifyContent={"center"}
                      alignItems={"center"}
                      backgroundColor={"rgba(43, 199, 4, 0.25)"}
                      padding={"10px"}
                      borderRadius={"50%"}
                      h={"100%"}
                    >
                      <IconCoinMonero
                        size={"36px"}
                        color="rgba(43, 199, 4,1)"
                      />
                    </Stack>
                    <VStack alignItems={"flex-start"}>
                      <Heading fontSize={"28px"}>₹ {monthlyRevenue}</Heading>
                      <Text>Monthly Totals</Text>
                    </VStack>
                    <Badge
                      colorScheme="red"
                      borderRadius={"12px"}
                      paddingY={"5px"}
                      px={"10px"}
                    >
                      + 19%
                    </Badge>
                  </HStack>
                  <HStack>
                    <Stack
                      justifyContent={"center"}
                      alignItems={"center"}
                      backgroundColor={"teal.100"}
                      padding={"10px"}
                      borderRadius={"50%"}
                      h={"100%"}
                    >
                      <IconReportMoney size={"36px"} color="teal" />
                    </Stack>

                    <VStack alignItems={"flex-start"}>
                      <Heading fontSize={"28px"}>₹ 128850</Heading>
                      <Text>Settlement Amount</Text>
                    </VStack>
                    <Badge
                      colorScheme="orange"
                      borderRadius={"12px"}
                      paddingY={"5px"}
                      px={"10px"}
                    >
                      + 15%
                    </Badge>
                  </HStack>
                </HStack>
              </HStack>
              <HStack width={"100%"} gap={"10"}>
                <LineChart
                  chartId={"weekly chart"}
                  chartData={chartData}
                  width={"1200px"}
                  height={"328px"}
                />
                <VStack
                  h={"full"}
                  width={"100%"}
                  boxShadow={"lg"}
                  borderRadius={"12px"}
                  padding={"20px"}
                  gap={"5"}
                >
                  <HStack
                    width={"100%"}
                    background={"rgba(220, 134, 12, 1)"}
                    borderRadius={"12px"}
                    padding={"20px"}
                    justifyContent={"space-between"}
                  >
                    <VStack width={"100%"} alignItems={"flex-start"}>
                      <Heading fontSize={"28px"} color={"white"}>
                        {lab?.wallet?.balance || 0}
                      </Heading>
                      <Text color={"white"}> Current Balance</Text>
                    </VStack>
                    <VStack>
                      <Button
                        color={"teal"}
                        size="sm"
                        onClick={() => setAddMoney(true)}
                      >
                        Add Money
                      </Button>
                    </VStack>
                  </HStack>
                  <VStack
                    width={"100%"}
                    align="flex-start"
                    spacing={4}
                    padding={"20px"}
                    borderRadius={"12px"}
                    bg={"teal"}
                  >
                    <HStack
                      justifyContent={"space-between"}
                      width={"100%"}
                      alignItems={"flex-start"}
                    >
                      <VStack
                        width={"100%"}
                        alignItems={"flex-start"}
                        color={"white"}
                      >
                        <Text>
                          Account Holder: {bankDetails?.accountHolder}
                        </Text>
                        <Text>
                          Account Number: {bankDetails?.accountNumber}
                        </Text>
                        <Text>Bank: {bankDetails?.bankName}</Text>
                        <Text>Branch: {bankDetails?.branchName}</Text>
                      </VStack>
                      <Button
                        color={"teal"}
                        size="sm"
                        onClick={() => setEditBankDetails(true)}
                      >
                        Edit
                      </Button>
                    </HStack>
                  </VStack>
                </VStack>
              </HStack>
              <WalletList transactions={transactions} />
              <AddMoneyModal
                isOpen={addMoney}
                onClose={() => setAddMoney(!addMoney)}
              />
            </Box>
          ) : (
            <AddBankDetails
              bankDetails={bankDetails}
              setBankDetails={setBankDetails}
              labId={lab?._id}
            />
          )}
        </>
      )}
      <BankDetailsModal
        isOpen={editBankDetails}
        onClose={() => setEditBankDetails(false)}
        bankDetails={bankDetails}
        setBankDetails={setBankDetails}
      />
    </Box>
  );
};
