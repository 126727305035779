import { Box, HStack, Heading, Icon, Image, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { AiFillPhone, AiOutlineMail } from "react-icons/ai";
import { FaLocationArrow } from "react-icons/fa";

export const Contact = () => {
	return (
		<VStack spacing={10} py={10}>
			<Heading color={"#0C727E"}>Contact Us</Heading>
			<HStack justifyContent={"space-evenly"} width={["2xm", "5xl"]} display={"flex"}>
				<Image
					src="/assets/landing/Contact.png"
					height={300}
					width={300}
					display={["none", "flex"]}
				/>
				<VStack spacing={5} alignItems={["flex-start"]} px={[8, ""]}>
					<HStack spacing={8}>
						<Icon as={AiFillPhone} fontSize={30} />
						<Box>
							<Heading fontSize={18} fontWeight={500} color={"#0C727E"}>
								Phone
							</Heading>
							<Text fontWeight={500}>+91 73037 01602</Text>
						</Box>
					</HStack>
					<HStack spacing={8}>
						<Icon as={AiOutlineMail} fontSize={30} />
						<Box>
							<Heading fontSize={18} fontWeight={500} color={"#0C727E"}>
								Email
							</Heading>
							<Text fontWeight={500}>dilatelabs@gmail.com</Text>
						</Box>
					</HStack>
					<HStack spacing={8}>
						<Icon as={FaLocationArrow} fontSize={30} />
						<Box>
							<Heading fontSize={18} fontWeight={500} color={"#0C727E"}>
							Dilate Laboratory Pvt Ltd
							</Heading>
							<Text fontWeight={500}>2972 Ranpwill Rd. Maharastra 85486</Text>
						</Box>
					</HStack>
				</VStack>
			</HStack>
		</VStack>
	);
};