import React from "react";
import { Heading, Button } from "@chakra-ui/react";

export const getTotalPriceForToday = (orders) => {
  const currentDate = new Date().toISOString().split("T")[0];

  // Filter orders for today
  const todayOrders = orders.filter(
    (order) => order.createdAt && order.createdAt.split("T")[0] === currentDate
  );

  // Calculate total price for today's orders
  const totalPriceForToday = todayOrders.reduce(
    (total, order) => total + order.paymentDetails?.price,
    0
  );

  return parseInt((75 * totalPriceForToday) / 100);
};

export const TodayRevenue = ({ orders }) => {
  const totalPriceForToday = getTotalPriceForToday(orders);

  return (
    <>
      <Heading color={"teal"}> ₹ {totalPriceForToday}</Heading>
      <Button variant={"ghost"} color={"teal"}>
        Today
      </Button>
    </>
  );
};
