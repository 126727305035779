import {
  Box,
  Button,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Switch,
  Td,
  Text,
  Tr,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { DisabledTest, deleteTest } from "../../../../api/test";
import useOwnStore from "../../../../store/store";

export const TestList = ({ data, fetchData }) => {
  const { lab } = useOwnStore();
  const toast = useToast();
  const [disabled, setDisabled] = useState(data?.disabled?.includes(lab._id));

  const handleChecked = async () => {
    const res = await DisabledTest({ labId: lab?._id, testId: data?._id });
    toast({
      title: "test disabled successfully",
      status: "success",
      duration: 5000,
      isClosable: true,
    });
  };

  const handleDelete = async () => {
    try {
      const res = await deleteTest(data?._id);
      fetchData();
      toast({
        title: "test deleted successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      // console.log(error.message)
    }
  };

  return (
    <Tr
      justifyContent={"space-between"}
      width={"100%"}
      display={"flex"}
      alignItems={"center"}
      transition={"ease-in 0.2s"}
      cursor={"default"}
      _hover={{
        bg: "gray.100",
      }}
    >
      <Td
        sx={{
          width: "30%",
          display: "flex",
          flexDir: "column",
        }}
      >
        <Flex gap={3}>
          <Box sx={{}}>
            <Text color={"#2A2B2C"}>{data?.name}</Text>
            <Text color={"#718096"}>{data?.description.slice(0, 30)}...</Text>
          </Box>
        </Flex>
      </Td>
      <Td></Td>
      <Td isNumeric>₹ {data?.price}</Td>
      <Td isNumeric>{data?.createdAt.split("T")[0]}</Td>
      <Td>
        {data.tag === "basic" ? (
          <Switch
            size="lg"
            colorScheme="teal"
            onChange={(e) => {
              if (e.target.checked) {
                handleChecked();
                setDisabled(true);
              } else {
                setDisabled(false);
                handleChecked();
              }
            }}
            isChecked={disabled}
          />
        ) : (
          <Menu>
            {({ isOpen }) => (
              <>
                <MenuButton>
                  <IconButton
                    isActive={isOpen}
                    as={Button}
                    borderRadius={"50%"}
                    sx={{}}
                  >
                    <BsThreeDotsVertical />
                  </IconButton>
                </MenuButton>
                <MenuList>
                  <MenuItem color={"red"} onClick={handleDelete}>
                    Delete
                  </MenuItem>
                  {/* <MenuItem onClick={() => alert('Kagebunshin')}>Create a Copy</MenuItem> */}
                </MenuList>
              </>
            )}
          </Menu>
        )}
      </Td>
    </Tr>
  );
};
