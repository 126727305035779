import { CheckIcon } from '@chakra-ui/icons';
// import HorizontalRuleIcon from '';
import { Box, IconButton, Stack, Text } from '@chakra-ui/react';
import React from 'react';

export const Steps = ({ form }) => {
  return (
    <Stack
      direction="row"
      gap={['0px', '30px']}
      justifyContent="space-between"
      sx={{ color: 'white' }}
      mt={5}
    >
      {form.steps.map((step, index) => (
        <Box display={'flex'} key={index}>
          <IconButton
            key={index}
            size="large"
            onClick={
              form.currentStep.index > step.index
                ? () => form.goToStep(step.name)
                : null
            }
            sx={{
              borderRadius: '100%',
              border:
                form.currentStep.name === step.name ||
                form.currentStep.index > step.index
                  ? '1px solid teal'
                  : '1px solid teal',
              width: ['45px', '50px'],
              height: ['45px', '50px'],
              cursor: 'pointer',
              background:
                form.currentStep.name === step.name ||
                form.currentStep.index > step.index
                  ? 'teal'
                  : '',
              color:
                form.currentStep.name === step.name ||
                form.currentStep.index > step.index
                  ? '#212121'
                  : 'teal',
            }}
          >
            {form.currentStep.index < step.index || !step.isValid ? (
              <Text> {index + 1}</Text>
            ) : (
              <CheckIcon
                sx={{
                  ml: -1,
                  mr: -1,
                  color: 'white',
                }}
              />
            )}
          </IconButton>
          {/* {index !== 3 && (
            <HorizontalRuleIcon
              sx={{
                display: ['flex', 'none'],
                marginX: '-3px',
                marginTop: '7px',
              }}
            />
          )} */}
        </Box>
      ))}
    </Stack>
  );
};
