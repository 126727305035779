import { Box, Button, Heading, Input, Textarea } from "@chakra-ui/react";
import React from "react";
export const Package = () => {
  return (
    <Box
      sx={{
        display: "flex",
        width: "full",
        height: "88vh",
        flexDir: "column",
        px: 7,
        py: 5,
        gap: 5,
        overflowY: "scroll",
      }}
    >
      <Input defaultValue={"vedant"} fontSize={"3xl"} size={"lg"} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
        }}
      >
        <Heading fontSize={"2xl"}>About</Heading>

        <Textarea
          fontWeight={500}
          fontSize={"lg"}
          defaultValue={
            "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Minima dolorum sit voluptatem ratione dolores mollitia voluptas exercitationem. Commodi similique nulla, atque reprehenderit quasi nam magnam."
          }
        />
        <Heading size={"md"}>Basic</Heading>
        <Box
          sx={{
            display: "flex",
            width: "50%",
            gap: 5,
          }}
        >
          <Input placeholder={"Price"} />
          <Input placeholder={"MRP"} />
        </Box>
        <Heading size={"sm"}>Selected Tests</Heading>
        <Button variant={"ghost"} width={"32"}>
          Add Tests
        </Button>
      </Box>
    </Box>
  );
};
