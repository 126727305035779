import {
  Box,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React from "react";

export const WalletList = ({ transactions }) => {
  return (
    <Box
      width={"100%"}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      boxShadow={"lg"}
      borderRadius={"12px"}
      padding={"20px"}
    >
      <TableContainer width={"100%"}>
        <Table
          variant="simple"
          width="100%"
          p="0"
          css={{ tableLayout: "fixed" }}
        >
          <Thead width="100%" bg={"#E5F6FB"}>
            <Tr width="100%">
              <Th width="25%" color={"teal"}>
                Transaction Type
              </Th>
              <Th width="25%" color={"teal"}>
                Amount
              </Th>
              <Th width="25%" color={"teal"}>
                Date
              </Th>
              <Th width="25%" color={"teal"}>
                Payment Method
              </Th>
              <Th width="25%" color={"teal"}>
                Reference ID
              </Th>
            </Tr>
          </Thead>
          {transactions?.length > 0 ? (
            <Tbody>
              {transactions?.map((t, index) => (
                <Tr key={index} width="100%">
                  <Td width="25%">
                    <Text
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"flex-start"}
                      flexWrap={"wrap"}
                      overflowWrap={"break-word"}
                      whiteSpace={"normal"}
                      maxWidth={"100%"}
                    >
                      {t?.transactionName}
                    </Text>
                  </Td>
                  <Td width="25%">
                    <Text
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"flex-start"}
                      flexWrap={"wrap"}
                      overflowWrap={"break-word"}
                      whiteSpace={"normal"}
                      maxWidth={"100%"}
                    >
                      {t?.amount}
                    </Text>
                  </Td>
                  <Td width="25%">
                    <Text>{t.date.split("T")[0]}</Text>
                  </Td>
                  <Td width="25%">
                    <Text
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"flex-start"}
                      flexWrap={"wrap"}
                      overflowWrap={"break-word"}
                      whiteSpace={"normal"}
                      maxWidth={"100%"}
                    >
                      {t?.paymentMethod}
                    </Text>
                  </Td>
                  <Td>{t.referenceId}</Td>
                </Tr>
              ))}
            </Tbody>
          ) : (
            <Heading width={"100%"} textAlign={"center"} padding={"20px"}>
              No Transaction History
            </Heading>
          )}
        </Table>
      </TableContainer>
    </Box>
  );
};
