import { Box, Text } from "@chakra-ui/react";

export const IconComponent = ({ icon, text }) => {
  return (
    <Box
      sx={{
        display: "flex",
        gap: "8px",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {icon}
      <Text
        sx={{
          fontSize: "12px",
          fontWeight: "600",
        }}
      >
        {text}
      </Text>
    </Box>
  );
};
