import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Input,
  Select,
  HStack,
  Button,
  Stack,
  VStack,
  useToast,
  Avatar,
  Box,
  Text,
} from "@chakra-ui/react";
import useOwnStore from "../../../store/store";
import axios from "axios";
import { getLab } from "../../../cookie/lab";
import { useDropzone } from "react-dropzone";
import { BsImage } from "react-icons/bs";

export const DoctorModal = ({ isOpen, onClose }) => {
  const { setLab } = useOwnStore();
  const [labId, setlabId] = useState();
  const [labData, setLabData] = useState("");
  const [loading, setLoading] = useState(false);

  const toast = useToast();
  const [doctorSignatureUrl, setDoctorSignatureUrl] = useState("");
  const [acceptedFiles, setAcceptedFiles] = useState([]);

  const [showOtherDetails, setShowOtherDetails] = useState(false);
  const [otherDetails, setOtherDetails] = useState("");

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpeg", ".jpg"],
    },
    multiple: false,
    onDrop: (accepted) => {
      setAcceptedFiles(accepted);
      fileHandler(accepted);
    },
  });

  const fileHandler = async (accepted) => {
    const file = accepted[0];
    // console.log('calling accepted in file hanlder', accepted, file);
    setDoctorSignatureUrl(URL.createObjectURL(file));
    // console.log('calling file url locally', URL.createObjectURL(file));
    try {
      setLoading(true);
      const data = new FormData();
      data?.append("file", file);
      data?.append("upload_preset", "vv0rqrrj");
      data?.append("cloud_name", "ddqlrpysr");

      const response = await axios.post(
        "https://api.cloudinary.com/v1_1/ddqlrpysr/image/upload",
        data
      );

      // console.log("calling cloudinary res", response);
      if (response?.status === 200) {
        const imageUrl = response?.data?.secure_url;
        setDoctorSignatureUrl(imageUrl);
        setLabData({ ...labData, doctorSignatureUrl: imageUrl });
      }
    } catch (error) {
      console.error("Error uploading signature image:", error);
      toast({
        title: "error in uploading signature to cloudinary",
        status: error,
        isClosable: true,
        duration: 9000,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (event) => {
    setLabData({ ...labData, [event.target.name]: event.target.value });
  };
  const handleOtherDetails = (event) => {
    setOtherDetails(event.target.value);
    setLabData({ ...labData, designation: event.target.value });
  };

  const handleDesignationChange = (event) => {
    const selectedDesignation = event.target.value;
    handleChange(event);

    if (selectedDesignation === "Other") {
      setShowOtherDetails(true);
    } else {
      setShowOtherDetails(false);
      setOtherDetails("");
      // Set the designation in labData when a non-"Other" option is selected
      setLabData({ ...labData, designation: selectedDesignation });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // if other is selected , set the designation as other
    if (labData?.designation === "Other") {
      setLabData({ ...labData, designation: otherDetails });
    }
    try {
      setLoading(true);
      const res = await axios.put(
        `${process.env.REACT_APP_SERVER}/updateLabDoctor/${labId}`,
        labData
      );
      // console.log("calling labData before updating ", labData);
      // console.log("calling responses", res);

      setLabData(res?.data?.updatedLab);
      setLab(res?.data?.updatedLab);
      localStorage.setItem("lab", JSON.stringify(res?.data?.updatedLab));
      toast({
        title: "Doctor details updated successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      onClose();
    } catch (error) {
      toast({
        title: "error while lab logo and doctor details",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const lab = getLab();

    setlabId(lab?._id);

    const labDetails = {
      doctorName: lab.doctorName,
      specialisation: lab?.specialisation,
      designation: lab?.designation,
      doctorSignatureUrl: lab?.doctorSignatureUrl,
    };

    setLabData(labDetails);
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"4xl"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader display={"flex"} justifyContent={"center"} fontSize={"30"}>
          Upload Lab Logo and Doctor Signature
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack width={"100%"} gap={"5"}>
            <Stack
              width={"100%"}
              flexDirection={["column", "row"]}
              gap={"5"}
              justifyContent={"center"}
            >
              <VStack
                {...getRootProps()}
                width="220px"
                height="220px"
                border="2px dashed"
                borderColor="gray.200"
                borderRadius="md"
                justifyContent="center"
                alignItems="center"
                cursor="pointer"
                fontSize={"normal"}
                padding={"5"}
              >
                <Box
                  width={"100%"}
                  padding={"5"}
                  flexDirection={"column"}
                  display={"flex"}
                  justifyContent="center"
                  alignItems={"center"}
                  gap={"5"}
                >
                  {labData?.DoctorSignatureUrl ? (
                    <Avatar src={labData?.signatureUrl} size={"xl"} />
                  ) : (
                    <>
                      <BsImage width={"100%"} height={"100%"} size={"50px"} />
                      <Text
                        textAlign="center"
                        width={"100%"}
                        alignItems={"center"}
                      >
                        Drag & drop a signature here or click to select one
                      </Text>
                    </>
                  )}
                </Box>
                <Input type="file" {...getInputProps()} />
              </VStack>
            </Stack>
            <Stack width={"100%"} flexDirection={["column", "row"]} gap={"5"}>
              <Input
                type="text"
                name="doctorName"
                value={labData?.doctorName}
                onChange={handleChange}
                focusBorderColor="teal.500"
                placeholder="Doctor Name"
              />
              <Input
                type="text"
                name="specialisation"
                value={labData?.specialisation}
                onChange={handleChange}
                focusBorderColor="teal.500"
                placeholder="Specialisation"
              />

              <Select
                name="designation"
                value={labData?.designation}
                onChange={handleDesignationChange}
                placeholder="Designation"
                focusBorderColor="teal.500"
              >
                <option value="Bio Chemist">Bio Chemist</option>
                <option value="Micro Biologist">Micro Biologist</option>
                <option value="Hametology">Hametology</option>
                <option value="Clinical Pathology">Pathology</option>
                <option value="Other">Other</option>
              </Select>
            </Stack>
            {showOtherDetails && (
              <Input
                type="text"
                name="otherDetails"
                value={otherDetails}
                onChange={(e) => handleOtherDetails(e)}
                focusBorderColor="teal.500"
                placeholder="Enter other details"
              />
            )}
            <HStack py={"5"} justifyContent={"center"} width={"100%"}>
              <Button
                colorScheme="teal"
                type="submit"
                onClick={handleSubmit}
                width={"20%"}
                isLoading={loading}
              >
                Update Details
              </Button>
              <Button variant="solid" onClick={onClose}>
                Cancel
              </Button>
            </HStack>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
