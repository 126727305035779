import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  HStack,
  Heading,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Textarea,
  VStack,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import useOwnStore from "../../../../store/store";
import axios from "axios";

export const TestCard = ({ test }) => {
  const [isOpen, setIsOpen] = useState(false);

  const { lab } = useOwnStore();
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const [testData, setTestData] = React.useState({
    name: test?.name,
    price: test?.price,
    description: test?.description,
    suitableFor: test?.suitableFor,
    labId: lab._id,
  });

  const handleSubmit = async () => {
   
    setTestData((prevTest) => ({
      ...prevTest,
      labId: lab._id,
    }));
    try {
      setLoading(true);
      const res = await axios.put(
        `${process.env.REACT_APP_SERVER}/updateLabTest/${test?._id}`,
        testData
      );
      // console.log("calling testDat before updating ", testData);
      setTestData(res?.data?.updatedTest);
      toast({
        title: "lab test updated successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      onClose();
    } catch (error) {
      toast({
        title: "error while updating lab test",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  function onClose() {
    setIsOpen(false);
  }


  return (
    <>
      <Card
        width={"100%"}
        maxW={"md"}
        height={["80"]}
        px={[1, 3]}
        py={1}
        borderRadius={8}
        variant={"outline"}
        transition={"ease-in-out 0.3s"}
        border={"1px solid black"}
        _hover={{
          boxShadow: "0px 4px 5px 0px rgba(42, 43, 44, 0.50)",
        }}
      >
        <CardBody>
          <Stack gap={3}>
            <Heading fontSize={[20, 25]} _hover={{ cursor: "pointer" }}>
              {testData?.name}
            </Heading>
            <Text fontSize={["md", "md"]}>
              {testData?.description?.slice(0, 250)}
              {testData?.description?.length > 250 && "..."}
            </Text>
          </Stack>
        </CardBody>
        <CardFooter
          justify="space-between"
          flexWrap="wrap"
          sx={{
            "& > button": {
              minW: "136px",
            },
          }}
        >
          <HStack width={"full"} justifyContent={"space-between"}>
            <Box>
              <Heading fontSize={[15, 18]} color={"#1DC2D5"}>
                ₹ {testData?.price}
              </Heading>
            </Box>

            <Button
              bg={"#0C727E"}
              color={"#fff"}
              _hover={{}}
              onClick={() => setIsOpen(true)}
            >
              Edit Tests
            </Button>
          </HStack>
        </CardFooter>
      </Card>
      <Modal isOpen={isOpen} onClose={onClose} size={"4xl"} sx={{}}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader></ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <HStack alignItems={"flex-start"} spacing={5}>
              <VStack flex={1} p={5} display={["none", "flex"]}>
                <Image src={"/assets/signup/Signup.png"} width={"8xl"} />
              </VStack>
              <VStack flex={1} p={[1, 5]} spacing={7}>
                <Heading>Update Test</Heading>
                <Stack
                  sx={{
                    display: "flex",
                    flexDir: "column",
                    gap: "3",
                    width: "100%",
                  }}
                >
                  <Input
                    placeholder="Enter Name"
                    size={"lg"}
                    value={testData?.name}
                    onChange={(e) =>
                      setTestData((prevTest) => ({
                        ...prevTest,
                        name: e.target.value,
                      }))
                    }
                  />
                  <Input
                    placeholder="Enter Price"
                    size={"lg"}
                    value={testData?.price}
                    onChange={(e) =>
                      setTestData((prevTest) => ({
                        ...prevTest,
                        price: e.target.value,
                      }))
                    }
                  />
                  <Input
                    placeholder="Enter Suitable For"
                    size={"lg"}
                    value={testData?.suitableFor}
                    onChange={(e) =>
                      setTestData((prevTest) => ({
                        ...prevTest,
                        suitableFor: e.target.value,
                      }))
                    }
                  />
                  <Textarea
                    placeholder="Enter Description"
                    size={"lg"}
                    value={testData?.description}
                    onChange={(e) =>
                      setTestData((prevTest) => ({
                        ...prevTest,
                        description: e.target.value,
                      }))
                    }
                  />
                </Stack>
                <Button
                  width={"full"}
                  isLoading={loading}
                  variant={"solid"}
                  colorScheme="teal"
                  onClick={handleSubmit}
                >
                  Update
                </Button>
              </VStack>
            </HStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
