import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  HStack,
  Heading,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { CreateCustomerModal } from "../../Modal/CreateCustomer";
import { fetchLabOrders } from "../../../api/order";
import { ListElement } from "./List";
import { getLab } from "../../../cookie/lab";

export const Customer = () => {
  const [open, setOpen] = React.useState(false);
  const lab = getLab();
  const [orders, setOrders] = useState([]);
  const [displayedOrders, setDisplayedOrders] = useState([]);
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [onlineOrders, setOnlineOrders] = useState([]);
  const [walkInOrders, setWalkInOrders] = useState([]);

  const fetchOrders = async () => {
    setLoading(true);
    try {
      if (lab?._id) {
        const res = await fetchLabOrders(lab?._id);
        setOrders(() => res?.data?.labOrders?.reverse());
        // console.log(
        //   "calling displayed orders after setting in fetch",
        //   displayedOrders
        // );
        // console.log("calling inside customer and showing orders", res);
        // console.log("calling orders +1", orders);
      }
    } catch (error) {
      toast({
        title: "error in fetching the lab details",
        duration: 9000,
        isClosable: true,
        status: 'error',
      });
      // console.log("error in fetching lab details");
    } finally {
      setLoading(false);
    }
  };

  // Handlers for filtering orders based on sample mode
  const onlineHandler = () => {
    setDisplayedOrders(onlineOrders);
  };

  const walkInHandler = () => {
    setDisplayedOrders(walkInOrders);
  };

  const allOrderHandler = () => {
    setDisplayedOrders(orders);
  };

  useEffect(() => {
    setDisplayedOrders(orders);

    const onlineOrdersResult = orders.filter(
      (order) => order?.sampleMode === "home"
    );

    setOnlineOrders(onlineOrdersResult);
    const walkInOrdersResult = orders.filter(
      (order) => order?.sampleMode === "lab"
    );
    setWalkInOrders(walkInOrdersResult);
  }, [orders]);
  useEffect(() => {
    fetchOrders();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        width: "full",
        height: "87vh",
        flexDir: "column",
        px: 7,
        py: 5,
        gap: 5,
        overflowY: "scroll",
        marginLeft: "64",
      }}
    >
      <HStack height={"60"} py={5} px={4} justifyContent={"space-evenly"}>
        <Card
          size={"lg"}
          sx={{
            display: "flex",
            height: "100%",
            border: "1px solid black",
          }}
        >
          <CardBody
            sx={{
              gap: 2,
              display: "flex",
              flexDir: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Heading color={"#0C727E"}>{onlineOrders?.length}</Heading>
            <Text fontSize={"xl"} fontWeight={500}>
              Online Appointments
            </Text>
            <Button variant={"ghost"} color={"#1DC2D5"} onClick={onlineHandler}>
              Check it
            </Button>
          </CardBody>
        </Card>
        <Card
          size={"lg"}
          sx={{
            display: "flex",
            border: "1px solid black",
            height: "100%",
          }}
        >
          <CardBody
            sx={{
              gap: 2,
              display: "flex",
              flexDir: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Heading color={"#0C727E"}>{walkInOrders?.length}</Heading>
            <Text fontSize={"xl"} fontWeight={500}>
              Walk In Customer Appointments
            </Text>
            <Button variant={"ghost"} color={"#1DC2D5"} onClick={walkInHandler}>
              Check it
            </Button>
          </CardBody>
        </Card>
        <Card
          size={"lg"}
          sx={{
            display: "flex",
            width: "",
            height: "100%",
            border: "1px solid black",
          }}
        >
          <CardBody
            sx={{
              gap: 2,
              display: "flex",
              flexDir: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Heading color={"#0C727E"}>{orders?.length}</Heading>
            <Text fontSize={"xl"} fontWeight={500}>
              All Customers Appointments
            </Text>
            <Button
              variant={"ghost"}
              color={"#1DC2D5"}
              onClick={allOrderHandler}
            >
              Check it
            </Button>
          </CardBody>
        </Card>
      </HStack>
      <VStack
        sx={{
          width: "full",
          px: 7,
          py: 5,
          gap: 4,
        }}
      >
        <Flex
          sx={{
            width: "full",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingX: "8",
          }}
        >
          <Heading
            color={"teal"}
            fontSize={18}
            _hover={{ cursor: "pointer", textDecoration: "underline" }}
          >
            All Customer
          </Heading>
          <Button>Date</Button>{" "}
        </Flex>
        <Flex width={"full"} justifyContent={"center"}>
          <TableContainer>
            <Table
              variant="simple"
              width={"72vw"}
              display={"flex"}
              flexDir={"column"}
              justifyItems={"space-between"}
            >
              <Thead bg={"teal.300"}>
                <Tr
                  justifyContent={"space-between"}
                  width={"100%"}
                  display={"flex"}
                  color={"#1D5563"}
                >
                  <Th>Appointments</Th>
                  <Th ml={"8"}>Price</Th>
                  <Th isNumeric>Ordered date</Th>
                  <Th isNumeric>mode</Th>
                  <Th>Report</Th>
                </Tr>
              </Thead>
              {loading ? (
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    height: "60vh",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Spinner size={"lg"} />
                </Box>
              ) : (
                <Tbody paddingX={"0"}>
                  {displayedOrders?.map((e, i) => {
                    return (
                      <ListElement
                        key={i}
                        orderDetails={e}
                        orders={orders}
                        setLoading={setLoading}
                      />
                    );
                  })}
                </Tbody>
              )}
            </Table>
          </TableContainer>
        </Flex>
      </VStack>
      <CreateCustomerModal open={open} setOpen={setOpen} />
    </Box>
  );
};
