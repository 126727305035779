import { Heading, Text, VStack } from "@chakra-ui/react"

export const WhyDPath = () => {
  return (
    <VStack
      p={10}
      spacing={5}
      height={"sm"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Heading color="teal" fontSize={25}>
        Why Join DLabs??
      </Heading>
      <Text w={["full", 850]} textAlign={"center"} fontSize={18}>
        D Labs provides laboratories with an array of benefits including new
        customer acquisition strategies, comprehensive laboratory support
        covering operations, quality, and accreditation, as well as training and
        controls to enhance efficiency and customer experience. The eligibility
        criteria are straightforward, making it accessible for laboratories with
        adequate space to become a part of D Labs' network.
      </Text>
    </VStack>
  )
}
