import {
  Button,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineUser } from "react-icons/ai";
import { IoWalletOutline } from "react-icons/io5";
import { IoIosLogOut } from "react-icons/io";

import React from "react";
import { MdOutlineDashboard } from "react-icons/md";
import useOwnStore from "../../../store/store";
import { SignupModal } from "../../Modal/SignupModal";

const NAVIGATIONS = [
  {
    text: "About Us",
    type: "ghost",
    url: "/aboutus",
  },
  {
    text: "Contact Us",
    type: "ghost",
    url: "/contactus",
  },

  {
    text: "Sign Up",
    type: "outline",
  },
  {
    text: "Sign In",
    type: "solid",
  },
];

export const Navigations = ({ setOpen }) => {
  const { setSignin, signup, signin, setSignup, lab, setLab } = useOwnStore();
  const navigate = useNavigate();
  // console.log("calling signup state in navigation", signup, signin);

  return (
    <>
      <HStack>
        {NAVIGATIONS.slice(0, 2).map((element, i) => (
          <Button
            key={i}
            colorScheme="teal"
            variant={element?.type}
            display={["none", "flex"]}
          >
            <Link to={`${element?.url}`}>{element?.text}</Link>
          </Button>
        ))}
        {lab === null ? (
          <>
            <Button
              colorScheme="teal"
              variant={"outline"}
              size={["sm", "md"]}
              onClick={() => setSignup(true)}
            >
              Sign Up
            </Button>
            <Button
              colorScheme="teal"
              variant={"solid"}
              size={["sm", "md"]}
              onClick={() => setSignin(true)}
            >
              Sign In
            </Button>
          </>
        ) : (
          <>
            <IconButton
              colorScheme="teal"
              variant={"ghost"}
              display={["none", "flex"]}
              onClick={() => navigate("/mywallet")}
              _hover={{}}
              p={1}
              borderRadius={"50px"}
            >
              <IoWalletOutline size={"20px"} />
            </IconButton>

            <Menu>
              <MenuButton
                as={Button}
                variant={"ghost"}
                rightIcon={<AiOutlineUser />}
                sx={{ borderRadius: "100%", width: "50px", height: "50px" }}
              >
                <AiOutlineUser color="teal" />
              </MenuButton>
              <MenuList>
                <MenuItem
                  onClick={() => {
                    navigate("/dashboard");
                  }}
                  gap={2}
                >
                  <MdOutlineDashboard size={"23px"} />
                  <Text mt={1.5}>Dashboard</Text>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    localStorage.clear();
                    setLab(null);
                    navigate("/");
                  }}
                  gap={2}
                >
                  <IoIosLogOut size={"23px"} />
                  <Text mt={1.5}>Logout</Text>
                </MenuItem>
              </MenuList>
            </Menu>
          </>
        )}
      </HStack>
      {signup && <SignupModal />}
    </>
  );
};
