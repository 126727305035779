import { Button, Flex, VStack } from "@chakra-ui/react";
import React from "react";
import { MdOutlineDashboard, MdAttachMoney } from "react-icons/md";
import { IoPeopleSharp } from "react-icons/io5";
import { AiOutlineShoppingCart, AiOutlineUser } from "react-icons/ai";
import { TbTestPipe2 } from "react-icons/tb";
import { GiHealthNormal } from "react-icons/gi";

import { BiHelpCircle } from "react-icons/bi";
import { useLocation, useNavigate } from "react-router-dom";
import { IconBasket, IconDeviceTabletCog } from "@tabler/icons-react";

export const Menu = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const menu = [
    {
      title: "Dashboard",
      link: "/dashboard",
      icon: <MdOutlineDashboard />,
    },
    {
      title: "Profile",
      link: "/dashboard/profile",
      icon: <AiOutlineUser />,
    },
    {
      title: "Customer",
      link: "/dashboard/customer",
      icon: <IoPeopleSharp />,
    },
    {
      title: "Revenue",
      link: "/dashboard/revenue",
      icon: <MdAttachMoney />,
    },
    {
      title: "Orders",
      link: "/dashboard/orders",
      icon: <AiOutlineShoppingCart />,
    },
    {
      title: "Test Available",
      link: "/dashboard/tests",
      icon: <TbTestPipe2 />,
    },
    {
      title: "Health Packages",
      link: "/dashboard/packages",
      icon: <GiHealthNormal />,
    },
    {
      title: "Training",
      link: "/dashboard/training",
      icon: <IconDeviceTabletCog />,
    },
    {
      title: "Inventory",
      link: "/dashboard/inventory",
      icon: <IconBasket />,
    },
    // {
    //   title: "Help & Support",
    //   link: "/dashboard/help",
    //   icon: <BiHelpCircle />,
    // },
  ];
  return (
    <Flex
      bg={"#E5F6FB"}
      height={"full"}
      display={location.pathname === "/" ? "none" : "flex"}
      position="fixed"
      px={"3"}
      py={"3"}
      top={"13vh"}
      zIndex={99}
      width={"260px"}
      overflowY={"scroll"}
    >
      <VStack
        alignItems={"center"}
        width={"64"}
        spacing={"5"}
        justifyContent={"flex-start"}
        overflowY={"scroll"}
      >
        {menu.map((r, i) => {
          return (
            <Button
              variant="ghost"
              key={i}
              borderRadius={50}
              px={10}
              py={6}
              gap={2}
              justifyContent={"flex-start"}
              width={"56"}
              fontWeight={600}
              transition={"all 300ms"}
              onClick={() => navigate(r.link)}
              color={location.pathname === `${r.link}` ? "#1DC2D5" : "black"}
              _hover={{
                color: "#1DC2D5",
              }}
            >
              {r.icon}
              {r.title}
            </Button>
          );
        })}
      </VStack>
    </Flex>
  );
};
