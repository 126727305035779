import React, { useRef, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { getLab } from "../../../../../cookie/lab";
import { DLabsBanner } from "../DlabBranding";
import Barcode from "react-barcode";
import { useToast } from "@chakra-ui/react";
import axios from "axios";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

export const calculateAge = (birthdate) => {
  const birthdateObj = new Date(birthdate);
  const currentDate = new Date();

  const age = currentDate.getFullYear() - birthdateObj.getFullYear();

  if (
    currentDate.getMonth() < birthdateObj.getMonth() ||
    (currentDate.getMonth() === birthdateObj.getMonth() &&
      currentDate.getDate() < birthdateObj.getDate())
  ) {
    return age - 1;
  } else {
    return age;
  }
};

export const getFormattedDate = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = `0${date.getMonth() + 1}`.slice(-2);
  const day = `0${date.getDate()}`.slice(-2);

  return `${day}-${month}-${year}`;
};

export const ReportCard = ({
  Open,
  setOpen,
  reportParameters,
  order,
  setOrder,
  patient,
}) => {
  const reportRef = useRef();
  const lab = getLab();
  const [loading, setLoading] = useState(false);
  const [reportUrl, setReportUrl] = useState("");
  const toast = useToast();

  const downloadPDF = async () => {
    try {
      const input = reportRef.current;
      setLoading(true);

      const pdf = new jsPDF({ unit: "mm", format: "a4" });

      // Convert canvas to image data
      const canvas = await html2canvas(input);

      // console.log("calling canvas", canvas);
      const imgData = canvas.toDataURL("image/png");

      // Add image to PDF
      pdf.addImage(imgData, "PNG", 0, 0, 210, 297);

      // Convert canvas to Blob directly
      const canvasBlob = await new Promise((resolve) => canvas.toBlob(resolve));
      // console.log("calling pdf blob data", canvasBlob);

      const data = new FormData();
      data.append("file", canvasBlob, "report.pdf");
      data.append("upload_preset", "vv0rqrrj");
      data.append("cloud_name", "ddqlrpysr");

      const cloudinaryRes = await fetch(
        "https://api.cloudinary.com/v1_1/ddqlrpysr/upload",
        {
          method: "POST",
          body: data,
        }
      );

      if (!cloudinaryRes.ok) {
        throw new Error("Cloudinary upload failed");
      }

      // console.log("calling cloudinary response", cloudinaryRes);
      const cloudinaryData = await cloudinaryRes.json();
      const cloudinaryUrl = cloudinaryData.secure_url;

      setReportUrl(cloudinaryUrl);
      toast({
        title: "Report uploaded successfully",
        duration: 9000,
        status: "success",
        isClosable: true,
      });

      const response = await axios.put(
        `${process.env.REACT_APP_SERVER}/labs/orders/complete/${order?._id}`,
        { reportUrl: cloudinaryUrl }
      );

      setOrder(response?.data?.order);
      // console.log("calling complete order response", response);

      if (response?.status === 200) {
        setOpen(false);
        toast({
          title: "order completed successfully",
          duration: 9000,
          status: "success",
          isClosable: true,
        });
      }
    } catch (error) {
      console.error(error);
      toast({
        title: error?.message || "Error uploading report",
        duration: 9000,
        status: "error",
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={Open} onClose={() => setOpen(false)} size={"6xl"}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody p={"40px"}>
          <Flex
            ref={reportRef}
            width={"100%"}
            justifyContent={"flex-start"}
            alignItems={"center"}
            flexDir={"column"}
            gap={"5"}
            p={"0px"}
          >
            <DLabsBanner />
            <Grid
              templateColumns={["1fr 1fr 1fr 1fr"]}
              width={"100%"}
              borderY={"2px solid black"}
              py={"5"}
              px={"32px"}
            >
              <Box alignItems={"flex-start"}>
                <Text fontWeight={"bold"} fontSize={"12px"}>
                  Patient Name
                </Text>
                <Text fontWeight={"bold"} fontSize={"12px"}>
                  Age/Gender
                </Text>
                <Text fontWeight={"bold"} fontSize={"12px"}>
                  OrderId
                </Text>
                <Text fontWeight={"bold"} fontSize={"12px"}>
                  Referred By
                </Text>
              </Box>
              <Box alignItems={"flex-start"}>
                <Text fontSize={"12px"}>: {patient?.name}</Text>
                <Text fontSize={"12px"}>
                  {`     : ${calculateAge(patient?.birthdate)}
                    years / ${patient?.gender}`}
                </Text>
                <Text fontSize={"12px"}>: {order?._id}</Text>
                <Text fontSize={"12px"}>: {patient?.tag}</Text>
              </Box>
              <Box alignItems={"flex-start"}>
                <Text fontWeight={"bold"} fontSize={"12px"}>
                  Sample Mode
                </Text>
                <Text fontWeight={"bold"} fontSize={"12px"}>
                  Sample Collected On
                </Text>
                <Text fontWeight={"bold"} fontSize={"12px"} mb={"5"}>
                  Report Generated
                </Text>
                <Text fontWeight={"bold"} fontSize={"12px"}>
                  Barcode
                </Text>
              </Box>
              <Box alignItems={"flex-start"}>
                <Text fontSize={"12px"}>: {order?.sampleMode} collection</Text>
                <Text fontSize={"12px"}>
                  : {getFormattedDate(order?.createdAt.split("T")[0])}
                </Text>
                <Text fontSize={"12px"} mb={"5"}>
                  : {getFormattedDate(new Date())}
                </Text>
                <Barcode
                  value={order?.barcodeData?.toUpperCase()}
                  format="CODE128"
                  background="#ffffff"
                  lineColor="#000000"
                  height={16}
                  width={1}
                />
              </Box>
            </Grid>
            <Heading fontSize={"25px"} width={"100%"} textAlign={"center"}>
              {order?.name}
            </Heading>
            <TableContainer width={"100%"}>
              <Table
                variant="simple"
                width="100%"
                p="0"
                css={{ tableLayout: "fixed" }}
              >
                <TableCaption>
                  <Text
                    fontWeight={"bold"}
                    textAlign={"center"}
                    color={"black"}
                  >
                    Report Prepared by {lab?.labName}
                  </Text>
                </TableCaption>
                <Thead
                  width="100%"
                  bg={"teal.400"}
                  alignItems={"center"}
                  verticalAlign={"center"}
                >
                  <Tr width="100%">
                    <Th
                      width="25%"
                      verticalAlign={"center"}
                      color={"white"}
                      fontSize={"12px"}
                    >
                      Test Parameter
                    </Th>
                    <Th
                      width="25%"
                      verticalAlign={"center"}
                      color={"white"}
                      fontSize={"12px"}
                    >
                      Value
                    </Th>
                    <Th
                      width="25%"
                      verticalAlign={"center"}
                      color={"white"}
                      fontSize={"12px"}
                    >
                      Unit
                    </Th>
                    <Th
                      width="25%"
                      verticalAlign={"center"}
                      color={"white"}
                      fontSize={"12px"}
                    >
                      Referral Range
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {reportParameters?.map((p, index) => {
                    return (
                      <Tr key={index}>
                        <Td fontSize={"12px"}>
                          <Text
                            display={"flex"}
                            alignItems={"center"}
                            justifyContent={"flex-start"}
                            flexWrap={"wrap"}
                            overflowWrap={"break-word"}
                            whiteSpace={"normal"}
                            maxWidth={"100%"}
                          >
                            {p?.name}
                          </Text>
                        </Td>
                        <Td fontSize={"12px"}>
                          <Text>{p.value}</Text>
                        </Td>
                        <Td fontSize={"12px"}>
                          <Text>{p?.unit}</Text>
                        </Td>
                        <Td
                          fontSize={"12px"}
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"flex-start"}
                          flexWrap={"wrap"}
                          overflowWrap={"break-word"}
                          whiteSpace={"normal"}
                          maxWidth={"100%"}
                        >
                          {p?.referralRange}
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
                <Tfoot width={"100%"}></Tfoot>
              </Table>
            </TableContainer>
          </Flex>
          <Box
            display={"flex"}
            width={"100%"}
            justifyContent={"center"}
            py={"5"}
          >
            <Button
              colorScheme="teal"
              onClick={downloadPDF}
              isLoading={loading}
            >
              Generate Report
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
