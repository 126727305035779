import { Flex, useToast } from "@chakra-ui/react";
import { FormizStep } from "@formiz/core";
import React from "react";
import { TextField } from "../../../Inputs/TextField";

export const Step2 = ({ form }) => {
  const toast = useToast();

  return (
    <>
      <FormizStep
        as={"Box"}
        sx={{
          display: "flex",
          width: "full",
          flexDirection: "column",
          gap: 5,
          justifyContent: "start",
          alignItems: "center",
        }}
        component="form"
        label="Step 2"
        name="step2"
      >
        <Flex flexDirection={"column"} gap={3} width={"100%"}>
          <Flex gap={3}>
            <TextField
              disabled={false}
              id={"text"}
              name={"country"}
              type={"select"}
              placeholder="Country*"
            />
            <TextField
              disabled={false}
              name={"state"}
              type={"select"}
              placeholder="State*"
            />
          </Flex>
          <Flex gap={3}>
            <TextField
              disabled={false}
              id={"text"}
              name={"district"}
              type={"select"}
              placeholder="District*"
            />
            <TextField
              disabled={false}
              id={"text"}
              name={"pincode"}
              type={"number"}
              placeholder="Pin Code*"
            />
          </Flex>
          <TextField
            disabled={false}
            id={"Address"}
            name={"address"}
            placeholder="Address*"
          />
        </Flex>
      </FormizStep>
    </>
  );
};
