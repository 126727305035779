import React, { useEffect } from "react";
import { Box } from "@chakra-ui/react";
import Chart from "chart.js/auto";

export const LineChart = ({ chartId, chartData, width, height }) => {
  useEffect(() => {
    let chartInstance = null;

    // Create the chart when the component mounts
    const createChart = () => {
      const canvas = document.getElementById(chartId);

      if (canvas) {
        canvas.width = width || canvas.width;
        canvas.height = height || canvas.height;

        const ctx = canvas.getContext("2d");
        chartInstance = new Chart(ctx, {
          type: "line",
          data: chartData,
          options: {
            scales: {
              x: {
                grid: {
                  display: false,
                },
              },
              y: {
                beginAtZero: true,
                grid: {
                  display: true,
                },
              },
            },
          },
        });
      }
    };

    createChart();

    // Destroy the chart when the component is unmounted
    return () => {
      if (chartInstance) {
        chartInstance.destroy();
      }
    };
  }, [chartId, chartData, width, height]);

  return (
    <Box
      width={width || "100%"}
      height={height || "auto"}
      boxShadow={"lg"}
      padding={"10px"}
      borderRadius={"12px"}
    >
      <canvas id={chartId}></canvas>
    </Box>
  );
};
