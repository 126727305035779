import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
  VStack,
  useToast,
  Spinner,
  Grid,
  useBreakpointValue,
} from "@chakra-ui/react";
import React from "react";
import { ListElement } from "./List";
import { useEffect, useState } from "react";
import { fetchLabOrders } from "../../../api/order";
import { Yesterday } from "./Yesterday/index";
import { Weekly } from "./Weekly/index";
import { Monthly } from "./Monthly/index";
import { Half } from "./Half/index";
import { FullYear } from "./FullYear/index";
import { Total } from "./Total/index";
import { Quarterly } from "./Quarterly/index";
import { TodayRevenue } from "./Today/index";
import { getLab } from "../../../cookie/lab";

export const Revenue = () => {
  const lab = getLab();
  const [orders, setOrders] = useState([]);
  const toast = useToast();
  const [loading, setLoading] = useState(false);

  const cardColumns = useBreakpointValue({
    base: 1,
    sm: 2,
    md: 3,
    lg: 4,
    xl: 4,
  });

  const fetchOrders = async () => {
    setLoading(true);
    try {
      const res = await fetchLabOrders(lab?._id);

      setOrders(() => res.data.labOrders?.reverse());
      // console.log('calling inside customer and showing orders', res)
      // console.log('calling orders +1', orders)
    } catch (error) {
      toast({
        title: "error in fetching the lab details",
        duration: 9000,
        isClosable: true,
        status: 'error',
      });
      // console.log('error in fetching lab details')
    } finally {
      setLoading(false);
    }
  };
  const completedOrders = orders.filter(
    (orderData) => orderData.orderStatus === "completed"
  );

  useEffect(() => {
    fetchOrders();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        px: 7,
        py: 5,
        gap: 5,
        overflowY: "scroll",
        marginLeft: "64",
        alignItems: "center",
        width: "100%",
        h: "87vh",
      }}
    >
      <Grid
        justifyContent={"space-around"}
        alignSelf={"center"}
        templateColumns={`repeat(auto-fill, minmax(calc(100% / ${cardColumns}), 1fr))`}
        width={"100%"}
        rowGap={"6"}
        paddingX={"12"}
        paddingY={"6"}
      >
        <Card
          size={"lg"}
          sx={{
            display: "flex",
            width: "80%",
            border: "1px solid black",
            height: "100%",
          }}
        >
          <CardBody
            sx={{
              display: "flex",
              flexDir: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TodayRevenue orders={completedOrders?.reverse()} />
          </CardBody>
        </Card>
        <Card
          size={"lg"}
          sx={{
            display: "flex",
            width: "80%",
            border: "1px solid black",
            height: "100%",
          }}
        >
          <CardBody
            sx={{
              display: "flex",
              flexDir: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Yesterday orders={completedOrders} />
          </CardBody>
        </Card>
        <Card
          size={"lg"}
          sx={{
            display: "flex",
            width: "80%",
            height: "100%",
            border: "1px solid black",
          }}
        >
          <CardBody
            sx={{
              display: "flex",
              flexDir: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Weekly orders={completedOrders} />
          </CardBody>
        </Card>
        <Card
          size={"lg"}
          sx={{
            display: "flex",
            width: "80%",
            height: "100%",
            border: "1px solid black",
          }}
        >
          <CardBody
            sx={{
              display: "flex",
              flexDir: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Monthly orders={completedOrders} />
          </CardBody>
        </Card>
        <Card
          size={"lg"}
          sx={{
            display: "flex",
            width: "80%",
            height: "100%",
            border: "1px solid black",
          }}
        >
          <CardBody
            sx={{
              display: "flex",
              flexDir: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Quarterly orders={completedOrders} />
          </CardBody>
        </Card>
        <Card
          size={"lg"}
          sx={{
            display: "flex",
            width: "80%",
            height: "100%",
            border: "1px solid black",
          }}
        >
          <CardBody
            sx={{
              display: "flex",
              flexDir: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Half orders={completedOrders} />
          </CardBody>
        </Card>
        <Card
          size={"lg"}
          sx={{
            display: "flex",
            width: "80%",
            height: "100%",
            border: "1px solid black",
          }}
        >
          <CardBody
            sx={{
              display: "flex",
              flexDir: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FullYear orders={completedOrders} />
          </CardBody>
        </Card>
        <Card
          size={"lg"}
          sx={{
            display: "flex",
            width: "80%",
            height: "100%",
            border: "1px solid black",
          }}
        >
          <CardBody
            sx={{
              display: "flex",
              flexDir: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Total orders={completedOrders} />
          </CardBody>
        </Card>
      </Grid>
      <VStack
        sx={{
          width: "full",
          px: 7,
          py: 5,
          gap: 4,
        }}
      >
        <Flex
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingX: "8",
            pb: "8",
          }}
        >
          <Heading
            color={"teal"}
            fontSize={20}
            _hover={{ cursor: "pointer", textDecoration: "underline" }}
          >
            All Completed Orders
          </Heading>
        </Flex>
        <Flex width={"full"} justifyContent={"center"}>
          <TableContainer width={"100%"}>
            <Table
              variant="simple"
              width="100%"
              p="0"
              css={{ tableLayout: "fixed" }}
            >
              <Thead width="100%" bg={"teal.300"}>
                <Tr width="100%">
                  <Th>Order Name</Th>
                  <Th>Ordered Date</Th>
                  <Th isNumeric>Price</Th>
                </Tr>
              </Thead>
              {loading ? (
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    height: "100vh",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Spinner size={"lg"} />
                </Box>
              ) : (
                <Tbody width={"100%"} overflowY={"scroll"}>
                  {completedOrders?.map((e, i) => {
                    return <ListElement key={i} orderDetails={e} />;
                  })}
                </Tbody>
              )}
            </Table>
          </TableContainer>
        </Flex>
      </VStack>
    </Box>
  );
};
